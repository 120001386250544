<template>
  <div class="flex align-center justify-center items-center">
    <input
      :id="`order-input-${invitee.signeeId}`"
      class="round-input pointer-events-auto"
      min="1"
      max="9"
      type="number"
      :value="groupIndex"
      @focusin="emit('disable-drag', true)"
      @focusout="emit('disable-drag', false)"
      @change="handleUpdateGroupOrderDebounced"
    />
  </div>
</template>

<script setup lang="ts">
import type { Signee } from '@/types/Signee.ts';
import { type PropType } from 'vue';
import { useCreateStore } from '@/stores/create.ts';
import type { HTMLInputEvent } from '@/utils/types/HtmlInputEvent.ts';
import { debounce } from '@/composables/useUtils.ts';

const props = defineProps({
  invitee: {
    type: Object as PropType<Signee>,
    required: true
  },
  groupIndex: {
    type: Number,
    required: true
  }
});

const emit = defineEmits(['disable-drag']);

const createStore = useCreateStore();

const handleUpdateGroupOrderDebounced = debounce(function (event: Event) {
  updateGroupOrder(event);
}, 700);

async function updateGroupOrder(event: Event) {
  createStore.isLoading = true;
  const fromGroupIndex = props.groupIndex - 1;
  const toGroupIndex = parseInt((event as HTMLInputEvent).target.value) - 1;

  if (isNaN(toGroupIndex) || fromGroupIndex === toGroupIndex) {
    return;
  }

  const insertionArrayGroupIndex = getGroupIndex(toGroupIndex);
  const inviteeWithUpdatedSignGroup: Signee = {
    ...props.invitee,
    signOrder: insertionArrayGroupIndex
  };
  const localSigneesOrdered = filterInviteeFromLocalData(props.invitee);
  const filteredGroups = localSigneesOrdered.filter(
    (group) => group.length > 0
  );
  if (insertionArrayGroupIndex < filteredGroups.length) {
    filteredGroups[insertionArrayGroupIndex].push(inviteeWithUpdatedSignGroup);
  } else if (insertionArrayGroupIndex >= filteredGroups.length) {
    filteredGroups.push([inviteeWithUpdatedSignGroup]);
  }

  try {
    // Update the document's sign order
    await createStore.updateDocumentSignOrder(
      createStore.document!.documentId,
      filteredGroups
    );
    createStore.document!.signeesOrdered = filteredGroups;
    createStore.isLoading = false;
  } catch (e) {
    createStore.isLoading = false;
    return e;
  }
}

function getGroupIndex(toGroupIndex: number) {
  if (toGroupIndex <= 0) {
    return 0;
  } else if (toGroupIndex >= createStore.nextGroupIndex) {
    return createStore.nextGroupIndex;
  } else {
    return toGroupIndex;
  }
}

function filterInviteeFromLocalData(inviteeToRemove: Signee) {
  return (
    createStore.document?.signeesOrdered.map((group) =>
      group.filter((invitee) => invitee.signeeId !== inviteeToRemove.signeeId)
    ) || []
  );
}
</script>
<style lang="scss" scoped>
@import '@/styles/core/colors';

.round-input {
  z-index: 99;
  font-size: 16px;
  color: $primary-color;
  text-align: center;
  background-color: #dfe9ff;
  height: 30px !important;
  width: 30px !important;
  border-radius: 50%;
}

/* Hide the spin buttons in WebKit browsers */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide spin buttons in Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
