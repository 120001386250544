import { useRoute } from 'vue-router';
import { computed } from 'vue';

export function useInvitationContext() {
  const route = useRoute();

  const invitationSignKey = computed(() => {
    return route.params.id;
  });

  const mailRef = computed(() => {
    return route.query['mail-ref'];
  });

  const method = computed(() => {
    return route.query.method;
  });

  const sid = computed(() => {
    return route.query.sid;
  });

  return {
    invitationSignKey,
    mailRef,
    method,
    sid
  };
}
