<template>
  <div
    class="flex justify-between items-center w-full"
    data-test-id="invited-signatory-options"
    @mouseenter="isMouseHover = true"
    @mouseleave="isMouseHover = false"
  >
    <!-- EMAIL AND STATUS TEXT -->
    <div class="flex flex-col" :class="[signatureClass]" style="min-width: 0">
      <!-- EMAIL -->
      <div
        class="flex items-center justify-between signee-email"
        :class="{ clickable: !isDocumentWithdrawn && !isDocumentRejected }"
        @click="scrollToSignature"
      >
        <v-tooltip
          content-class="tooltip deep-light tooltip-top pointer-events-auto"
          location="top"
          :open-on-click="device?.isMobile"
        >
          <template #activator="{ props: ActivatorProps }">
            <div class="flex items-center gap-2">
              <VIcon v-if="hasApprovers" class="text-blue-500">
                {{
                  isSigneeTypeApprove
                    ? 'fa-light fa-octagon-check'
                    : 'fa-light fa-pen-nib'
                }}
              </VIcon>
              <span
                v-bind="ActivatorProps"
                class="max-w-[12rem] md:max-w-[17rem] truncate"
              >
                {{ signature.email }}
              </span>
            </div>
          </template>
          <template #default>
            <span class="max-w-3 truncate">
              {{ signature.email }}
            </span>
          </template>
        </v-tooltip>
      </div>
      <!-- /EMAIL -->

      <!-- STATUS TEXT -->
      <div class="flex items-center text-[13px] mt-1">
        <div class="flex items-center">
          <span :class="status.class">
            {{ status.text }}
          </span>
          <span v-if="status.time" class="ml-1.5 text-gray-400">
            {{ status.time }}
          </span>
          <v-tooltip
            v-if="
              isSignatureSigned || isSignatureRejected || isSignatureApproved
            "
            content-class="tooltip deep-light tooltip-top"
            location="top"
            open-on-click
          >
            <template #activator="{ props: ActivatorProps }">
              <v-icon
                v-bind="ActivatorProps"
                class="ml-1 date text-gray-400"
                :size="12"
              >
                far fa-clock
              </v-icon>
            </template>
            <span class="text-xs">
              {{
                formatDateTimeToLocaleTimeWithTimeZone(
                  signature.signedTime,
                  i18n.locale.value
                )
              }}
            </span>
          </v-tooltip>
        </div>
      </div>
      <!-- /STATUS TEXT -->
    </div>
    <!-- /EMAIL AND STATUS TEXT -->

    <!-- STATUS ICONS -->
    <div class="flex flex-col justify-center align-center">
      <div class="flex">
        <ParticipantMenu
          v-if="!isSignatureSigned || !isSignatureApproved"
          :show-activator="isMouseHover"
          :participant-id="signature.signeeId"
          :participant-status="signature.signStatus"
          :participant-policy="signature.policy"
          :participant-email="signature.email"
          :participant-type="
            signature.signeeType === SigneeType.SIGN ? 'signee' : 'approve'
          "
        />

        <a v-if="signeeComment" class="show-comment" @click="showDialog">
          <v-icon class="mr-2" size="18"> fal fa-comment-lines</v-icon>
        </a>
        <div class="flex items-center justify-center">
          <v-icon
            v-if="
              isSignatureApproved || isSignatureSigned || isSignatureRejected
            "
            :color="
              isSignatureApproved || isSignatureSigned ? 'success' : 'error'
            "
            class="mr-2"
            size="18"
          >
            {{
              isSignatureApproved || isSignatureSigned
                ? 'fas fa-circle-check'
                : isSignatureRejected
                  ? 'fa-solid fa-circle-xmark'
                  : ''
            }}
          </v-icon>
        </div>
      </div>
    </div>
    <!-- /STATUS ICONS -->

    <!-- COMMENT DIALOG -->
    <SigneeCommentDialog
      v-bind="$attrs"
      :ref="`comment-dialog-${signature.email}`"
      v-model="commentDialog"
      :comment="signeeComment"
      :signee="signature"
    />
    <!-- /COMMENT DIALOG -->
  </div>
</template>
<script lang="ts" setup>
import SignatureService from '@/services/signatureService';
import { isMobileOrTablePortrait } from '@/services/styleService';
import AuthService from '@/services/auth-service';
import { DocumentStatus } from '@/types/enums/DocumentStatus.ts';
import { SignStatus } from '@/types/enums/SignStatus.ts';
import { useSignStore } from '@/stores/sign/sign.ts';
import { computed, inject, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import { useEmitter } from '@/composables/useEmitter.ts';
import { DeviceKey } from '@/plugins/device-detector-js';
import {
  formatDateTimeToLocaleTimeWithTimeZone,
  formatDateToLocale
} from '@/composables/useUtils';
import ParticipantMenu from '@/components/menus/ParticipantMenu.vue';
import { useScreenOrientation } from '@vueuse/core';
import type { PropType } from 'vue';
import type { SignSignee } from '@/types/SignSignee';
import { SigneeType } from '@/types/enums/SigneeType';

const props = defineProps({
  signature: {
    type: Object as PropType<SignSignee>,
    required: true
  },
  hasApprovers: {
    type: Boolean,
    default: false
  }
});

const display = useDisplay();
const signStore = useSignStore();
const commentDialog = ref(false);
const invitationSent = ref(false);
const { orientation } = useScreenOrientation();
const device = inject(DeviceKey);
const i18n = useI18n();
const emitter = useEmitter();
const isMouseHover = ref(false);
const documentStatus = computed(() => {
  return signStore.signInfo?.document?.documentStatus;
});

// Document status
const isDocumentWithdrawn = computed(
  () => documentStatus.value === DocumentStatus.WITHDRAWN
);
const isDocumentRejected = computed(
  () => documentStatus.value === DocumentStatus.REJECTED
);

const isDocumentInProgress = computed(
  () => documentStatus.value === DocumentStatus.IN_PROGRESS
);
//

// Signature status
const isSignatureSigned = computed(
  () => props.signature.signStatus === SignStatus.SIGNED
);
const isSignatureApproved = computed(
  () => props.signature.signStatus === SignStatus.APPROVED
);
const isSignatureRejected = computed(
  () => props.signature.signStatus === SignStatus.REJECTED
);
const isSignaturePending = computed(
  () => props.signature.signStatus === SignStatus.PENDING
);
const isSignatureOnHold = computed(
  () => props.signature.signStatus === SignStatus.ON_HOLD
);

// Signature type
const isSigneeTypeApprove = computed(
  () => props.signature.signeeType === SigneeType.APPROVE
);
const isSigneeTypeSign = computed(
  () => props.signature.signeeType === SigneeType.SIGN
);

const isMobileOrTabletPortrait = computed(() => {
  return (
    isMobileOrTablePortrait(
      device?.isMobile,
      device?.isTablet,
      display.mdAndDown.value
    ) && orientation.value === 'portrait-primary'
  );
});

const isMe = computed(() => {
  if (signStore.signInfo?.email) {
    return SignatureService.compareString(
      signStore.signInfo.email,
      props.signature?.email
    );
  }
  if (AuthService.isAuthenticated) {
    return SignatureService.compareString(
      AuthService.user?.email,
      props.signature?.email
    );
  } else {
    return false;
  }
});

const signatureClass = computed(() => {
  if (isMe.value && isSignaturePending.value) return 'my-card';
  if (isSignaturePending.value) return 'pending-card';
  if (isSignatureSigned.value) return 'signed-card';
  return '';
});

const status = computed(() => {
  if (isSignaturePending.value) {
    if (isDocumentRejected.value) {
      return {
        text: i18n.t('titles.signee_rejected_signature'),
        class: 'text-error'
      };
    }
    if (isSigneeTypeApprove.value) {
      return {
        text: i18n.t('titles.waiting_for_approval'),
        class: 'text-gray-400'
      };
    }
    if (isSigneeTypeSign.value && isDocumentInProgress) {
      return {
        text: i18n.t('titles.waiting_for_signature'),
        class: 'text-gray-400'
      };
    }
  }

  if (isSignatureRejected.value && isSigneeTypeSign.value) {
    return {
      text: `${i18n.t('titles.signature_rejected')}:`,
      class: 'text-error',
      time: `${formatDateToLocale(
        props.signature.signedTime,
        i18n.locale.value,
        true
      )}`
    };
  }
  if (isSignatureRejected.value && isSigneeTypeApprove.value) {
    return {
      text: `${i18n.t('titles.approval_rejected')}:`,
      class: 'text-error',
      time: `${formatDateToLocale(
        props.signature.signedTime,
        i18n.locale.value,
        true
      )}`
    };
  }

  if (isSignatureApproved.value) {
    return {
      text: `${i18n.t('document_status.approved')}:`,
      class: 'text-green-600',
      time: `${formatDateToLocale(
        props.signature.signedTime,
        i18n.locale.value,
        true
      )}`
    };
  }

  if (isSignatureSigned.value) {
    return {
      text: `${i18n.t('document_status.signed')}:`,
      class: 'text-green-600',
      time: `${formatDateToLocale(
        props.signature.signedTime,
        i18n.locale.value,
        true
      )}`
    };
  }

  if (isSignatureOnHold.value && isSigneeTypeSign.value) {
    return {
      text: `${i18n.t('document_status.on_hold')}`,
      class: 'text-gray-400'
    };
  }

  if (isSignatureOnHold.value && isSigneeTypeApprove.value) {
    return {
      text: `${i18n.t('document_status.approval_on_hold')}`,
      class: 'text-gray-400'
    };
  }

  return {
    text: '',
    class: ''
  };
});

const signeeComment = computed(() => {
  if (isMe.value) {
    return (
      signStore.signInfo?.signeeComment || props.signature?.signeeComment || ''
    );
  }
  return props.signature?.signeeComment || '';
});

emitter.$on('invitation-sent', updatedInvitationStatus);

onUnmounted(() => {
  emitter.$off('invitation-sent', updatedInvitationStatus);
});

function showDialog() {
  commentDialog.value = true;
}

function updatedInvitationStatus(eventData) {
  if (eventData === props.signature.email) {
    invitationSent.value = true;
  }
}

function scrollToSignature() {
  if (
    isMobileOrTabletPortrait.value ||
    isDocumentWithdrawn ||
    isDocumentRejected ||
    !props.signature.autographPosition
  ) {
    return null;
  }
  SignatureService.scrollToSignaturePosition(props.signature.autographPosition);
}
</script>

<style lang="scss" scoped>
@import '@/styles/core/colors';

.clickable {
  &:hover {
    cursor: pointer;
  }
}

.signer-card {
  @apply pt-2 min-h-[60px];
}

.signee-email {
  word-break: break-all;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
}

.show-comment {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  color: $grey-darken-1;

  &:hover {
    color: $primary-color;

    i {
      color: $primary-color;
    }
  }
}
</style>
