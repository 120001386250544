import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { Preferences } from '@/types/deepadmin/Preferences';
import deepadminApi from '@/api/deepadmin/deepadmin';

export const usePreferencesStore = defineStore('preferences', () => {
  // End point url
  const endPointUrl = '/users/me/profile/';

  const preferences = ref<Preferences | undefined>();

  const fetchAllPending = ref(false);
  const setPreferencePending = ref(false);
  const removePreferencePending = ref(false);

  async function fetchAll() {
    try {
      fetchAllPending.value = true;
      const response = await deepadminApi.get(endPointUrl);
      preferences.value = response.data?.preferences;
      fetchAllPending.value = false;
      return Promise.resolve(response);
    } catch (error) {
      fetchAllPending.value = false;
      return Promise.reject(error);
    }
  }

  async function setPreference(
    key: string,
    value: string | number | null | boolean | unknown[] | undefined
  ) {
    try {
      setPreferencePending.value = true;
      const body = { preferences: {} };
      body.preferences[`${key}`] = value;
      const response = await deepadminApi.patch(endPointUrl, body);
      preferences.value = response.data?.preferences;
      setPreferencePending.value = false;
      return Promise.resolve(response);
    } catch (error) {
      setPreferencePending.value = false;
      return Promise.reject(error);
    }
  }

  async function removePreference(keyName: string) {
    try {
      removePreferencePending.value = true;
      const body = { preferences: {} };
      body.preferences[`${keyName}`] = null;
      const response = await deepadminApi.patch(endPointUrl, body);
      removePreferencePending.value = false;
      return Promise.resolve(response);
    } catch (error) {
      removePreferencePending.value = false;
      return Promise.reject(error);
    }
  }

  return {
    preferences,
    fetchAllPending,
    setPreferencePending,
    removePreferencePending,
    fetchAll,
    setPreference,
    removePreference
  };
});
