<template>
  <v-dialog
    :model-value="modelValue"
    max-height="300"
    width="600"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card class="dialog-container" rounded="lg">
      <!-- TITLE -->
      <v-card-title
        class="d-flex flex-row align-center justify-center card-title"
      >
        <h2>{{ i18n.t('dialogs.save_signature.title') }}</h2>
      </v-card-title>
      <!-- /TITLE -->

      <!-- TEXT -->
      <v-card-text class="d-flex flex-column align-center justify-center pa-0">
        <div class="text-container text-center">
          {{ i18n.t('dialogs.save_signature.text') }}
        </div>
      </v-card-text>
      <!-- TEXT -->

      <!-- ACTIONS -->
      <div class="button-container">
        <DeepButton
          :action="goToOnboarding"
          class="mb-3"
          max-width="405px"
          :text="i18n.t('buttons.create_account')"
        />
        <DeepButton
          :action="closeDialog"
          variant="outlined"
          :text="i18n.t('buttons.cancel')"
        />
      </div>
      <!-- /ACTIONS -->
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  }
});
</script>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useInvitationContext } from '@/composables/useInvitationContext';
import { useOnboarding } from '@/composables/useOnboarding';

const props = defineProps({
  modelValue: {
    type: Boolean
  },
  signerEmail: {
    type: String,
    default: undefined
  }
});

const onboarding = useOnboarding();
const i18n = useI18n();
const emit = defineEmits(['update:modelValue']);

const { mailRef, sid, invitationSignKey } = useInvitationContext();

const redirectUrl = computed(() => {
  return `sign/${invitationSignKey.value}?sid=${sid.value}&mail-ref=${mailRef.value}`;
});

function goToOnboarding() {
  onboarding.openRegisterPage(redirectUrl.value, props.signerEmail);
}

function closeDialog() {
  emit('update:modelValue', !props.modelValue);
}
</script>

<style lang="scss" scoped>
@import '@/styles/core/colors';
.dialog-container {
  padding: 24px !important;
}

.card-title {
  word-break: break-word;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  padding: 0 !important;
}

.text-content {
  margin-bottom: 56px;
  min-width: 100%;
  text-align: start;
  background: $grey-lighten-4;
  padding: 24px;
  color: black;
}

.button-container {
  margin: 24px 24px 0 24px;
  padding: 0 40px;
}

.text-container {
  margin: 24px 24px 16px 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: black;
  div {
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.004em;
    color: $grey;
  }
}
</style>
