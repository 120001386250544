import type { SignOrder } from '@/types/enums/SignOrder';
import type { AxiosRequestConfig } from 'axios';
import deepSignApi from '@/api/deepsign/deepsignApi';

export const updateSignOrderType = (
  documentId: string,
  signOrderType: SignOrder,
  config?: AxiosRequestConfig
) =>
  deepSignApi.put(
    `documents/${documentId}/sign-order/${signOrderType}`,
    config
  );
