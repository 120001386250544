<template>
  <div class="grid gap-4" style="width: 100%">
    <div class="text-sm text-gray-500 mt-1" data-test-id="file-max-size">
      {{ i18n.t('texts.attachments_format_size_hint') }}
    </div>
    <div
      v-if="modelValue.length > 0"
      class="files-container"
      data-test-id="attachment-info"
    >
      <UploadedAttachment
        v-for="file in modelValue"
        :key="file.uploadId"
        :file="file"
        :attachment-type="attachmentType"
        @remove-uploaded-attachment="emit('remove-file', file)"
      />
    </div>

    <input
      ref="filesInput"
      accept="application/pdf"
      hidden
      multiple
      type="file"
      data-test-id="input-file-attachment"
      @input="onSelectFiles"
    />

    <div
      v-if="modelValue.length > 0"
      class="text-center text-sm text-gray-500"
      :class="{ 'text-error': isTotalSizeTooLarge }"
      :data-test-id="`totalfilesize-${totalFileSize}`"
    >
      {{ i18n.t('texts.total_file_size') + ': ' + totalFileSize }}
    </div>
    <div>
      <DeepButton
        :action="openSelector"
        :disabled="isTotalSizeTooLarge"
        icon="fa fa-arrow-up-from-bracket"
        variant="outlined"
        :text="i18n.t('buttons.attach_files')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { AttachmentStatus } from '@/types/enums/AttachmentStatus.ts';
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { humanFileSize } from '@/composables/useUtils';
import UploadedAttachment from '@/components/items/UploadedAttachment.vue';
import type { PropType } from 'vue';
import type { Attachment, AttachmentFile } from '@/types/Attachment';
import { v4 as uuid } from 'uuid';

export interface FileWithStatus {
  file: File;
  status: AttachmentStatus;
  attachment: Attachment;
  errorId?: string;
}

const i18n = useI18n();
const filesInput = ref();

const props = defineProps({
  modelValue: {
    type: Array as PropType<AttachmentFile[]>,
    default: () => []
  },
  maxFileSizeMB: {
    type: Number,
    default: 40
  },
  supportedTypes: {
    type: String,
    required: true
  },
  attachmentType: {
    type: String as PropType<'attachment' | 'signee-attachment'>,
    default: undefined
  }
});

const emit = defineEmits(['update:modelValue', 'remove-file']);

const totalFileSize = computed(() => {
  let fileSize = 0;
  props.modelValue.forEach((file) => {
    fileSize += file.size;
  });
  return humanFileSize(fileSize);
});

const isTotalSizeTooLarge = computed(() => {
  let fileSize = 0;
  props.modelValue.forEach((file) => {
    if (file.status === AttachmentStatus.ATTACHED) {
      fileSize += file.size;
    } else {
      fileSize += file.size;
    }
  });
  return Number((fileSize / 1000000).toFixed(1)) > props.maxFileSizeMB;
});

async function onSelectFiles(event) {
  const newFiles = [...Array.prototype.slice.call(event.target.files)].map(
    (file) => {
      if (Number((file.size / 1000000).toFixed(1)) > props.maxFileSizeMB) {
        return {
          file,
          name: file.name,
          size: file.size,
          mimeType: undefined,
          attachementId: undefined,
          uploadId: uuid(),
          status: AttachmentStatus.EXCLUDED
        };
      } else {
        return {
          file,
          name: file.name,
          size: file.size,
          mimeType: undefined,
          attachementId: undefined,
          uploadId: uuid(),
          status:
            file.type === props.supportedTypes
              ? AttachmentStatus.PENDING
              : AttachmentStatus.UNSUPPORTED
        };
      }
    }
  );

  // Emit the updated file list
  emit('update:modelValue', [...props.modelValue, ...newFiles]);

  filesInput.value.value = null;
}

function openSelector() {
  filesInput.value.click();
}
</script>
