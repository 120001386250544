<template>
  <SignaturePad
    v-if="isSignaturePlaced"
    :has-default-autograph="false"
    :pen-color="'rgba(22,32,  81,0.84)'"
    :signer-email="signContext.userEmail.value"
  />
</template>

<script setup lang="ts">
import { useSignStore } from '@/stores/sign/sign';
import { computed } from 'vue';
import { useSignContext } from '@/composables/useSignContext';

const signContext = useSignContext();
const signStore = useSignStore();

const isSignaturePlaced = computed(() => {
  return signStore.placedSignatures.some(
    (signee) => signee.signeeId === signStore.signInfo?.signeeId
  );
});
</script>
