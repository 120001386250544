import deepadminApi from '@/api/deepadmin/deepadmin';
import type { UserDetail } from '@/types/deepadmin/UserDetail';
import type { AxiosResponse } from 'axios';

export function getUserById(
  id: string,
  mobileId = false
): Promise<AxiosResponse<UserDetail>> {
  return deepadminApi.get(`users/${id}?mobileId=${mobileId}`);
}
