import type { Signee } from '@/types/Signee.ts';
import { SigneeType } from '@/types/enums/SigneeType.ts';

export function useLocalInviteesDataActions(store) {
  const createStore = store;

  function addInviteeToLocalData(invitee: Signee) {
    createStore.document!.signeesOrdered =
      createStore.document!.signeesOrdered.length === 0
        ? []
        : createStore.document!.signeesOrdered;
    createStore.document!.signeesOrdered.push([invitee]);
  }

  function updateSignOrderInLocalData(groups: Array<Array<Signee>>) {
    groups.forEach((group, groupIndex) => {
      group.forEach((invitee: Signee) => {
        // eslint-disable-next-line no-param-reassign
        invitee.signOrder = groupIndex;

        //we need to sort here as the BE does ignore the in group position of an invitee
        return group.sort((a, b) => a.email?.localeCompare(b.email));
      });
    });
    createStore.document.signeesOrdered = groups;
  }

  function updateInviteeInLocalData(invitee: Signee) {
    createStore.document!.signeesOrdered.forEach((group) => {
      const match = group.find(
        (_invitee) => _invitee.signeeId === invitee.signeeId
      );
      if (match) {
        match.email = invitee.email;
        match.autographPosition = invitee.autographPosition;
        match.signOrder = invitee.signOrder;
      }
    });
  }

  function createInitialGroupsWithApprovers(groups: Array<Array<Signee>>) {
    const approverGroup: Array<Signee> = [];
    const signeeGroup: Array<Signee> = [];
    groups.forEach((group) => {
      if (group.length > 0) {
        group.forEach((invitee: Signee) => {
          invitee.signeeType === SigneeType.SIGN
            ? signeeGroup.push(invitee)
            : approverGroup.push(invitee);
        });
      }
    });
    return [approverGroup, signeeGroup];
  }

  function removeInviteeFromLocalData(id: string) {
    const groups = createStore.document!.signeesOrdered?.map((inviteeGroup) => {
      return inviteeGroup.flat().filter((invitee) => invitee.signeeId !== id);
    });
    createStore.document!.signeesOrdered = filterEmptyGroups(groups);
  }

  function filterEmptyGroups(groups: Array<Array<Signee>>) {
    return groups.filter((group) => group.length > 0);
  }

  function filterEmptyInBetweenGroups(groups: Array<Array<Signee>>) {
    return groups.filter((group, index) => {
      if (index === 0) {
        return true;
      }

      if (index === groups.length - 1) {
        return true;
      }

      return group.length > 0;
    });
  }

  function filterInviteeFromLocalData(inviteeToRemove: Signee) {
    return createStore.document!.signeesOrdered.map((group) =>
      group.filter(
        (invitee: Signee) => invitee.signeeId !== inviteeToRemove.signeeId
      )
    );
  }

  function clearInvalidFields() {
    createStore.document!.signeesOrdered.forEach((signeeGroup) => {
      signeeGroup.forEach((invitee: Signee) => {
        if (!createStore.validateEmail(invitee.email)) {
          // eslint-disable-next-line no-param-reassign
          invitee.email = '';
        }
      });
    });
  }

  function addNewFirstGroupAsDropZone() {
    createStore.document!.signeesOrdered?.splice(0, 0, []);
  }

  function addNewLastGroupAsDropZone() {
    createStore.document!.signeesOrdered?.push([]);
  }

  function addDropZones(groups: Array<Array<Signee>> | null) {
    const inviteeGroups = groups
      ? groups
      : createStore.document!.signeesOrdered;
    const isFirstGroupEmpty = inviteeGroups[0].length === 0;
    const isLastGroupEmpty =
      inviteeGroups[inviteeGroups.length - 1].length === 0;
    if (isFirstGroupEmpty && isLastGroupEmpty) {
      return;
    }
    if (!isFirstGroupEmpty) {
      addNewFirstGroupAsDropZone();
    }

    if (!isLastGroupEmpty) {
      addNewLastGroupAsDropZone();
    }
  }

  function sanitizeSigneeOrderedFields() {
    /*  addDropZones(null);
    createStore.document.signeesOrdered = filterEmptyInBetweenGroups(
      createStore.document.signeesOrdered
    );*/
  }

  return {
    addDropZones,
    addInviteeToLocalData,
    clearInvalidFields,
    createInitialGroupsWithApprovers,
    filterEmptyInBetweenGroups,
    filterEmptyGroups,
    filterInviteeFromLocalData,
    updateSignOrderInLocalData,
    updateInviteeInLocalData,
    removeInviteeFromLocalData,
    sanitizeSigneeOrderedFields
  };
}
