type SubStringList = {
  text: string;
  class?: string;
};

type HighlightPositions = {
  start: number;
  length: number;
};

export function useSuggestionHelper() {
  const highlightClass = 'v-list-item__mask';
  function filterItems(itemText: string, queryText: string, item?: object) {
    if (
      (item.props.value?.header && item.props.value?.header?.hasEmailMatches) ||
      item.props.value?.type === 'divider'
    ) {
      return true;
    }

    const itemTextLc = itemText.toLocaleLowerCase();

    return queryText
      .toLocaleLowerCase()
      .split(' ')
      .every((q) => itemTextLc.includes(q));
  }

  function parseItemText(itemText: string, queryText: string) {
    const highlightPositions: Array<HighlightPositions> | HighlightPositions =
      extractHighlightPositions(itemText, queryText);
    const parsed: Array<SubStringList> = [];
    let pos = 0;
    for (const { start, length } of Array.from(highlightPositions)) {
      if (pos < start) {
        pushSubstring(parsed, itemText, pos, start);
      }
      pushSubstring(parsed, itemText, start, start + length, highlightClass);
      pos = start + length;
    }
    if (pos < itemText.length) {
      pushSubstring(parsed, itemText, pos, itemText.length);
    }
    return parsed;
  }
  function pushSubstring(
    arr: Array<SubStringList>,
    str: string,
    start: number,
    end: number,
    cls?: string
  ) {
    const text = str.substring(start, end);
    arr.push({ text, class: cls });
  }
  function extractHighlightPositions(itemText: string, queryText: string) {
    if (!queryText || !itemText) return [];
    const queryWords = queryText.toLocaleLowerCase().split(' ');
    return queryWords
      .map((qw) => ({ start: itemText.indexOf(qw), length: qw.length }))
      .sort((a, b) => a.start - b.start || a.length - b.length)
      .reduce((agg: any[], positionData) => {
        if (agg.length === 0) return [positionData];
        const { start, length } = positionData;
        const last = agg[agg.length - 1];
        if (start > last.start + last.length) {
          agg.push(positionData);
        } else {
          last.length = Math.max(last.length, start - last.start + length);
        }
        return agg;
      }, []);
  }

  return {
    filterItems,
    parseItemText
  };
}
