<template>
  <v-dialog v-model="showDialog" persistent width="600">
    <v-card class="pa-6" rounded="lg">
      <!-- TITLE -->
      <v-card-title class="justify-center">
        <h5 class="card-title">
          {{ i18n.t('titles.observers') }}
        </h5>
      </v-card-title>
      <!-- /TITLE -->

      <!-- TEXT -->
      <v-card-text class="px-0 text-black mb-10">
        <div class="text-center mb-10">
          {{ i18n.t('texts.observers_dialog_hint') }}
        </div>

        <v-form
          ref="observersForm"
          @submit.prevent="updateObservers"
          @keydown.enter.prevent
        >
          <ObserverInput
            v-for="(observer, index) in observers"
            :key="observer.key"
            :index="index"
            :observer="observer"
            :observers="observers"
            :email-to-hide="initiatorSigneeEmail"
            @remove-observer="removeObserver(index)"
            @update-observer="updateObserver"
          />
        </v-form>
        <div class="mt-4">
          <span
            class="deep-text-button"
            tabindex="0"
            data-test-id="observer-add"
            @click="addNewObserver"
            @keyup.enter="addNewObserver"
          >
            {{ i18n.t('buttons.add_observer') }}
          </span>
        </div>
      </v-card-text>
      <!-- /TEXT -->

      <!-- ACTIONS -->
      <DeepButton
        :action="updateObservers"
        class="mb-3"
        color="primary"
        :disabled="!isFormValid"
        :loading="inProgress"
        :text="i18n.t('buttons.save')"
      />
      <DeepButton
        :action="closeDialog"
        variant="outlined"
        :text="i18n.t('buttons.cancel')"
      />
      <!-- /ACTIONS -->
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { v4 as uuid } from 'uuid';
import {
  observersToDelete,
  observersToUpdate
} from '@/services/documentService';
import { useDocumentStore } from '@/stores/document/document';
import { ref, reactive, computed, onMounted, onUnmounted, watch } from 'vue';
import { useEmitter } from '@/composables/useEmitter.ts';
import { useI18n } from 'vue-i18n';
import { cloneDeep } from '@/composables/useUtils';
import { useEmailService } from '@/components/inputs/Emailsuggestion/useEmailService';
import { useRoute } from 'vue-router';

const props = defineProps({
  hasValidSubscription: {
    type: Boolean,
    required: true
  },
  document: {
    type: Object,
    required: true
  }
});

const documentStore = useDocumentStore();
const showDialog = ref(false);
const emptyObserver = reactive({
  email: null,
  isAdmin: false,
  errorState: false
});
const observers = ref([{ ...emptyObserver }]);
const observersForm = ref();
const documentObservers = ref([]);
const inProgress = ref(false);
const isFormValid = ref(true);
const emitter = useEmitter();
const i18n = useI18n();
const route = useRoute();
const { saveRecentlyUsedEmails } = useEmailService();

const initiatorSigneeEmail = computed(() => {
  return documentStore.document?.initiatorDisplayEmail || '';
});

const isDraftMode = computed(() => route.name === 'document-create');

watch(
  observers,
  () => {
    isFormValid.value = observers.value.every((obs) => !obs.errorState);
  },
  { deep: true }
);

onMounted(() => {
  emitter.$on('open-observers-dialog', openDialog);
});

onUnmounted(() => {
  emitter.$off('open-observers-dialog', openDialog);
});

function openDialog(newObservers) {
  showDialog.value = true;
  inProgress.value = false;
  observers.value = cloneDeep(newObservers).map((obs) => addKey(obs));
  documentObservers.value = [...newObservers.map((obs) => addKey(obs))];
  if (observers.value.length === 0) {
    addNewObserver();
  }
}

function closeDialog() {
  showDialog.value = false;
  observers.value = [];
  inProgress.value = false;
}

function updateObservers() {
  inProgress.value = true;

  if (isDraftMode.value) {
    saveRecentlyUsedEmails(observers.value);
  }

  const toRemove = observersToDelete(documentObservers.value, observers.value);
  const toUpdate = observersToUpdate(documentObservers.value, observers.value);
  const toAdd = observers.value.filter((obs) => obs.observerId === undefined);
  Promise.all([
    Promise.all(
      toRemove.map((obs) => {
        documentStore.deleteObserver(props.document.documentId, obs);
      })
    ),
    Promise.all(
      toUpdate.map((obs) => {
        const observer = observers.value.find(
          (formObs) => formObs.observerId === obs
        );
        const data = {
          email: observer.email?.length === 0 ? null : observer.email,
          comment: observer.comment,
          language: observer.language,
          isAdmin: observer.isAdmin
        };

        documentStore.updateDocumentObserver(
          props.document.documentId,
          observer.observerId,
          data
        );
      })
    ),
    Promise.all(
      toAdd.map((obs) => {
        const data = {
          email: obs.email?.length === 0 ? null : obs.email,
          comment: obs.comment,
          language: obs.language,
          isAdmin: obs.isAdmin
        };
        documentStore.addObserver(props.document.documentId, data);
      })
    )
  ]).then(() => {
    closeDialog();
  });
}

function updateObserver({ observer, error }) {
  const observerToUpdate = observers.value.find(
    (obs) => obs.key === observer.key
  );
  observerToUpdate.email = observer.email;
  observerToUpdate.isAdmin = observer.isAdmin;
  observerToUpdate.errorState = error;

  const emailCounts = {};
  observers.value.forEach((obs) => {
    if (obs.email) {
      if (obs.email in emailCounts) {
        emailCounts[obs.email]++;
      } else {
        emailCounts[obs.email] = 1;
      }
    }
  });

  if (error || !Object.values(emailCounts).every((count) => count === 1)) {
    isFormValid.value = false;
  } else {
    isFormValid.value = true;
  }
}

function addNewObserver() {
  const newObserver = {
    key: uuid(),
    ...emptyObserver
  };
  observers.value.push(newObserver);
  setTimeout(() => {
    const input = document.getElementById(newObserver.key);
    input.focus();
  }, 100);
}

function removeObserver(index) {
  observers.value.splice(index, 1);
  observersForm.value.resetValidation();
  isFormValid.value = observersForm.value.validate();
}

function addKey(object) {
  return {
    key: uuid(),
    ...object
  };
}
</script>

<style scoped lang="scss">
.card-title {
  word-break: break-word;
  font-weight: 600;
  font-size: 24px !important;
  text-align: center;
  margin: 0 0 24px;
  white-space: normal;
}
</style>
