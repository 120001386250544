<template>
  <DeepButton
    :action="sealDocument"
    class="overflow-ellipsis"
    color="primary"
    :disabled="disabled"
    :loading="loading"
    :text="i18n.t('buttons.seal')"
  />
</template>

<script setup lang="ts">
import { UploadStatus } from '@/types/enums/UploadStatus';
import { useDocumentStore } from '@/stores/document/document';
import { useRouter } from 'vue-router';
import type { PropType } from 'vue';
import type { FileType } from '@/types/ui';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  loading: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  organizationId: {
    type: String,
    default: undefined
  },
  uploadedFiles: {
    type: Array as PropType<FileType[]>,
    default: undefined
  }
});

const emits = defineEmits(['close-dialog']);
const documentStore = useDocumentStore();
const router = useRouter();
const i18n = useI18n();

async function createDocuments() {
  const promises = [];
  for (const uploadedFile of props.uploadedFiles) {
    uploadedFile.status = UploadStatus.PROGRESS;
    promises.push(
      documentStore
        .createSealDocumentFromFile(uploadedFile.file, props.organizationId)
        .then(() => {
          uploadedFile.status = UploadStatus.UPLOADED;
        })
        .catch((error) => {
          console.error('CreateDocuments ', error);
        })
    );
  }
  await Promise.all(promises);
}

async function sealDocument() {
  // We create the document - the new document will have a signee with signeeType=seal
  // for the user uploading the document and the document will be started (documentStatus=in_progress)
  await createDocuments();
  const documentId = documentStore.document?.documentId;
  await documentStore.fetchDocument(documentId);

  if (documentId && props.uploadedFiles?.length === 1) {
    await router.push({
      name: 'document-seal',
      params: {
        document: documentId
      }
    });
    emits('close-dialog', props.uploadedFiles);
  } else {
    emits('close-dialog', props.uploadedFiles);
  }
}
</script>
