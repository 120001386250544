<template>
  <v-card class="pb-6" flat>
    <!-- EXPERT SETTINGS -->
    <v-expand-transition>
      <div
        v-show="
          expertSettings ||
          hasExpertSettingsDefined ||
          hasRequiredAuthorityService
        "
      >
        <!-- ATTACHMENTS -->
        <CustomSwitchWrapper
          v-if="
            expertSettings ||
            (createStore.document &&
              createStore.document.signeeAttachmentsAllowed)
          "
          :disabled="!canEnableAttachments"
          :initial-value="allowAttachments"
          :label="String(i18n.t('labels.allow_attachments'))"
          label-class="drawer-subhead"
          data-test-id="allow-attachments"
          @value-changed="updateAllowAttachments"
        >
          <template #label-append>
            <span class="ml-1">
              <v-menu
                location="left top"
                max-width="300"
                open-on-hover
                open-on-click
              >
                <template #activator="{ props: ActivatorProps }">
                  <v-icon
                    v-bind="ActivatorProps"
                    class="icon"
                    color="grey"
                    size="13"
                  >
                    fa-regular fa-circle-info
                  </v-icon>
                </template>
                <v-card class="pa-3" flat>
                  <v-card-text class="pa-0">
                    {{
                      canEnableAttachments
                        ? i18n.t('texts.allow_attachments_hint')
                        : i18n.t('texts.allow_attachments_disabled_hint')
                    }}
                  </v-card-text>
                </v-card>
              </v-menu>
            </span>
          </template>
        </CustomSwitchWrapper>
        <!-- ATTACHMENTS -->

        <!-- ENFORCE AUTHORITY SERVICE -->
        <v-slide-x-transition>
          <CustomSwitchWrapper
            v-if="
              (expertSettings || hasRequiredAuthorityService) &&
              canEnforceAuthorityService
            "
            class="my-3"
            :disabled="!canEnforceAuthorityService"
            :initial-value="enforceAuthorityService"
            :label="i18n.t('labels.enforce_authority_service').toString()"
            label-class="drawer-subhead"
            data-test-id="enforce-authority"
            @value-changed="updateEnforceAuthorityService"
          >
            <template #label-append>
              <span class="ml-1">
                <v-menu
                  location="left top"
                  max-width="300"
                  open-on-hover
                  open-on-click
                >
                  <template #activator="{ props: ActivatorProps }">
                    <v-icon
                      v-bind="ActivatorProps"
                      class="icon"
                      color="grey"
                      size="13"
                    >
                      fa-regular fa-circle-info
                    </v-icon>
                  </template>
                  <v-card class="pa-3" flat>
                    <v-card-text class="pa-0">
                      {{ i18n.t('texts.enforce_authority_service_hint') }}
                    </v-card-text>
                  </v-card>
                </v-menu>
              </span>
            </template>
          </CustomSwitchWrapper>
        </v-slide-x-transition>
        <v-expand-transition>
          <v-row
            v-if="canEnforceAuthorityService && enforceAuthorityService"
            class="d-flex align-center authority-service deep-footnote my-3"
            no-gutters
          >
            <div class="mt-1 drawer-footnote ml-2">
              {{ i18n.t('labels.id_service') }}
            </div>
            <AuthorityServiceMenu
              v-if="
                availableAuthorityServices.length > 0 && createStore.document
              "
              :signature-mode="createStore.document.signatureMode"
              :authority-service="authorityService"
              :authority-services="availableAuthorityServices"
            />
            <v-spacer />
          </v-row>
        </v-expand-transition>
        <!-- ENFORCE AUTHORITY SERVICE -->

        <!-- OBSERVERS -->
        <ObserversCard
          v-show="expertSettings || hasObservers"
          :document="createStore.document"
        />
        <!-- OBSERVERS -->
      </div>
    </v-expand-transition>
    <!-- /EXPERT SETTINGS -->

    <!-- /POSITION -->

    <AttachmentsUploadCard />

    <!-- MESSAGE -->

    <MessagePanel :for-requestor="false" />
    <!-- /MESSAGE -->
  </v-card>
</template>

<script setup lang="ts">
import { usePreferencesStore } from '@/stores/deepadmin/preferences';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useEmitter } from '@/composables/useEmitter';
import { useI18n } from 'vue-i18n';
import { useServiceStore } from '@/stores/service/service';
import { SignatureMode } from '@/types/enums/SignatureMode';
import { orderBy } from '@/composables/useUtils';
import { Jurisdiction } from '@/types/enums/Jurisdiction';
import {
  hasExpertSettingsDefined as hasExpertSettingDef,
  hasDocumentObservers as hasDocumentObs
} from '@/services/documentService';

const emit = defineEmits(['enforce-authority-service']);
import { useDocumentContext } from '@/composables/useDocumentContext';
import type { AuthorityService } from '@/types/enums/AuthorityService';
import { useRoute } from 'vue-router';
import { useStorage } from '@vueuse/core';
import { useCreateStore } from '@/stores/create.ts';
import { SigneeType } from '@/types/enums/SigneeType.ts';

const { currentDocument } = useDocumentContext();
const createStore = useCreateStore();
const i18n = useI18n();

const serviceStore = useServiceStore();
const preferencesStore = usePreferencesStore();
const emitter = useEmitter();
const allowAttachments = ref(false);
const enforceAuthorityService = ref<boolean | undefined>(
  !!createStore.document?.requiredAuthorityService
);
const route = useRoute();
const sessionExpertSettings = useStorage(
  'expertSettings',
  false,
  sessionStorage
);
const authorityService = ref<
  | {
      key: AuthorityService | undefined;
    }
  | undefined
>({
  key: createStore.document?.requiredAuthorityService
});

const isPublicCreateRoute = computed(() => {
  return route.name === 'p-document-create';
});

const expertSettings = computed(() => {
  if (isPublicCreateRoute.value) {
    return sessionExpertSettings.value;
  } else if (preferencesStore.preferences) {
    return preferencesStore.preferences.expertSettings;
  } else {
    return false;
  }
});

serviceStore.fetchAvailableModes();

onMounted(() => {
  emitter.$on('set-authority-service', setAuthorityService);
  if (allSignaturesArePlaced.value) {
    createStore.placeSignaturesModel = true;
  }
  if (createStore.document && createStore.document.signeeAttachmentsAllowed) {
    allowAttachments.value = createStore.document.signeeAttachmentsAllowed;
  }
});

onUnmounted(() => {
  emitter.$off('set-authority-service', setAuthorityService);
});

const allSignaturesArePlaced = computed(() => {
  return (
    currentDocument.value &&
    createStore.filteredSignees &&
    createStore.filteredSignees.length ===
      createStore.placedSignatures.length &&
    createStore.filteredSignees.length > 0
  );
});

const canEnableAttachments = computed(() => {
  let result = true;
  if (!createStore.hasInvitees || !createStore.hasSignees) {
    result = false;
  }

  if (
    createStore.ordered &&
    createStore.hasApprovers &&
    createStore.hasSignees
  ) {
    const firstPossibleSigneeGroup = createStore.document!.signeesOrdered.find(
      (group) => group.find((signee) => signee.signeeType === SigneeType.SIGN)
    );
    const firstPossibleSigneeGroupWithoutApprovers =
      firstPossibleSigneeGroup?.filter(
        (signee) => signee.signeeType === SigneeType.SIGN
      ) || null;
    if (
      firstPossibleSigneeGroup &&
      firstPossibleSigneeGroupWithoutApprovers &&
      firstPossibleSigneeGroupWithoutApprovers.length > 1
    ) {
      result = false;
    }
  }

  if (
    !createStore.ordered &&
    createStore.filteredSignees &&
    createStore.filteredSignees.length > 1
  ) {
    result = false;
  }
  return result;
});

const canEnforceAuthorityService = computed(() => {
  return (
    createStore.document &&
    createStore.document.signatureMode !== SignatureMode.TIMESTAMP &&
    !(
      createStore.document?.signatureMode === SignatureMode.ADVANCED &&
      createStore.document?.jurisdiction === Jurisdiction.EIDAS
    )
  );
});

watch(
  () => createStore.document?.signatureMode,
  (value) => {
    if (value === SignatureMode.TIMESTAMP) {
      enforceAuthorityService.value = undefined;
      authorityService.value = undefined;
    }
  },
  { deep: true }
);

watch(
  () => createStore.document?.jurisdiction,
  (value) => {
    if (
      value === Jurisdiction.EIDAS &&
      createStore.document?.signatureMode === SignatureMode.ADVANCED
    ) {
      enforceAuthorityService.value = undefined;
      authorityService.value = undefined;
    }
  },
  { deep: true }
);

watch(
  () => authorityService.value,
  (value) => {
    emit('enforce-authority-service', value?.key || null);
  }
);

watch(
  () => enforceAuthorityService.value,
  async (value) => {
    if (value) {
      await serviceStore.fetchAvailableModes();
      if (
        authorityService.value === null ||
        authorityService.value === undefined ||
        authorityService.value?.key === null ||
        authorityService.value?.key === undefined
      ) {
        if (availableAuthorityServices.value[0]?.key) {
          setAuthorityService(availableAuthorityServices.value[0]?.key);
        }
      }
    }
  }
);

watch(
  () => expertSettings.value,
  (newValue) => {
    if (!newValue) {
      //reset expert setting options if user disables expert settings
      updateAllowAttachments(false);
      updateEnforceAuthorityService(false);
    }
  }
);

const availableAuthorityServices = computed(
  (): { key: AuthorityService }[] | never[] => {
    if (!serviceStore.availableModes) return [];
    if (createStore.document) {
      // eslint-disable-next-line prettier/prettier
      const services =
        serviceStore.availableModes[createStore.document.signatureMode][
          createStore.document.jurisdiction
        ];
      if (!services) {
        return [];
      }
      return orderBy(
        services.map((mode: AuthorityService) => {
          return {
            key: mode
          };
        }),
        ['key']
      );
    } else {
      return [];
    }
  }
);

function setAuthorityService(serviceKey: AuthorityService) {
  const newAuthService = availableAuthorityServices.value.find((service) => {
    if (service.key === serviceKey) {
      return service.key === serviceKey;
    }
  });
  if (newAuthService) {
    authorityService.value = newAuthService;
  }
}

const hasExpertSettingsDefined = computed(() => {
  if (createStore.document) {
    return hasExpertSettingDef(createStore.document);
  } else {
    return false;
  }
});

const hasObservers = computed(() => {
  if (createStore.document) {
    return hasDocumentObs(createStore.document);
  } else {
    return undefined;
  }
});

const hasRequiredAuthorityService = computed(() => {
  return createStore.document && createStore.document.requiredAuthorityService;
});

watch(canEnableAttachments, (newValue) => {
  if (
    createStore.document &&
    createStore.document.signeeAttachmentsAllowed &&
    !newValue
  ) {
    allowAttachments.value = false;
    updateAllowAttachments(false);
  }
});

function updateAllowAttachments(value: boolean) {
  allowAttachments.value = value;
  emitter.$emit('update-attachments-option', value);
}

function updateEnforceAuthorityService(value: boolean) {
  enforceAuthorityService.value = value;
  if (!enforceAuthorityService.value) {
    authorityService.value = undefined;
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/core/colors';

.position-and-message-card {
  box-sizing: border-box;
  padding: 0;
}

.place-button {
  color: $primary-color;
}

.remove-button {
  color: $grey-darken-1;
}

.preview-switch {
  padding: 0;
  margin: 0;
}

.move-to-page {
  padding-bottom: 9px;
  font-weight: 400;
  font-size: 13px;
}

.move-to-page-menu {
  border-radius: 20px;
}

.place-signatures-hint {
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.004em;
}

.disabled {
  color: $grey;
}
</style>
