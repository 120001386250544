import deepSignApi from '@/api/deepsign/deepsignApi';
import type { AxiosRequestConfig } from 'axios';

export const storeSigneeInvitation = (
  documentId: string,
  signeeId: string,
  config?: AxiosRequestConfig
) =>
  deepSignApi.post(
    `documents/${documentId}/signees/${signeeId}/resend-invitation`,
    config
  );
