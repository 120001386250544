<template>
  <div
    id="swipeSurface"
    v-touch="{ down: swipeDown }"
    class="d-flex flex-row justify-center grow"
    @click="swipeDown"
  >
    <svg
      fill="none"
      height="4"
      viewBox="0 0 62 4"
      width="62"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect :fill="color" height="4" rx="2" width="62" />
    </svg>
  </div>
</template>

<script setup lang="ts">
defineProps({
  color: { type: String, default: '#D9D9D9' }
});

const emit = defineEmits(['close-bottom-sheet']);

const swipeDown = () => {
  emit('close-bottom-sheet');
};
</script>
