<template>
  <svg
    fill="none"
    height="181"
    viewBox="0 0 136 181"
    width="136"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill="white" height="181" width="136" />
    <rect fill="#D9D9D9" height="6" width="59" x="15" y="19" />
    <rect fill="#D9D9D9" height="6" width="107" x="15" y="38" />
    <rect fill="#D9D9D9" height="6" width="107" x="15" y="52" />
    <rect fill="#D9D9D9" height="6" width="89" x="15" y="68" />
    <rect fill="#D9D9D9" height="6" width="52" x="15" y="130" />
    <rect fill="#D9D9D9" height="6" width="26" x="15" y="142" />
  </svg>
</template>
