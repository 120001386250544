import axios from 'axios';
import { onRejected } from '@/api/interceptor';
import { setAuthorizationHeaders } from '@/api/utils';

const globalAxios = axios.create({
  paramsSerializer: {
    indexes: null
  }
});

globalAxios.interceptors.request.use(async (config) => {
  const headers = {};

  await setAuthorizationHeaders(headers);

  Object.assign(config.headers, headers);
  return {
    ...config
  };
});

globalAxios.interceptors.response.use((response) => response, onRejected);

export default globalAxios;
