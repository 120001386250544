import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type { Document } from '@/types/Document';
import type { Signee } from '@/types/Signee.ts';
import { SigneeType } from '@/types/enums/SigneeType.ts';
import AuthService from '@/services/auth-service.ts';
import { useLocalInviteesDataActions } from '@/components/create/composables/useLocalInviteesDataActions.ts';
import { useInviteesSignaturePlacement } from '@/components/create/composables/useInviteesSignaturePlacement.ts';
import { useCreateStoreHelpers } from '@/components/create/composables/useCreateStoreHelpers.ts';
import { useInviteesActions } from '@/components/create/composables/useInviteesActions.ts';

export const useCreateStore = defineStore('createStore', () => {
  //STATE
  const document = ref<Document | undefined>();
  const ordered = ref(false);
  const hasOrderBeenSetManually = ref(false);
  const isLoading = ref(false);
  const placeSignaturesModel = ref(false);
  const signOrderContextLabel = ref('');
  const signeesOrderedFlat = computed(
    () => document.value?.signeesOrdered?.flat() || []
  );
  const filteredSignees = computed(
    () =>
      document?.value?.signeesOrdered
        .flat()
        .filter((signee) => signee.signeeType === SigneeType.SIGN) || []
  );
  const filteredApprovers = computed(
    () =>
      document?.value?.signeesOrdered
        .flat()
        .filter((signee) => signee.signeeType === SigneeType.APPROVE) || []
  );
  const placedSignatures = computed(() => {
    if (filteredSignees.value) {
      return filteredSignees.value.filter(
        (signee) => signee.autographPosition !== null
      );
    }
    return [];
  });
  const nextGroupIndex = computed(() => {
    if (document.value?.signeesOrdered.length === 0 || !ordered.value) {
      return 0;
    }
    return document.value?.signeesOrdered.length || 0;
  });

  const hasInvitees = computed(() => signeesOrderedFlat.value.length > 0);
  const hasSignees = computed(() => {
    const signees = signeesOrderedFlat.value.filter(
      (signee) => signee.signeeType === SigneeType.SIGN
    );
    return signees.length > 0;
  });

  const hasApprovers = computed(() => {
    const approvers = signeesOrderedFlat.value.filter(
      (signee) => signee.signeeType === SigneeType.APPROVE
    );
    return approvers.length > 0;
  });

  const initiatorAsSignee = computed(() => {
    return filteredSignees.value?.find((s) =>
      compareString(s.email, AuthService.user?.email)
    );
  });

  const hasEmptyObservers = computed(() => {
    return document.value?.observers.some((o) => o.email === null) || false;
  });

  const hasEmptySignees = computed(() => {
    return (
      document.value?.signeesOrdered
        ?.flat()
        .some((invitee: Signee) => invitee.email === null) || false
    );
  });

  const { compareString, focusInputField, validateEmail } =
    useCreateStoreHelpers();
  const {
    addInvitee,
    deleteDocumentSignOrder,
    deleteObserver,
    createUpdateSignOrderPayload,
    enforceAuthorityService,
    getSignOrder,
    getAndSetSignOrderContext,
    updateDocumentSignOrder,
    updateDocumentById,
    updateSignatureMessage,
    updateAttachmentsAllow,
    updateAuthorityService,
    updateSignatureLevel,
    updateInvitee,
    updateSignOrderForInitialGrouping,
    updateSignOrderForInitialApproverGrouping,
    removeInvitee,
    removeAllInviteeFieldsWithEmptyEmail,
    removeAllObserversWithEmptyEmail
  } = useInviteesActions(useCreateStore());
  const {
    addDropZones,
    addInviteeToLocalData,
    clearInvalidFields,
    createInitialGroupsWithApprovers,
    filterEmptyInBetweenGroups,
    filterInviteeFromLocalData,
    updateSignOrderInLocalData,
    updateInviteeInLocalData,
    removeInviteeFromLocalData,
    sanitizeSigneeOrderedFields
  } = useLocalInviteesDataActions(useCreateStore());
  const {
    deleteSignatureElement,
    removeAllPlacedSignatures,
    removeSignature,
    resetSignaturePreviewStyle
  } = useInviteesSignaturePlacement(useCreateStore());

  //ACTIONS
  async function getDocument() {
    return document.value || null;
  }

  function setDocument(doc: Document) {
    document.value = doc;
  }

  //HELPERS
  function resetStore() {
    document.value = undefined;
    ordered.value = false;
    isLoading.value = false;
    placeSignaturesModel.value = false;
  }

  //TODO refactor this asap
  async function onOrderSwitchToggle(
    orderSwitchValue: boolean,
    isApproverCase: boolean = false,
    tempGroups: Array<Array<Signee>> | null,
    id?: string
  ) {
    //clearInvalidFields();
    if (!orderSwitchValue && !isApproverCase) {
      await deleteDocumentSignOrder(document.value!.documentId);
    } else if (!isApproverCase) {
      await updateSignOrderForInitialGrouping(document.value!.documentId);
      document.value.signeesOrdered = document
        .value!.signeesOrdered.flat()
        .map((signee: Signee, index) => {
          // eslint-disable-next-line no-param-reassign
          signee.signOrder = index;
          return [signee];
        });
    } else if (isApproverCase && tempGroups) {
      await updateSignOrderForInitialApproverGrouping(
        document.value!.documentId,
        tempGroups
      );
      document.value!.signeesOrdered =
        createInitialGroupsWithApprovers(tempGroups);
    }
    ordered.value = orderSwitchValue;
    if (id) {
      await focusInputField(id);
    }
  }

  return {
    //STATE
    document,
    isLoading,
    filteredSignees,
    filteredApprovers,
    hasApprovers,
    hasInvitees,
    hasSignees,
    hasEmptySignees,
    hasEmptyObservers,
    hasOrderBeenSetManually,
    nextGroupIndex,
    ordered,
    initiatorAsSignee,
    placedSignatures,
    placeSignaturesModel,
    signOrderContextLabel,
    signeesOrderedFlat,
    //ACTIONS TO BE
    addInvitee,
    deleteDocumentSignOrder,
    deleteObserver,
    createUpdateSignOrderPayload,
    enforceAuthorityService,
    getSignOrder,
    getAndSetSignOrderContext,
    updateDocumentSignOrder,
    updateDocumentById,
    updateSignatureMessage,
    updateAttachmentsAllow,
    updateAuthorityService,
    updateSignatureLevel,
    updateInvitee,
    updateSignOrderForInitialGrouping,
    updateSignOrderForInitialApproverGrouping,
    removeInvitee,
    removeAllInviteeFieldsWithEmptyEmail,
    removeAllObserversWithEmptyEmail,
    //LOCAL DATA ACTIONS
    addInviteeToLocalData,
    createInitialGroupsWithApprovers,
    removeInviteeFromLocalData,
    filterInviteeFromLocalData,
    updateSignOrderInLocalData,
    filterEmptyInBetweenGroups,
    updateInviteeInLocalData,
    sanitizeSigneeOrderedFields,
    //HELPERS
    onOrderSwitchToggle,
    clearInvalidFields,
    addDropZones,
    focusInputField,
    validateEmail,
    createUpdateSignOrderPayload,
    resetStore,
    setDocument,
    getDocument,
    // SIGNATURE PLACEMENT ACTIONS
    removeAllPlacedSignatures,
    removeSignature,
    resetSignaturePreviewStyle,
    deleteSignatureElement
  };
});
