import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import type {
  BasicObserver,
  BasicSignee,
  DocumentDialogState
} from '@/types/ui';

export const useGlobalStore = defineStore('global', () => {
  const currentPage = ref(1);
  const history = ref<string[]>([]);
  const locale = ref();
  const pageCount = ref();
  const pdfPreviewRendered = ref(false);
  const drawer = ref<boolean | null>(null);
  const bottomSheet = ref<boolean | null>(null);
  const documentDialog = ref<boolean | null>(null);
  const documentDialogState = ref<DocumentDialogState>(null);
  const selectedSeal = ref(null);

  const selectedSignee = ref<BasicSignee>();

  watch(documentDialog, (value) => {
    if (!value) {
      // on dialog close we reset the store
      documentDialogState.value = null;
      selectedSignee.value = undefined;
      selectedObserver.value = undefined;
      documentDialogState.value = null;
    }
  });

  function setLocale(localeParam: string | null) {
    locale.value = localeParam;
  }

  function setCurrentPage(currentPageParam: number) {
    currentPage.value = currentPageParam;
  }

  function setPageCount(pageCountParam: number | null) {
    pageCount.value = pageCountParam;
  }

  function addRouteToHistory(route: string) {
    history.value.push(route);
  }

  // Observers
  const selectedObserver = ref<BasicObserver>();

  function setSelectedObserver(value?: BasicObserver) {
    selectedObserver.value = value;
  }

  function setSelectedSeal(value?: any) {
    selectedSeal.value = value;
  }

  return {
    // State
    currentPage,
    locale,
    pageCount,
    pdfPreviewRendered,
    history,
    drawer,
    bottomSheet,
    documentDialog,
    documentDialogState,
    selectedSignee,
    selectedSeal,

    // Actions
    setLocale,
    setCurrentPage,
    setPageCount,
    addRouteToHistory,

    // Observers
    selectedObserver,
    setSelectedObserver,
    setSelectedSeal
  };
});
