import type {
  OnboardingStatus,
  UserValidationApiResponse
} from '@/types/onboarding/OnboardingStatus';
import type { UserValidationPayload } from '@/types/onboarding/UserValidationPayload';
import { VerificationSource } from '@/types/enums/VerificationSource';
import { VerificationStatus } from '@/types/enums/VerificationStatus';
import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import type { UserValidation } from '@/types/UserValidation';
import type { CompanyStatus } from '@/types/onboarding/CompanyStatus';
import { useSignStore } from '@/stores/sign/sign';
import { AuthorityService } from '@/types/enums/AuthorityService';
import globalAxios from '@/composables/useAxios';

export const useDeepAdminInternalStore = defineStore(
  'deepadmin-internal',
  () => {
    const signStore = useSignStore();

    const fetchOnboardingStatusPending = ref(false);
    const startUserValidationPending = ref(false);
    const onboardingStatus = ref<OnboardingStatus>({
      companies: [] as CompanyStatus[],
      user_validations: [] as UserValidation[]
    } as OnboardingStatus);

    const userValidations = computed(() => {
      return onboardingStatus.value?.user_validations;
    });

    const isVerified = computed(() => {
      return userValidations.value?.some(
        (uv) => uv.status === VerificationStatus.VERIFIED
      );
    });

    const isInProgress = computed(() => {
      return userValidations.value?.some(
        (uv) => uv.status === VerificationStatus.IN_PROGRESS
      );
    });

    const isNotVerifiedOrInProgress = computed(() => {
      return !isVerified.value && !isInProgress.value;
    });

    const isDeepIDVerification = computed(() => {
      return userValidations.value?.some(
        (uv) => uv.source === VerificationSource.DEEP_ID
      );
    });

    const shouldStartValidation = computed(() => {
      return (
        userValidations.value === null ||
        isNotVerifiedOrInProgress.value ||
        !isDeepIDVerification.value
      );
    });

    async function fetchOnboardingStatus() {
      try {
        fetchOnboardingStatusPending.value = true;

        const response = await globalAxios.get(
          `${
            import.meta.env.VITE_DEEPADMIN_INTERNAL_API_BASE_URL
          }onboarding/status?source=${VerificationSource.DEEP_ID}`
        );
        onboardingStatus.value = response.data as OnboardingStatus;
        if (isDeepIDVerification.value && isVerified.value) {
          signStore.enableAuthorityService(AuthorityService.DEEP_ID);
        }
        fetchOnboardingStatusPending.value = false;
        return Promise.resolve();
      } catch (error) {
        fetchOnboardingStatusPending.value = false;
        return Promise.reject(error);
      }
    }

    async function startUserValidation(payload: UserValidationPayload) {
      try {
        startUserValidationPending.value = true;
        const body = {
          first_names: payload?.first_name || null,
          last_name: payload?.last_name || null,
          source: payload?.source || null,
          purpose: payload?.purpose || null
        };
        const response = await globalAxios.post(
          `${
            import.meta.env.VITE_DEEPADMIN_INTERNAL_API_BASE_URL
          }onboarding/user_validation`,
          body
        );
        startUserValidationPending.value = false;
        const { companies, user_validations } =
          response.data as UserValidationApiResponse;

        onboardingStatus.value = { companies, user_validations };
        return Promise.resolve(response);
      } catch (error) {
        startUserValidationPending.value = false;
        return Promise.reject(error);
      }
    }

    async function getCompanies(payload: string) {
      try {
        const params = {
          query: payload
        };
        const response = await globalAxios.get(
          `${
            import.meta.env.VITE_DEEPADMIN_INTERNAL_API_BASE_URL
          }onboarding/deepd`,
          { params }
        );

        if (response.data && response.data.length > 0) {
          return response.data;
        }
      } catch (error) {
        console.log(error);
      }
    }

    return {
      // State
      fetchOnboardingStatusPending,
      onboardingStatus,
      startUserValidationPending,

      // Getters
      isDeepIDVerification,
      isInProgress,
      isNotVerifiedOrInProgress,
      isVerified,
      shouldStartValidation,
      userValidations,

      // Actions
      fetchOnboardingStatus,
      startUserValidation,
      getCompanies
    };
  }
);
