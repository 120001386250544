<template>
  <div class="flex flex-col">
    <div
      v-if="['idle', 'progress'].includes(emailUpdateStatus)"
      class="dialog-text-hint"
    >
      {{ i18n.t('texts.update_signee_email_hint') }}
    </div>
    <v-form
      v-if="['idle', 'progress'].includes(emailUpdateStatus)"
      ref="editEmailForm"
      @submit.prevent="sendInvitation"
    >
      <v-text-field
        key="invitation-email-input"
        v-model.trim="email"
        :label="i18n.t('labels.email')"
        autocapitalize="off"
        autocomplete="off"
        autocorrect="off"
        autofocus
        type="email"
        class="mt-2 bg-white"
        density="compact"
        variant="outlined"
        :rules="emailRules"
        clearable
        data-test-id="update-email-input"
        clear-icon="fal fa-circle-xmark"
      />
    </v-form>

    <DeepSignAlert v-if="emailUpdateStatus === 'error'" class="mb-5" error>
      <div class="text-sm">
        {{ i18n.t('errors.generic') }}
      </div>
    </DeepSignAlert>

    <v-btn
      v-if="emailUpdateStatus !== 'error'"
      class="mt-2"
      elevation="0"
      :disabled="!isFormValid"
      color="primary"
      @click="sendInvitation"
      >{{ i18n.t('buttons.send_invitation') }}
    </v-btn>

    <v-btn class="mt-3" variant="outlined" color="primary" @click="closeDialog">
      {{
        i18n.t(
          emailUpdateStatus === 'error' ? 'buttons.close' : 'buttons.cancel'
        )
      }}
    </v-btn>
  </div>
</template>

<script setup lang="ts">
import { useDocumentContext } from '@/composables/useDocumentContext';
import { useEmitter } from '@/composables/useEmitter';
import { useValidationRules } from '@/composables/useValidationRules';
import { useGlobalStore } from '@/stores/global/global';
import { useSignStore } from '@/stores/sign/sign';
import type { ComponentState } from '@/types/ui';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const i18n = useI18n();
const { emailRules } = useValidationRules();
const editEmailForm = ref();
const signStore = useSignStore();
const globalStore = useGlobalStore();
const emit = defineEmits(['closeDialog']);
const email = ref<string | null>(globalStore.selectedSignee?.email || null);
const emailUpdateStatus = ref<ComponentState>('idle');
const emitter = useEmitter();
const { currentDocument } = useDocumentContext();

const isFormValid = computed(() => {
  if (!editEmailForm.value || email.value === null) return false;
  return editEmailForm.value.isValid;
});

function closeDialog() {
  email.value = null;
  emit('closeDialog');
}

async function sendInvitation() {
  try {
    emailUpdateStatus.value = 'progress';
    if (
      globalStore.selectedSignee?.signeeId &&
      email.value &&
      isFormValid &&
      currentDocument.value
    ) {
      await signStore.updateSigneeEmail(
        currentDocument.value.documentId,
        globalStore.selectedSignee.signeeId,
        { email: email.value }
      );
      emailUpdateStatus.value = 'success';
      closeDialog();
      emitter.$emit('update-sign-status');
    }
    emailUpdateStatus.value = 'idle';
  } catch (error) {
    emailUpdateStatus.value = 'error';
  }
}
</script>

<style scoped lang="scss">
:deep(.v-text-field.v-text-field--enclosed) .v-text-field__details {
  padding-left: 0 !important;
}

.v-input :deep(.v-input__details) {
  padding: 0;
  min-height: 18px;
}

.v-input.v-text-field {
  margin-bottom: 4px;
}
</style>
