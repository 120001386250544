<template>
  <div
    v-if="seal?.sealType"
    class="document-sign-type"
    data-test-id="sign-type"
  >
    <i class="fal fa-award text-lg text-primary" />
    <span> {{ sealLevel }} </span>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import { SealType } from '@/types/enums/SealType';
import { Jurisdiction } from '@/types/enums/Jurisdiction';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import type { SignSeal } from '@/types/deepsign/SignSeal';

const i18n = useI18n();
const props = defineProps({
  seal: {
    type: Object as PropType<SignSeal>,
    default: null
  }
});

const sealLevel = computed(() => {
  if (props.seal?.sealType) {
    let sealLevelText = '';
    switch (props.seal.sealType) {
      case SealType.AES:
        sealLevelText = `${i18n.t('texts.seal_types_description.AES')}`;
        break;
      case SealType.QES:
        if (props.seal.jurisdiction === Jurisdiction.ZERTES)
          sealLevelText = `${i18n.t('texts.seal_types_description.QES_CH')}`;

        if (props.seal.jurisdiction === Jurisdiction.EIDAS)
          sealLevelText = `${i18n.t('texts.seal_types_description.QES_EU')}`;
        break;
      case SealType.GOV:
        sealLevelText = `${i18n.t('texts.seal_types_description.GOV')}`;
        break;
      default:
        sealLevelText = '';
    }

    return sealLevelText;
  }
  return '';
});
</script>

<style lang="scss">
@import '@/styles/core/colors';

.document-sign-type {
  display: flex;
  gap: 0.5rem;
  align-items: center;

  span {
    color: $grey;
    font-weight: 400 !important;
    margin-right: 0.25rem;
    font-size: 14px !important;
  }
  .menu-icon {
    color: $grey;
    padding-top: 0px;
  }

  .signature-level-description {
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.004em;
  }
}
</style>
