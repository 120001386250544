<template>
  <VBtn
    data-test-id="new-button"
    prepend-icon="far fa-file-plus"
    color="primary"
    @click="openNewDocumentDialog"
  >
    <template #prepend>
      <VIcon>fa-regular fa-plus</VIcon>
    </template>

    {{ i18n.t('buttons.new_document') }}
  </VBtn>
</template>

<script lang="ts" setup>
import { useEmitter } from '@/composables/useEmitter';
import { useI18n } from 'vue-i18n';

const i18n = useI18n();
const emitter = useEmitter();

function openNewDocumentDialog() {
  emitter.$emit('open-new-document-dialog');
}
</script>
