<template>
  <div v-if="!createStore.ordered && createStore.hasInvitees">
    <div
      v-for="signee in createStore.document?.signeesOrdered.flat()"
      :key="signee.signeeId"
      class="my-2"
    >
      <div class="flex flex-row align-center">
        <EmailSuggestion
          :id="`email-${signee.signeeId}`"
          v-model.trim="signee.email"
          :data-test-id="`signee-email-${signee.email}`"
          :autocomplete="`signee-email-${signee.email}-${signee.signeeId}`"
          :readonly="isRequestorSignee(signee)"
          :rules="signatureRules"
          :label="getFieldLabel(signee.signeeType)"
          :email-to-hide="initiatorSigneeEmail"
          :class="{ initiator: isRequestorSignee(signee) }"
          :name="`signee-email-${signee.email}-${signee.signeeId}`"
          @blur="emit('on-blur', signee)"
          @focus="emit('on-focus', signee)"
          @update:model-value="emit('on-update', { event: $event, signee })"
          @keydown.enter="emit('on-update-key-down', { event: $event, signee })"
        >
          <template #prepend-inner>
            <v-icon v-if="createStore.hasApprovers" color="primary">{{
              signee?.signeeType === SigneeType.SIGN
                ? 'fa-regular fa-pen-nib'
                : 'fa-light fa-octagon-check'
            }}</v-icon>
          </template>
        </EmailSuggestion>

        <v-btn
          class="mt-2 ml-1 text-gray-400"
          :class="{
            'opacity-25 cursor-not-allowed pointer-events-none': isLoading
          }"
          color="default"
          icon="fal fa-xmark"
          size="medium"
          data-test-id="remove-signee"
          :disabled="createStore.isLoading"
          @click="emit('remove-invitee', signee)"
        >
          <v-icon>fal fa-xmark</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useValidationRules } from '@/composables/useValidationRules.ts';
import { useCreateStore } from '@/stores/create.ts';
import type { Signee } from '@/types/Signee.ts';
import { computed } from 'vue';
import { useDocumentAuth } from '@/composables/useDocumentAuth.ts';
import { SigneeType } from '@/types/enums/SigneeType.ts';
import { useI18n } from 'vue-i18n';
const { signatureRules } = useValidationRules();
const createStore = useCreateStore();
const documentAuth = useDocumentAuth();
const { t } = useI18n();
const emit = defineEmits([
  'remove-invitee',
  'on-update-invitee',
  'on-blur',
  'on-focus',
  'on-update',
  'on-update-key-down'
]);

defineProps({
  isLoading: {
    type: Boolean,
    default: false
  }
});

function isRequestorSignee(signee: Signee) {
  return (
    signee.signeeId === createStore.initiatorAsSignee?.signeeId &&
    !isDocumentAuthAuthenticated.value
  );
}

const isDocumentAuthAuthenticated = computed(() => {
  return documentAuth.isAuthenticated.value;
});

const initiatorSigneeEmail = computed(() => {
  return createStore.document?.initiatorDisplayEmail || '';
});

function getFieldLabel(signeeType: SigneeType) {
  return signeeType === SigneeType.SIGN
    ? t('labels.signee_email')
    : t('labels.approver_email');
}
</script>
