import deepSignApi from '@/api/deepsign/deepsignApi';
import type { AxiosRequestConfig } from 'axios';

const url = 'documents/file/seal';

/**
 * Uploads a PDF file. The payload is sent as 2 mime parts separated with boundaries.
 * One part contains the file binary and the other part contains the document payload structure.
 * The new document will have a signee with signeeType=seal for the user uploading the document and the document will be started (documentStatus=in_progress).
 * @param data
 * @param config
 */
export const uploadDocumentToBeSealed = (
  data?: FormData,
  config?: AxiosRequestConfig
) => deepSignApi.post(url, data, config);
