<template>
  <v-dialog
    v-model="showDialog"
    persistent
    width="600"
    @keydown.esc="showDialog = false"
  >
    <v-card class="p-6" rounded="lg">
      <!-- TITLE -->
      <v-card-title class="w-full mx-auto text-center">
        <span class="text-2xl font-bold">
          {{ i18n.t('titles.withdraw_document') }}
        </span>
      </v-card-title>
      <!-- TEXT -->
      <v-card-text class="!p-0 text-black text-center mt-6 mb-8">
        {{ i18n.t('texts.withdraw_document') }}
      </v-card-text>
      <div class="text-sm text-zinc-600 mb-1">
        {{ i18n.t('titles.withdraw_message') }}
        ({{ i18n.t('captions.optional') }})
      </div>
      <v-textarea
        v-model="message"
        :label="i18n.t('labels.message')"
        no-resize
        variant="outlined"
        :placeholder="i18n.t('placeholders.withdraw')"
        data-test-id="withdraw-message-dialog"
      />

      <div class="pt-6">
        <DeepButton
          :action="withdraw"
          class="mb-3"
          :loading="withdrawDocumentPending"
          :text="i18n.t('buttons.withdraw')"
          data-test-id="withdraw-document"
        />
        <DeepButton
          :action="() => (showDialog = false)"
          variant="outlined"
          :text="i18n.t('buttons.cancel')"
          data-test-id="close-dialog-withdraw"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { storeWithdrawnDocument } from '@/api/deepsign/withdraw';
import { SendMail } from '@/types/enums/SendMail';
import { useMessageStore } from '@/stores/message/message';
import { ref, onUnmounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useEmitter } from '@/composables/useEmitter';
import { useSignStore } from '@/stores/sign/sign';

const emitter = useEmitter();

emitter.$on('open-document-withdraw-dialog', openDialog);

onUnmounted(() => {
  emitter.$off('open-document-withdraw-dialog', openDialog);
});

const showDialog = ref(false);
const message = ref();
const withdrawDocumentPending = ref(false);
const router = useRouter();
const route = useRoute();
const messageStore = useMessageStore();
const i18n = useI18n();
const documentID = ref();
const signStore = useSignStore();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const _documents = ref<any[]>([]);
const emit = defineEmits(['done']);
function openDialog(documents) {
  if (documents) _documents.value = documents;

  //If on DocumentView
  if (
    signStore.signInfo &&
    signStore.signInfo.document &&
    signStore.signInfo.document.documentId
  ) {
    _documents.value = [signStore.signInfo.document];
  }
  showDialog.value = true;
}

async function withdraw() {
  let isError = false;
  withdrawDocumentPending.value = true;

  const withdrawPromises = _documents.value.map((document) => {
    const payload = {
      message: message.value,
      sendEmail: SendMail.OTHERS
    };

    return storeWithdrawnDocument(document.documentId, payload).catch(() => {
      isError = true;
      messageStore.showMessage({
        key: 'errors.api.withdraw_document',
        color: 'error',
        icon: 'fa fa-circle-exclamation'
      });
    });
  });

  await Promise.all(withdrawPromises)
    .then(async () => {
      if (!isError) {
        if (route.name === 'dashboard') {
          emit('done');
        } else {
          router.push(`/document/${_documents.value[0].documentId}/details`);
        }
        messageStore.showMessage({
          key: 'snackbars.document_withdrawn',
          color: 'success',
          icon: 'fa fa-circle-check'
        });
      }
    })
    .catch(() => {
      isError = true;
    })
    .finally(() => {
      if (isError) {
        messageStore.showMessage({
          key: 'errors.api.withdraw_documents',
          color: 'error',
          icon: 'fa fa-circle-exclamation'
        });
      }

      withdrawDocumentPending.value = false;
      showDialog.value = false;
      message.value = undefined;
      documentID.value = undefined;
    });
}
</script>
