<template>
  <div class="flex flex-col w-full">
    <!-- TITLE -->
    <div class="flex flex-row h-[24px] rounded-0">
      {{ i18n.t('titles.approved_as') }}
    </div>
    <!-- /TITLE -->

    <div class="flex items-center justify-between">
      <div>
        <!-- APPROVERS  DETAILS -->
        <SignerSignDetails :email="email" :show-signature-level="false" />
        <!-- /APPROVERS  DETAILS -->
        <div class="flex items-center justify-center gap-1 mt-1">
          <span class="text-sm text-green-600">
            {{ i18n.t('document_status.approved') }}:
          </span>
          <div v-if="signedTime" class="flex items-center">
            <span class="text-gray-400 text-sm">
              {{ formatDateToLocale(signedTime, i18n.locale.value, true) }}
            </span>
          </div>
          <v-spacer />
        </div>
      </div>
      <v-icon
        v-if="signStore.signInfo?.signStatus === SignStatus.APPROVED"
        color="success"
        size="18"
      >
        fas fa-circle-check
      </v-icon>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { useSignStore } from '@/stores/sign/sign.ts';
import { formatDateToLocale } from '@/composables/useUtils.ts';
import { SignStatus } from '@/types/enums/SignStatus.ts';

const i18n = useI18n();
const signStore = useSignStore();
const email = computed(() => signStore.signInfo?.email);
const signedTime = computed(() => signStore.signInfo?.signedTime);
</script>
