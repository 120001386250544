<template>
  <v-expansion-panels
    v-show="expertSettings || (files && files.length > 0)"
    v-model="panel"
    class="py-2"
  >
    <v-expansion-panel elevation="0">
      <v-expansion-panel-title
        static
        class="attachment-panel-header"
        data-test-id="attachments-panel"
      >
        <div class="drawer-section-title text-uppercase">
          {{ i18n.t('titles.attachments') }}
        </div>
        <template #actions="{ expanded }">
          <v-icon size="16" :class="{ 'fa-rotate-180': expanded }">
            fal fa-chevron-down
          </v-icon>
        </template>
      </v-expansion-panel-title>
      <v-expansion-panel-text class="attachment-panel-content">
        <Attachments
          v-model="files"
          attachment-type="attachment"
          supported-types="application/pdf"
          @update:model-value="handleUpload"
          @remove-file="handleRemove"
        />
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import globalAxios from '@/composables/useAxios';
import { useDocumentContext } from '@/composables/useDocumentContext';
import type { AttachmentFile } from '@/types/Attachment';
import { AttachmentStatus } from '@/types/enums/AttachmentStatus';
import { usePreferencesStore } from '@/stores/deepadmin/preferences';
import { useStorage } from '@vueuse/core';
import { useRoute } from 'vue-router';
import { v4 as uuid } from 'uuid';

const apiBaseUrl = import.meta.env.VITE_DEEPSIGN_API_BASE_URL;
const i18n = useI18n();
const files = ref<AttachmentFile[]>();
const { currentDocument } = useDocumentContext();
const preferencesStore = usePreferencesStore();
const route = useRoute();
const panel = ref(0);

const sessionExpertSettings = useStorage(
  'expertSettings',
  false,
  sessionStorage
);

const isPublicCreateRoute = computed(() => {
  return route.name === 'p-document-create';
});

const expertSettings = computed(() => {
  if (isPublicCreateRoute.value) {
    return sessionExpertSettings.value;
  } else if (preferencesStore.preferences) {
    return preferencesStore.preferences.expertSettings;
  } else {
    return false;
  }
});

async function handleUpload(filesToUpload: AttachmentFile[]) {
  // Update the files array initially to reflect the new files
  files.value = filesToUpload;

  // Map through the files and create an array of Promises for each upload
  const uploadPromises = files.value.map(async (item) => {
    if (item.status === AttachmentStatus.PENDING) {
      // Set the status to PROGRESS before starting the upload
      // eslint-disable-next-line no-param-reassign
      item.status = AttachmentStatus.PROGRESS;

      try {
        const formData = new FormData();
        if (item.file) {
          formData.append('file', item.file);
          const url = `${apiBaseUrl}documents/${currentDocument.value?.documentId}/attachments`;

          // Perform the file upload
          const response = await globalAxios.post(url, formData, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          });

          // Extract the attachment information from the response
          const { attachmentId, name, size, mimeType } = response.data;

          // Update the uploaded file's info and status in the files array
          const newFileData = {
            ...item,
            attachmentId,
            name,
            size,
            mimeType,
            status: AttachmentStatus.ATTACHED
          };

          // Update the specific file in the files array after upload success
          files.value = files.value?.map((fileItem) =>
            fileItem.uploadId === item.uploadId ? newFileData : fileItem
          );
        }
      } catch (error) {
        // Update the status to ERROR if something goes wrong
        files.value = files.value?.map((fileItem) =>
          fileItem.uploadId === item.uploadId
            ? {
                ...fileItem,
                status: AttachmentStatus.ERROR,
                errorId: error.response?.data?.messageId || 'Unknown error'
              }
            : fileItem
        );
      }
    }
  });

  // Execute all the upload operations in parallel
  await Promise.all(uploadPromises);
}

async function handleRemove(fileToRemove: AttachmentFile) {
  try {
    // Change only the specific file's status to DISABLED to prevent further actions
    files.value = files.value?.map((file) =>
      file.uploadId === fileToRemove.uploadId
        ? { ...file, status: AttachmentStatus.DISABLED }
        : file
    );

    // If the file was already uploaded (status is ATTACHED), proceed with the deletion request
    if (fileToRemove.status === AttachmentStatus.ATTACHED) {
      const url = `${apiBaseUrl}documents/${currentDocument.value?.documentId}/attachments/${fileToRemove.attachmentId}`;
      await globalAxios.delete(url);

      // Remove the file from the `files` array after deletion
      files.value = files.value?.filter(
        (file) => file.attachmentId !== fileToRemove.attachmentId
      );
    } else {
      // If the file is not yet uploaded, simply remove it from the files array
      files.value = files.value?.filter(
        (file) => file.uploadId !== fileToRemove.uploadId
      );
    }
  } catch (error) {
    console.error('Error removing attachment:', error);

    // Optional: Set the status back to ATTACHED or PENDING if deletion fails (or handle it in some other way)
    files.value = files.value?.map((file) =>
      file.uploadId === fileToRemove.uploadId
        ? { ...file, status: fileToRemove.status } // Revert to the previous status if the delete fails
        : file
    );
  }
}

watch(
  () => currentDocument.value?.attachments,
  (newValue) => {
    if (newValue) {
      files.value = newValue.map((file) => {
        return {
          attachmentId: file.attachmentId,
          mimeType: file.mimeType,
          name: file.name,
          size: file.size,
          uploadId: uuid(),
          status: AttachmentStatus.ATTACHED
        };
      });
    }
  },
  {
    deep: true
  }
);
</script>

<style scoped lang="scss">
:deep(.v-expansion-panel-title__overlay) {
  background-color: white;
}

.attachment-panel-header {
  padding-left: 0;
  padding-bottom: 0;
  padding-right: 0;
  color: black;
}

.attachment-panel-content :deep(.v-expansion-panel-content__wrap) {
  padding-left: 8px;
  padding-right: 0 !important;
}

.attachment-panel-content :deep(.v-expansion-panel-text__wrapper) {
  padding: 0px !important;
}

.v-expansion-panel :deep(.v-expansion-panel-title) {
  padding-top: 0px !important;
}
</style>
