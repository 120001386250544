import AuthService from '@/services/auth-service';
import type { Route } from 'vue-router';

function getEmail(route: Route) {
  return route.query.email;
}

function getRedirect(route: Route) {
  return route.query.redirectUri;
}

const reconnectGuardian = () => {
  return async (to: Route) => {
    AuthService.login({
      loginHint: getEmail(to),
      redirectUri: getRedirect(to)
    });
  };
};

export default reconnectGuardian;
