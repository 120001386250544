import { getSubjects } from '@/api/deepadmin/companyMembers';
import { ref, watch } from 'vue';
import { usePreferencesStore } from '@/stores/deepadmin/preferences';

const MAX_COUNT_MEMBERS_TO_SHOW = 6;
const MAX_COUNT_RECENTLY_USED_EMAILS_TO_SHOW = 6;
const MAX_COUNT_RECENTLY_USED_EMAILS_TO_SAVE = 100;

interface EmailSuggestion {
  email: string;
  isOrgMember?: boolean;
  count?: number | 0;
  lastUsed?: string;
  header?: Header;
  divider?: boolean;
  [key: string]: any;
}

interface Header {
  type: string;
  title: string;
  hasEmailMatches: boolean;
}

export function useEmailService(
  companyId?: string | undefined,
  emailToHide?: string | undefined
) {
  const preferenceStore = usePreferencesStore();
  const isLoading = ref(false);

  const recentlyUsedEmails = ref<Array<Partial<EmailSuggestion>>>([]);

  watch(
    preferenceStore,
    (value) => {
      if (!value?.preferences?.recentlyUsedEmails) return;
      recentlyUsedEmails.value = sanitizeRecentlyUsedEmails(
        value.preferences.recentlyUsedEmails as Array<EmailSuggestion>
      );
    },
    {
      immediate: true,
      deep: true
    }
  );

  function compareByCount(
    a: Partial<EmailSuggestion>,
    b: Partial<EmailSuggestion>
  ) {
    return (b?.count || 0) - (a?.count || 0);
  }

  function sanitizeRecentlyUsedEmails(emailList: Array<EmailSuggestion>) {
    return emailList
      .sort(compareByCount)
      .map((item: Partial<EmailSuggestion>) => {
        const { email, count } = item;
        return {
          email,
          isOrgMember: false,
          count: count || 0
        };
      })
      .filter((item) => item.email !== emailToHide)
      .filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.email === value.email)
      );
  }

  function sanitizeOrgMembers(
    emailList: Array<EmailSuggestion>
  ): Array<Partial<EmailSuggestion>> {
    return emailList
      .map((item: Partial<EmailSuggestion>) => {
        const { email } = item;
        return { email, isOrgMember: true };
      })
      .filter((item) => item.email !== emailToHide);
  }

  function buildRecentlyUsedEmailList(emailList: Array<EmailSuggestion>) {
    // eslint-disable-next-line
    let aggregatedEmailList = emailList;
    //sort recently used emails by count
    aggregatedEmailList.sort(compareByCount);

    //truncate recently used emails after sorting by count
    if (aggregatedEmailList.length > MAX_COUNT_RECENTLY_USED_EMAILS_TO_SHOW) {
      aggregatedEmailList.length = MAX_COUNT_RECENTLY_USED_EMAILS_TO_SHOW;
    }

    const header = {
      type: 'subheader',
      title: 'components.emailSuggestions.recentlyUsedEmailsTitle',
      hasEmailMatches: aggregatedEmailList.length > 0
    };
    if (aggregatedEmailList.length > 0) {
      aggregatedEmailList.splice(0, 0, { header });
    }

    return aggregatedEmailList;
  }

  function buildOrgMemberEmailList(emailList: Array<Partial<EmailSuggestion>>) {
    // eslint-disable-next-line
    let aggregatedEmailList = emailList;
    //truncate org members after sorting
    if (aggregatedEmailList.length > MAX_COUNT_MEMBERS_TO_SHOW) {
      aggregatedEmailList.length = MAX_COUNT_MEMBERS_TO_SHOW;
    }

    if (aggregatedEmailList.length > 0) {
      const header = {
        type: 'subheader',
        title: 'components.emailSuggestions.orgMembersTitle',
        hasEmailMatches: true
      };
      aggregatedEmailList.splice(0, 0, { header });
    }

    return aggregatedEmailList;
  }

  async function getEmailSuggestionList(searchQuery: string) {
    //filter by query
    const filteredByQueryRecentlyUsedEmails = recentlyUsedEmails.value.filter(
      (item) => item.email!.includes(searchQuery.toLocaleLowerCase())
    );

    const aggregatedRecentlyUsedEmailList = buildRecentlyUsedEmailList(
      filteredByQueryRecentlyUsedEmails as Array<EmailSuggestion>
    );

    if (!searchQuery || searchQuery?.length < 3) {
      return {
        isLoading: false,
        aggregatedList: aggregatedRecentlyUsedEmailList
      };
    }

    let emailSuggestions = {};
    try {
      isLoading.value = true;
      if (!companyId) {
        emailSuggestions = {
          data: {
            results: []
          }
        };
        await Promise.resolve();
      } else {
        emailSuggestions = await getSubjects(companyId, {
          search: searchQuery,
          subject_type: ['user']
        });
      }
    } catch (e) {
      console.error('Get subjects failed ', e);
      emailSuggestions = {
        data: {
          results: []
        }
      };
    } finally {
      isLoading.value = false;
    }

    const orgMemberSuggestions: Array<Partial<EmailSuggestion>> =
      buildOrgMemberEmailList(
        sanitizeOrgMembers(emailSuggestions?.data?.results || [])
      );

    if (
      filteredByQueryRecentlyUsedEmails.length > 0 &&
      orgMemberSuggestions.length > 0
    ) {
      filteredByQueryRecentlyUsedEmails.push({ type: 'divider' });
    }

    return {
      isLoading: isLoading.value,
      aggregatedList: [
        ...filteredByQueryRecentlyUsedEmails,
        ...orgMemberSuggestions
      ] as []
    };
  }

  async function saveRecentlyUsedEmails(signeeList: { email: string }[]) {
    const updatedRecentlyUsedEmails = ref<Array<EmailSuggestion>>([]);
    const filteredRecentlyUsedEmails = signeeList.filter((item) => item.email);

    filteredRecentlyUsedEmails.forEach((item) => {
      const emailEntry = recentlyUsedEmails.value.find(
        (copyItem) => copyItem.email!.toLowerCase() == item.email!.toLowerCase()
      );
      if (emailEntry) {
        //update count for reused emails
        emailEntry.count = emailEntry?.count || 0;
        ++emailEntry.count;
      } else {
        const { email } = item;
        updatedRecentlyUsedEmails.value.push({
          email,
          count: 0,
          isOrgMember: false
        });
      }
    });

    const combinedResults = [
      ...recentlyUsedEmails.value,
      ...updatedRecentlyUsedEmails.value
    ];

    //sort by count before truncating
    combinedResults.sort(compareByCount);

    //limit items to max size after sorting
    if (combinedResults.length > MAX_COUNT_RECENTLY_USED_EMAILS_TO_SAVE) {
      combinedResults.length = MAX_COUNT_RECENTLY_USED_EMAILS_TO_SAVE;
    }

    await preferenceStore.setPreference('recentlyUsedEmails', combinedResults);
  }

  async function deleteRecentlyUsedEmails(
    emailForDeletion: Partial<EmailSuggestion> | string
  ) {
    const email =
      typeof emailForDeletion === 'string'
        ? emailForDeletion
        : emailForDeletion.email;

    const filteredRecentlyUsedEmail = recentlyUsedEmails.value.filter(
      (item) => item.email !== email
    );

    try {
      await preferenceStore.setPreference(
        'recentlyUsedEmails',
        filteredRecentlyUsedEmail
      );
    } catch (e) {
      console.error('Deletion of recently used emails failed ', e);
    }
  }

  async function resetRecentlyUsedEmails() {
    try {
      await preferenceStore.setPreference('recentlyUsedEmails', []);
    } catch (e) {
      console.error('Reset of recently used emails failed ', e);
    }
  }

  return {
    deleteRecentlyUsedEmails,
    getEmailSuggestionList,
    resetRecentlyUsedEmails,
    saveRecentlyUsedEmails,
    isLoading
  };
}
