<template>
  <v-dialog v-model="dialog" width="600" persistent @keydown.esc="closeDialog">
    <v-card class="pa-6" rounded="lg">
      <!-- TITLE -->
      <v-card-title class="justify-center d-flex flex-column items-center py-0">
        <v-icon color="#FFAB00" size="44">
          fa-solid fa-diamond-exclamation
        </v-icon>
        <h5 class="card-title my-4">
          {{ i18n.t('titles.signature_cancelled') }}
        </h5>
      </v-card-title>
      <!-- /TITLE -->

      <!-- TEXT -->
      <v-card-text class="text-subtitle-1 instructions text-center">
        {{ i18n.t('texts.signature_cancelled') }}
      </v-card-text>
      <!-- /TEXT -->

      <!-- ACTIONS -->
      <DeepButton :action="closeDialog" :text="i18n.t('buttons.close')" />
      <!-- /ACTIONS -->
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { ref, onUnmounted } from 'vue';
import { useEmitter } from '@/composables/useEmitter.ts';
import { useI18n } from 'vue-i18n';

const i18n = useI18n();
const emitter = useEmitter();
const dialog = ref(false);

emitter.$on('show-signature-cancelled-dialog', showDialog);

onUnmounted(() => {
  emitter.$off('show-signature-cancelled-dialog', showDialog);
});

function showDialog() {
  dialog.value = true;
}

function closeDialog() {
  dialog.value = false;
}
</script>

<style scoped lang="scss">
.card-title {
  word-break: break-word;
  font-weight: 600;
  font-size: 24px !important;
  text-align: center;
  white-space: normal;
}
</style>
