<template>
  <div class="d-flex flex-row align-center justify-center">
    <div class="mr-2 app-store-available">
      {{ i18n.t('texts.available_on') }}
    </div>
    <v-icon size="18" color="#B1B1B7" class="mr-2">fab fa-apple</v-icon>
    <v-icon size="16" color="#B1B1B7">fab fa-google-play</v-icon>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const i18n = useI18n();
</script>

<style scoped lang="scss">
.app-store-available {
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #8e8e93;
}
</style>
