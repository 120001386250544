<template>
  <v-app-bar
    id="documentToolbar"
    theme="dark"
    elevation="4"
    extension-height="40"
    height="64"
    class="border-x-0 border-t-0"
  >
    <template #prepend>
      <BackToDeepboxButton
        v-if="
          isAuthenticated &&
          !isDocumentAuthAuthenticated &&
          !isDocumentPublicRoute
        "
      />
    </template>
    <template #title>
      <h6 class="text-center text-truncate">
        {{ truncate(documentName, 80, null) }}
      </h6>
    </template>
    <template #append>
      <div class="deep-caption text-right">
        <template v-if="isDocumentUrlNull"></template>
        <template v-else-if="rendering">
          <v-icon height="24"> fal fa-spinner-third fa-spin </v-icon>
        </template>
        <template v-else-if="document && globalStore.pageCount">
          {{
            i18n.t('paginations.pages_with_total', {
              n: globalStore.pageCount,
              currentPage: globalStore.currentPage
            })
          }}
        </template>
      </div>
    </template>
    <template v-if="showStatusSnackbar || $slots['status-toolbar']" #extension>
      <slot v-if="$slots['status-toolbar']" name="status-toolbar"> </slot>
      <div v-if="!$slots['status-toolbar']" class="w-full">
        <v-toolbar
          v-if="document.documentStatus === DocumentStatus.SIGNED"
          color="success"
          theme="light"
          flat
          height="40"
        >
          <div
            class="flex justify-around grow text-white w-full"
            data-test-id="fully-signed"
          >
            {{
              signStore.signInfo.signatureType === 'seal'
                ? i18n.t('snackbars.document_sealed')
                : i18n.t('snackbars.document_signed')
            }}
          </div>
        </v-toolbar>
        <v-toolbar
          v-if="document.documentStatus === DocumentStatus.WITHDRAWN"
          color="warning"
          theme="light"
          flat
          height="40"
        >
          <div class="flex justify-around grow text-white w-full">
            {{ i18n.t('snackbars.document_withdrawn') }}
          </div>
        </v-toolbar>
        <v-toolbar
          v-if="document.documentStatus === DocumentStatus.REJECTED"
          color="warning"
          theme="light"
          flat
          height="40"
        >
          <div class="flex justify-around grow text-white w-full px-4">
            {{
              isThereAnyApproverThatRejected
                ? i18n.t('snackbars.signature_rejected_by_approver')
                : i18n.t('snackbars.signature_rejected_by_signee')
            }}
          </div>
        </v-toolbar>
      </div>
    </template>
  </v-app-bar>
</template>

<script setup lang="ts">
import { useGlobalStore } from '@/stores/global/global.ts';
import AuthService from '@/services/auth-service';
import { DocumentStatus } from '@/types/enums/DocumentStatus.ts';
import { computed, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { truncate } from '@/composables/useUtils';
import { useDocumentAuth } from '@/composables/useDocumentAuth';
import { useSignStore } from '@/stores/sign/sign';
import type { Document } from '@/types/Document';
import { SigneeType } from '@/types/enums/SigneeType';
import { SignStatus } from '@/types/enums/SignStatus';

const globalStore = useGlobalStore();

const props = defineProps({
  document: {
    type: Object as PropType<Document>,
    default: undefined
  }
});
const i18n = useI18n();
const route = useRoute();
const documentAuth = useDocumentAuth();
const isDocumentAuthAuthenticated = computed(() => {
  return documentAuth.isAuthenticated.value;
});

const isAuthenticated = computed(() => AuthService.isAuthenticated);
const documentName = computed(() => props.document?.documentName || '');
const rendering = computed(() => globalStore.pageCount === null);
const signStore = useSignStore();

const isDocumentPublicRoute = computed(
  () => route.name === 'p-document-create' || route.name === 'p-document-view'
);
const showStatusSnackbar = computed(() => {
  if (!props.document) {
    return false;
  }
  return !!(
    props.document.documentStatus === DocumentStatus.SIGNED ||
    props.document.documentStatus === DocumentStatus.WITHDRAWN ||
    props.document.documentStatus === DocumentStatus.REJECTED
  );
});

const isDocumentUrlNull = computed(() => {
  return props.document?.documentUrl === null;
});

const isThereAnyApproverThatRejected = computed(() => {
  return props.document?.signeesOrdered
    .flat()
    .find(
      (signee) =>
        signee.signeeType === SigneeType.APPROVE &&
        signee.signStatus === SignStatus.REJECTED
    );
});
</script>

<style scoped lang="postcss">
:deep(.v-toolbar__extension) {
  padding: 0;
}

@screen xl {
  #documentToolbar {
    width: calc(100% - 484px) !important;
  }
}
</style>
