/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import initializeRouter from '@/router';
import vuetify from './vuetify';
import pinia from '../stores';
import i18n from '@/plugins/i18n';
import sentry from './sentry';
import deviceDetectorJs from './device-detector-js';
import { VueSignaturePad } from 'vue-signature-pad';
import 'flag-icons/css/flag-icons.min.css';
import 'v-phone-input/dist/v-phone-input.css';
import { createVPhoneInput } from 'v-phone-input';
import { VueQueryPlugin } from '@tanstack/vue-query';

const vPhoneInput = createVPhoneInput({
  countryIconMode: 'svg',
  displayFormat: 'international'
});

// Types
import type { App } from 'vue';

export function registerPlugins(app: App) {
  app.use(vPhoneInput);
  app.use(vuetify);
  app.use(pinia);
  app.use(i18n);
  app.use(sentry);
  app.use(VueQueryPlugin, {
    enableDevtoolsV6Plugin: true
  });
  app.use(deviceDetectorJs, { refreshOnResize: true });

  // components
  app.component('VueSignaturePad', VueSignaturePad);

  // Init router after all other plugins
  // https://github.com/dsb-norge/vue-keycloak-js/issues/94#issuecomment-1794403391
  // https://github.com/keycloak/keycloak/issues/14742#issuecomment-1663069438
  app.use(initializeRouter());
}
