import axios from 'axios';
import { setAuthorizationHeaders } from '@/api/utils';

const deepSignApi = axios.create({
  baseURL: import.meta.env.VITE_DEEPSIGN_API_BASE_URL
});

deepSignApi.interceptors.request.use(async (config) => {
  const headers = {};

  await setAuthorizationHeaders(headers);

  Object.assign(config.headers, headers);
  return {
    ...config
  };
});

export default deepSignApi;
