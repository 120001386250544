import deepSignApi from '@/api/deepsign/deepsignApi';
import type { AxiosRequestConfig } from 'axios';
import type { SubscriptionCheck } from '@/types/deepsign/SubscriptionCheck';

export const updateSubscriptionCheck = (
  data?: SubscriptionCheck,
  config?: AxiosRequestConfig
) => deepSignApi.put('subscription-check', data, config);

export const updateDocumentSubscriptionCheck = (
  documentId: string,
  data: SubscriptionCheck,
  config?: AxiosRequestConfig
) =>
  deepSignApi.put(`documents/${documentId}/subscription-check`, data, config);
