<template>
  <div
    class="action-bar w-full bg-white border border-black/15 h-14 rounded-md flex items-center justify-between p-2"
  >
    <div>
      <VBtn
        v-tooltip:bottom="i18n.t('text_unselect_all')"
        icon="fa-regular fa-xmark"
        size="32px"
        @click="emit('clear-selection')"
      >
      </VBtn>
      <span class="ml-1">
        {{ i18n.t('text.document', { n: total }) }}
      </span>
    </div>
    <div class="flex gap-2 sm:gap-4 items-center">
      <!-- Secondary action desktop -->
      <VBtn
        v-for="secondaryAction in secondaryActions"
        :key="secondaryAction.label"
        :class="[
          secondaryAction.type === 'warning' ? 'text-error' : 'text-primary'
        ]"
        :prepend-icon="secondaryAction.icon"
        class="bg-transparent px-0 lg:px-2 hidden sm:flex"
        @click="secondaryAction.action"
      >
        {{ secondaryAction.label }}
      </VBtn>

      <!-- Secondary action mobile -->
      <VBtn
        v-for="secondaryAction in secondaryActions"
        :key="secondaryAction.label"
        :class="[
          secondaryAction.type === 'warning' ? 'text-error' : 'text-primary'
        ]"
        :icon="secondaryAction.icon"
        size="32px"
        class="bg-transparent flex sm:hidden"
        @click="secondaryAction.action"
      >
      </VBtn>

      <!-- Primary action desktop and until 399px mobile -->
      <VBtn
        class="desktop-main-action"
        :color="mainAction.type === 'warning' ? 'error' : 'primary'"
        :prepend-icon="mainAction.icon"
        data-test-id="batch-sign"
        @click.prevent="() => mainAction.action()"
      >
        {{ mainAction.label }}
      </VBtn>

      <!-- Primary action mobile and up to 398px mobile -->
      <VBtn
        v-tooltip:bottom="mainAction.label"
        class="mobile-main-action rounded-lg"
        size="42px"
        :color="mainAction.type === 'warning' ? 'error' : 'primary'"
        :icon="mainAction.icon"
        :aria-label="mainAction.label"
        @click.prevent="() => mainAction.action()"
      >
      </VBtn>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useDocumentActions } from '@/composables/useDocumentActions';
import { useRouteQuery } from '@vueuse/router';
import type {
  FilterDocumentStatuses,
  FilterSignStatuses
} from '@/types/OverviewFilter';
import { computed, inject } from 'vue';

const i18n = useI18n();

const filterSignStatus = useRouteQuery<FilterSignStatuses>('filterSignStatus');
const filterDocumentStatus = useRouteQuery<FilterDocumentStatuses>(
  'filterDocumentStatus'
);

const selectedDocuments = inject('selectedDocuments');

const emit = defineEmits(['clear-selection']);

defineProps({
  total: {
    type: Number,
    required: true
  }
});

function getActions() {
  const { actions } = useDocumentActions(
    Array.isArray(filterDocumentStatus.value)
      ? filterDocumentStatus.value[0]
      : filterDocumentStatus.value,
    Array.isArray(filterSignStatus.value)
      ? filterSignStatus.value[0]
      : filterSignStatus.value,
    selectedDocuments
  );
  return actions.value;
}

const mainAction = computed(() => {
  return getActions()[0];
});
const secondaryActions = computed(() => {
  return getActions().slice(1);
});
</script>

<style lang="scss">
.action-bar {
  box-shadow: 0px 10px 20px 0px #0000001f;
}
@media (min-width: 399px) {
  .mobile-main-action {
    display: none !important;
  }
}

@media (min-width: 399px) {
  .desktop-main-action {
    display: flex !important;
  }
}

@media (max-width: 398px) {
  .desktop-main-action {
    display: none !important;
  }
}
</style>
