<template>
  <v-dialog v-model="dialog" persistent width="600" @keydown.esc="closeDialog">
    <v-card class="pa-6">
      <div class="d-flex flex-column align-center" style="width: 100%">
        <div
          v-if="doesKeyExists && isIdentityRelated"
          class="flex justify-center align-center logo"
        >
          <DeepIDLogo v-if="authorityService === AuthorityService.DEEP_ID" />
          <img
            v-if="authorityService === AuthorityService.MOBILE_ID"
            src="/svg/authority-services/ras-logo.svg"
            alt=""
          />
        </div>
        <v-card-title v-else class="justify-center">
          <h5
            class="md:text-base text-sm flex items-center justify-center mb-4 whitespace-normal text-center"
          >
            {{ i18n.t('titles.sign_error_dialog') }}
          </h5>
        </v-card-title>

        <v-card :flat="true" color="#F5F5F5" rounded="lg" class="error-card">
          <v-alert color="error" class="error-alert" rounded variant="outlined">
            <div class="d-flex flex-row align-center">
              <v-icon color="error" class="mr-2" size="14">
                fal fa-circle-exclamation
              </v-icon>

              <div class="alert-text">
                {{ i18n.t('texts.signing_not_possible') }}
                <a
                  v-if="
                    authorityService === AuthorityService.MOBILE_ID &&
                    errorKey === 'error.sign.user.serialNumber.mismatch'
                  "
                  :href="i18n.t('links.support_page')"
                >
                  {{ i18n.t('texts.mobile_id.serialNumber.mismatch') }}
                </a>
                <a
                  v-else-if="
                    !isConcurrentModification &&
                    doesKeyExists &&
                    isIdentityRelated
                  "
                  :href="learnMoreLink"
                  target="_blank"
                  class="text-primary"
                >
                  {{ i18n.t('texts.learn_more') }}</a
                >
              </div>
            </div>
          </v-alert>
          <i18n-t
            v-if="documentError"
            class="error-description"
            :keypath="`errors.api${authorityTranslationKey}.${documentError}`"
            tag="p"
          >
            <template #email>
              <a
                v-if="authorityService === 'did'"
                class="text-primary"
                href="mailto:support@deepid.swiss"
                >support@deepid.swiss</a
              >
              <a
                v-else
                class="text-primary"
                href="mailto:support@deepcloud.swiss"
                >support@deepcloud.swiss</a
              >
            </template>
          </i18n-t>
          <i18n-t
            v-else
            class="error-description"
            :keypath="getErrorPath()"
            tag="p"
          >
            <template #email>
              <a class="text-primary" href="mailto:support@deepcloud.swiss"
                >support@deepcloud.swiss</a
              >
            </template>
          </i18n-t>
          <div v-if="!doesKeyExists" class="error-description">
            {{ i18n.t('texts.sign_error_dialog_reference') }}:
            <p v-if="errorKey">
              {{ errorKey }}
            </p>
            <p v-if="documentError">
              {{ documentError }}
            </p>
          </div>
        </v-card>
      </div>
      <DeepButton
        v-if="
          authorityService === AuthorityService.MOBILE_ID &&
          errorKey &&
          errorKey.includes('mismatch')
        "
        :action="handleMismatch"
      >
        {{ i18n.t('texts.mobile_id.serialNumber.mismatch') }}
      </DeepButton>
      <DeepButton
        variant="outlined"
        :action="closeDialog"
        class="mt-5"
        :text="buttonText"
      />
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { constants } from '@/constants/constants';
import { AuthorityService } from '@/types/enums/AuthorityService.ts';
import { computed, onUnmounted, ref } from 'vue';
import { useEmitter } from '@/composables/useEmitter.ts';
import { useI18n } from 'vue-i18n';

const emitter = useEmitter();
const i18n = useI18n();
const dialog = ref(false);
const errorKey = ref();
const documentError = ref(null);
const authorityService = ref(null);

const UNKNOWN_ERROR = 'unknown_error';

emitter.$on('show-sign-error-dialog', showDialog);

onUnmounted(() => {
  emitter.$off('show-sign-error-dialog', showDialog);
});

const authorityTranslationKey = computed(() => {
  switch (authorityService.value) {
    case AuthorityService.DEEP_ID:
      return '.deep_id';
    case AuthorityService.MOBILE_ID:
      return '.mobile_id';
    default:
      return '';
  }
});

const learnMoreLink = computed(() => {
  let linkKey = '#';
  if (authorityService.value === AuthorityService.DEEP_ID) {
    linkKey = 'links.qes_deepid_failed';
  }
  if (authorityService.value === AuthorityService.MOBILE_ID) {
    linkKey = 'links.qes_mobileid_failed';
  }
  return i18n.t(linkKey);
});

const isConcurrentModification = computed(() => {
  return errorKey.value === constants.SIGN_ERROR_CONCURRENT_MODIFICATION;
});

const doesKeyExists = computed(() => {
  return (
    i18n.te(`errors.api${authorityTranslationKey.value}.${errorKey.value}`) ||
    (i18n.te(
      `errors.api${authorityTranslationKey.value}.${documentError.value}`
    ) &&
      documentError.value !== UNKNOWN_ERROR) ||
    i18n.te(`errors.api.${errorKey.value}`)
  );
});

const isIdentityRelated = computed(() => {
  return (
    i18n.te(`errors.api${authorityTranslationKey.value}.${errorKey.value}`) ||
    i18n.te(`errors.api${authorityTranslationKey.value}.${documentError.value}`)
  );
});

const buttonText = computed(() => {
  return i18n.t(
    isConcurrentModification.value
      ? 'buttons.close_and_refresh'
      : 'buttons.close'
  );
});

function handleMismatch() {
  const url =
    i18n.locale.value === 'de'
      ? 'https://support.deepbox.swiss/videos/deep-technologien/deepsign/signieren/qes-mit-mobile-id-nicht-moeglich/'
      : `https://support.deepbox.swiss/${i18n.locale.value}/videos/deep-technologies/deepsign/signieren/qes-mit-mobile-id-nicht-moeglich/`;
  window.open(url, '_blank');
}

function getErrorPath() {
  if (!doesKeyExists.value) {
    return 'errors.api.error.unknown_error';
  }
  if (i18n.te(`errors.api.${errorKey.value}`)) {
    return `errors.api.${errorKey.value}`;
  }

  if (i18n.te(`errors.api${authorityTranslationKey.value}.${errorKey.value}`)) {
    return `errors.api${authorityTranslationKey.value}.${errorKey.value}`;
  }

  if (
    i18n.te(`errors.api${authorityTranslationKey.value}.${documentError.value}`)
  ) {
    return `errors.api${authorityTranslationKey.value}.${documentError.value}`;
  }
  return 'errors.api.error.unknown_error';
}

function showDialog(event) {
  errorKey.value = event.errorKey;
  authorityService.value = event.authorityService;
  documentError.value = getDocumentError(event.documentErrors);
  if (!documentError.value && !errorKey.value) {
    documentError.value = UNKNOWN_ERROR;
  }
  dialog.value = true;
}

function getDocumentError(errors) {
  let orderedError = null;
  let orderNumber = null;
  if (errors?.documentIssues) {
    for (const issue of errors.documentIssues) {
      if (
        orderNumber > constants.SIGN_ERRORS_KEY_ORDER.indexOf(issue) ||
        orderNumber === null
      ) {
        orderNumber = constants.SIGN_ERRORS_KEY_ORDER.indexOf(issue);
        orderedError = issue;
      }
    }
    return orderedError;
  }
  return null;
}

function closeDialog() {
  if (isConcurrentModification.value) {
    emitter.$emit('update-sign-status');
  }
  dialog.value = false;
}
</script>

<style lang="scss" scoped>
.alert-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #d50000;

  :deep(a) {
    text-decoration: none !important;
  }
}

.error-description {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #090909;
  padding-top: 1rem;
}

.error-card {
  width: 100%;
  padding: 32px 24px !important;
}

.error-alert {
  background-color: white !important;
  padding: 12px;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo img {
  width: 30%;
}
</style>
