import deepSignApi from '@/api/deepsign/deepsignApi';
import publicAxios from '@/plugins/publicAxios';
import type { AxiosRequestConfig } from 'axios';
import type { ForwardSignee } from '@/types/deepsign/ForwardSignee';

export const forwardSignee = (
  signKey: string,
  payload: ForwardSignee,
  config?: AxiosRequestConfig
) => deepSignApi.post(`sign/${signKey}/forward`, payload, config);

export const forwardSigneePublic = (
  signKey: string,
  payload: ForwardSignee,
  config?: AxiosRequestConfig
) => publicAxios.post(`sign/${signKey}/forward`, payload, config);
