<template>
  <v-dialog v-model="showDialog" persistent width="600">
    <v-card class="p-6" rounded="lg">
      <!-- TITLE -->
      <v-card-title class="w-full mx-auto text-center">
        <span class="text-2xl font-bold">
          {{ i18n.t('texts.want_to_continue') }}
        </span>
      </v-card-title>
      <!-- /TITLE -->

      <!-- TEXT -->
      <v-card-text class="!p-0 text-black text-center my-6">
        {{ i18n.t('actions.delete_n_documents', _documents.length) }}
      </v-card-text>
      <!-- /TEXT -->

      <!-- ACTIONS -->
      <DeepButton
        :action="onDeleteDocuments"
        class="mb-3"
        color="error"
        :loading="deleteDocumentPending"
        :text="i18n.t('buttons.delete')"
        data-test-id="delete-document"
      />

      <DeepButton
        :action="closeDialog"
        variant="outlined"
        :text="i18n.t('buttons.cancel')"
        data-test-id="close-dialog-cancel"
      />
      <!-- ACTIONS -->
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { useMessageStore } from '@/stores/message/message';
import { useDocumentStore } from '@/stores/document/document';

import { ref, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useEmitter } from '@/composables/useEmitter.ts';
import { useRoute, useRouter } from 'vue-router';

const emit = defineEmits(['done']);

const messageStore = useMessageStore();
const documentStore = useDocumentStore();
const i18n = useI18n();
const emitter = useEmitter();
const route = useRoute();
const router = useRouter();

const showDialog = ref(false);
const deleteDocumentPending = ref(false);

emitter.$on('open-delete-documents-dialog', openDialog);

onUnmounted(() => {
  emitter.$off('open-delete-documents-dialog', openDialog);
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const _documents = ref<any[]>([]);

function openDialog({ documents }) {
  _documents.value = [...documents];
  showDialog.value = true;
}

function closeDialog() {
  showDialog.value = false;
  _documents.value = [];
}

async function onDeleteDocuments() {
  let isError = false;
  deleteDocumentPending.value = true;
  const deletePromises = _documents.value.map((document) =>
    documentStore.deleteDocument(document.documentId).catch(() => {
      messageStore.showMessage({
        key: 'errors.api.delete_document',
        color: 'error',
        icon: 'fa fa-circle-exclamation'
      });
    })
  );

  await Promise.all(deletePromises)
    .then(() => {
      if (isError) {
        messageStore.showMessage({
          key: 'errors.api.delete_document',
          color: 'error',
          icon: 'fa fa-circle-exclamation'
        });
      } else {
        messageStore.showMessage({
          text: i18n.t('snackbars.document_deleted', {
            number: deletePromises.length
          }),
          color: 'success',
          icon: 'fa fa-circle-check'
        });
      }
    })
    .catch(() => {
      isError = true;
    })
    .finally(() => {
      if (isError) {
        messageStore.showMessage({
          key: 'errors.api.delete_document',
          color: 'error',
          icon: 'fa fa-circle-exclamation'
        });
      }
      closeDialog();
      emit('done');
      if (route.name !== 'dashboard') {
        router.push({ name: 'dashboard' });
      }
      deleteDocumentPending.value = false;
    });
}
</script>

<style scoped lang="scss">
.card-title {
  word-break: break-word;
  font-weight: 600;
  font-size: 24px !important;
  text-align: center;
  margin: 0 0 24px;
  white-space: normal;
}
</style>
