<template>
  <div class="px-4 w-full">
    <v-expansion-panels elevation="0" variant="accordion">
      <v-expansion-panel>
        <v-expansion-panel-title data-test-id="more-information">
          {{ i18n.t('texts.more_infomation') }}
        </v-expansion-panel-title>

        <v-expansion-panel-text>
          <VList>
            <VListItem v-for="link in links" :key="link.id">
              <VListItemSubtitle>
                <a :href="getUrlForCurrentLanguage(link.url)" target="_blank">
                  {{ link.text }}
                </a>
              </VListItemSubtitle>
            </VListItem>
            <VListItem>
              <VListItemSubtitle> © 2024 DeepCloud AG </VListItemSubtitle>
            </VListItem>
            <VListItem>
              <div class="flex items-center justify-start mt-0">
                <VIcon :size="16">fa-regular fa-shield-check</VIcon>
                <div class="h-4 border-l border-gray-400 mx-4"></div>
                <img
                  src="/svg/swiss_made.svg?url"
                  alt="swiss_made"
                  class="h-4"
                />
              </div>
            </VListItem>
          </VList>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const i18n = useI18n();

const links = computed(() => [
  {
    id: 'privacy_policy',
    text: i18n.t('footer.link.privacy_policy'),
    url: {
      de: 'https://www.deepcloud.swiss/de/datenschutz/',
      en: 'https://www.deepcloud.swiss/en/privacy-policy/',
      fr: 'https://www.deepcloud.swiss/fr/politique-des-donnees/',
      it: 'https://www.deepcloud.swiss/it/protezione-dei-dati/'
    }
  },
  {
    id: 'terms_of_service',
    text: i18n.t('footer.link.terms_of_service'),
    url: {
      de: 'https://www.deepcloud.swiss/legal/AB-DeepCloud-AG_DE.pdf',
      en: 'https://www.deepcloud.swiss/legal/AB-DeepCloud-AG_EN.pdf',
      fr: 'https://www.deepcloud.swiss/legal/AB-DeepCloud-AG_FR.pdf',
      it: 'https://www.deepcloud.swiss/legal/AB-DeepCloud-AG_IT.pdf'
    }
  },
  {
    id: 'impressum',
    text: i18n.t('footer.link.impressum'),
    url: {
      de: 'https://www.deepcloud.swiss/de/impressum/',
      en: 'https://www.deepcloud.swiss/en/impressum/',
      fr: 'https://www.deepcloud.swiss/fr/mentions-legales/',
      it: 'https://www.deepcloud.swiss/it/nota-legale/'
    }
  },
  {
    id: 'help',
    text: i18n.t('footer.link.help'),
    url: {
      de: 'https://support.deepcloud.swiss/topic/deepsign/',
      en: 'https://support.deepcloud.swiss/en/topic/deepsign/',
      fr: 'https://support.deepcloud.swiss/en/topic/deepsign/',
      it: 'https://support.deepcloud.swiss/en/topic/deepsign/'
    }
  }
]);

function getUrlForCurrentLanguage(urls: Record): string {
  if (i18n.locale.value in urls) {
    return urls[i18n.locale.value as keyof typeof urls];
  }
  return urls['en'];
}
</script>

<style lang="postcss" scoped>
button.v-expansion-panel-title {
  @apply !px-2 hover:rounded-lg;
}
li {
  @apply py-1 text-xs text-zinc-500;
}

li a {
  @apply hover:!text-zinc-700;
}

li p {
  @apply pb-1;
}

.v-list-item--density-default.v-list-item--one-line {
  @apply !min-h-[40px] !pl-6;
}

.v-list-item--density-default.v-list-item--one-line :deep(a) {
  @apply hover:underline;
}

.v-expansion-panel-title.v-expansion-panel-title--active {
  @apply !min-h-[0px];
}

:deep(.v-expansion-panel-text__wrapper) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

:deep(.v-list--one-line) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

:deep(.fa-chevron-up) {
  font-weight: 300 !important;
}
</style>
