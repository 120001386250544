import { defineStore } from 'pinia';
import { ref } from 'vue';
import { showAvailableModes } from '@/api/deepsign/service';
import type { Jurisdiction } from '@/types/enums/Jurisdiction';
import type { SignatureMode } from '@/types/enums/SignatureMode';
import type { AuthorityService } from '@/types/enums/AuthorityService';

export const useServiceStore = defineStore('service', () => {
  const availableModes = ref<{
    [key in SignatureMode]: {
      [key in Jurisdiction]: AuthorityService[];
    };
  }>();
  const fetchAvailableModesPending = ref(false);

  async function fetchAvailableModes() {
    fetchAvailableModesPending.value = true;
    try {
      const response = await showAvailableModes();
      availableModes.value = response.data;
      fetchAvailableModesPending.value = false;
      return Promise.resolve(response);
    } catch (error) {
      fetchAvailableModesPending.value = false;
      return Promise.reject(error);
    }
  }
  return {
    availableModes,
    fetchAvailableModesPending,
    fetchAvailableModes
  };
});
