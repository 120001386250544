import Cookies from 'universal-cookie';
import type { Cookie, CookieSetOptions } from 'universal-cookie';

export function useGlobalCookies() {
  const defaultOptions: CookieSetOptions = {
    domain: import.meta.env.VITE_DEEPSIGN_COOKIE_DOMAIN,
    maxAge: 24 * 60 * 60 // 1 day in seconds
  };
  const cookies: Cookie = new Cookies(null, defaultOptions);

  function getKeyName(key: string, prefix = 'auth') {
    if (prefix === '') {
      return `${import.meta.env.VITE_ENVIRONMENT}.${key}`;
    } else {
      return `${prefix}.${import.meta.env.VITE_ENVIRONMENT}.${key}`;
    }
  }

  function setKey(
    key: string,
    value: string | number,
    prefix = 'auth',
    options?: CookieSetOptions
  ) {
    const cookieOptions = { ...defaultOptions, ...options };
    const keyName = getKeyName(key, prefix);
    cookies.set(keyName, value, cookieOptions);
  }

  function getKey(key: string, prefix = 'auth') {
    const keyName = getKeyName(key, prefix);
    return cookies.get(keyName);
  }

  function removeKey(key: string, prefix = 'auth') {
    const keyName = getKeyName(key, prefix);
    cookies.remove(keyName);
  }

  function clear() {
    const keys: Array<string> = Object.keys(cookies.getAll());
    keys.forEach((key) => cookies.remove(key));
  }

  function exists(key: string, prefix = 'auth') {
    const keyName = getKeyName(key, prefix);
    const keys: Array<string> = Object.keys(cookies.getAll());
    return keys.includes(keyName);
  }

  return {
    setKey,
    getKey,
    removeKey,
    exists,
    clear
  };
}
