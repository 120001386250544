<template>
  <!-- PLACE ON DOCUMENT SWITCH -->
  <CustomSwitchWrapper
    class="mb-2"
    :initial-value="createStore.placeSignaturesModel"
    :label="i18n.t('labels.place_signatures_on_document')"
    label-class="drawer-subhead"
    :disabled="createStore.isLoading || !globalStore.pdfPreviewRendered"
    data-test-id="place-signatures"
    @value-changed="updateSignaturesPreview"
  >
    <template #label-append>
      <span class="ml-1">
        <v-menu location="left top" max-width="300" open-on-hover open-on-click>
          <template #activator="{ props: ActivatorProps }">
            <v-icon v-bind="ActivatorProps" class="icon" color="grey" size="13">
              fa-regular fa-circle-info
            </v-icon>
          </template>
          <v-card class="pa-3" flat>
            <v-card-text class="pa-0">
              {{ i18n.t('texts.place_signatures_hint') }}
            </v-card-text>
          </v-card>
        </v-menu>
      </span>
    </template>
  </CustomSwitchWrapper>
  <!-- PLACE ON DOCUMENT SWITCH -->
</template>
<script setup lang="ts">
import { useCreateStore } from '@/stores/create.ts';
import { useI18n } from 'vue-i18n';
import { useGlobalStore } from '@/stores/global/global.ts';
import { useEmitter } from '@/composables/useEmitter.ts';

const createStore = useCreateStore();
const emitter = useEmitter();
const globalStore = useGlobalStore();
const i18n = useI18n();

function placeSignaturesAutomatically() {
  if (createStore.filteredSignees) {
    emitter.$emit(
      'place-signatures-automatically',
      createStore.filteredSignees
    );
  }
}

function removeAllSignatures() {
  createStore.filteredSignees.forEach((signee) => {
    if ('policy' in signee) {
      if (signee.policy.canModifyAutographPosition) {
        emitter.$emit('remove-signature', { id: signee.signeeId });
      }
    }
  });
}

function updateSignaturesPreview(value: boolean) {
  createStore.placeSignaturesModel = value;
  emitter.$emit('update-place-signatures-automatically', value);
  value ? placeSignaturesAutomatically() : removeAllSignatures();
}
</script>
