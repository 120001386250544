<template>
  <v-card
    v-if="!isDocumentAuthRoute && !isRequestor && isCreate"
    class="user-info"
    flat
    rounded
  >
    <div class="pb-1">
      {{ i18n.t('titles.requestor') }}
    </div>
    <div
      class="flex items-center flex-wrap text"
      data-test-id="requestor-org-name"
    >
      <div
        class="flex items-center"
        :data-test-id="`organization-${initiatorCompanyDisplayName}`"
      >
        {{ initiatorCompanyDisplayName }}
        <v-tooltip
          v-model="showVerificationTooltip"
          :close-delay="1000"
          content-class="tooltip deep-light tooltip-top"
          location="top"
          open-on-click
        >
          <template #activator="{ props: ActivatorProps }">
            <v-icon
              v-bind="ActivatorProps"
              class="mx-1"
              :color="verificationIconColor"
              :size="14"
            >
              {{ verificationIcon }}
            </v-icon>
          </template>
          <div class="max-w-[184px] font-size-[13px]">
            {{ verificationText }}
            <br />
            <a
              class="text-decoration-none text-primary pointer-events-auto"
              :href="String(i18n.t('texts.verification_badge_details.link'))"
              target="_blank"
              rel="noopener"
              >{{ i18n.t('texts.learn_more') }}</a
            >
          </div>
        </v-tooltip>
      </div>
      <span class="flex-shrink-0" data-test-id="requestor-display-name">
        ({{ requestorDisplayName }})
      </span>
      <div
        class="text-sm text-[#7d7d7d] mb-2"
        :data-test-id="`requestor-email-${requestorEmail}`"
      >
        {{ requestorEmail }}
      </div>
    </div>
  </v-card>
</template>
<script setup lang="ts">
import { useDocumentContext } from '@/composables/useDocumentContext.ts';
import { computed, ref } from 'vue';
import { VerificationState } from '@/types/enums/VerificationState.ts';
import { useI18n } from 'vue-i18n';
import AuthService from '@/services/auth-service.ts';
import SignatureService from '@/services/signatureService.ts';
import { DocumentStatus } from '@/types/enums/DocumentStatus.ts';

const { isDocumentAuthRoute, currentDocument } = useDocumentContext();
const i18n = useI18n();
const showVerificationTooltip = ref(false);

const initiatorCompanyDisplayName = computed(() => {
  return currentDocument.value?.initiatorCompanyDisplayName || '';
});

const requestorEmail = computed(() => {
  return currentDocument.value?.initiatorDisplayEmail || '';
});

const requestorDisplayName = computed(() => {
  return currentDocument.value?.initiatorDisplayName || '';
});

const verificationIcon = computed(() => {
  return currentDocument.value?.initiatorCompanyVerificationType ===
    VerificationState.STRONG
    ? 'fas fa-shield-check'
    : 'fas fa-shield-xmark';
});

const verificationIconColor = computed(() => {
  return currentDocument.value?.initiatorCompanyVerificationType ===
    VerificationState.STRONG
    ? 'primary'
    : '#F99C2C';
});

const verificationText = computed(() => {
  return currentDocument.value?.initiatorCompanyVerificationType ===
    VerificationState.STRONG
    ? i18n.t('texts.verification_badge_details.verified')
    : i18n.t('texts.verification_badge_details.unverified');
});

const isRequestor = computed(() => {
  if (currentDocument.value) {
    return SignatureService.compareString(
      currentDocument.value?.initiatorDisplayEmail,
      AuthService.user?.email
    );
  }
  return false;
});

const isCreate = computed(() => {
  return currentDocument.value?.documentStatus === DocumentStatus.DRAFT;
});
</script>
<style lang="scss" scoped>
.user-info {
  @apply shadow-md border-[#dadada] border p-4 mb-8;
}

.text {
  @apply text-base tracking-[0.005em] text-black;
  font-feature-settings: 'liga' off;
}
</style>
