<template>
  <!-- USER IS SIGNING SWITCH -->
  <CustomSwitchWrapper
    v-if="AuthService.isAuthenticated && !isDocumentAuthAuthenticated"
    class="mt-2 mb-4"
    :initial-value="userIsSigning"
    :label="i18n.t('titles.sign_yourself')"
    label-class="drawer-subhead"
    switch-id="requestor-switch"
    data-test-id="me-signing"
    :disabled="createStore.isLoading"
    @value-changed="onUserSigning"
  />
  <!-- USER IS SIGNING SWITCH -->
</template>
<script setup lang="ts">
import AuthService from '@/services/auth-service.ts';
import { useDocumentAuth } from '@/composables/useDocumentAuth.ts';
import { useEmitter } from '@/composables/useEmitter.ts';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useCreateStore } from '@/stores/create.ts';
import { SigneeType } from '@/types/enums/SigneeType.ts';
import type { Signee } from '@/types/Signee.ts';
import type { UpdateSigneePayload } from '@/types/UpdateSigneePayload.ts';
import SignatureService from '@/services/signatureService.ts';
import type { AddSigneePayload } from '@/types/AddSigneePayload.ts';
import type { AxiosResponse } from 'axios';

const createStore = useCreateStore();
const documentAuth = useDocumentAuth();
const emitter = useEmitter();
const i18n = useI18n();

const isDocumentAuthAuthenticated = computed(() => {
  return documentAuth.isAuthenticated.value;
});

const isSingleFieldWithoutEmailCase = computed(
  () =>
    createStore.hasSignees &&
    createStore.document?.signeesOrdered.length === 1 &&
    createStore.document?.signeesOrdered[0].length === 1 &&
    !createStore.document?.signeesOrdered[0][0].email
);

const userIsSigning = computed(
  () =>
    !!createStore.document?.signeesOrdered.find((group) =>
      group.find(
        (invitee: Signee) =>
          SignatureService.compareString(
            invitee?.email,
            AuthService.user?.email
          ) && invitee?.signeeType === SigneeType.SIGN
      )
    )
);

function placeSignaturesAutomatically() {
  if (createStore.filteredSignees) {
    emitter.$emit(
      'place-signatures-automatically',
      createStore.filteredSignees
    );
  }
}

async function updateExistingSingleField() {
  try {
    const invitee = createStore.document?.signeesOrdered[0][0];
    const payload: UpdateSigneePayload = {
      documentId: createStore.document!.documentId,
      signeeId: invitee!.signeeId,
      email: (AuthService.user?.email as string) || '',
      autographPosition: invitee?.autographPosition || null,
      signeeType: SigneeType.SIGN,
      signOrder: 0
    };
    const response = await createStore.updateInvitee(payload);
    createStore.updateInviteeInLocalData((response as AxiosResponse).data);
  } catch (e) {
    console.error('Update of single field with user email failed ', e);
  }
}

function moveUserFieldToFirstPossibleGroupPositionInLocalData(
  userField: Signee
) {
  const tempData = createStore.document!.signeesOrdered || [[]];
  const firstPossibleApprover = tempData
    .flat()
    .find((invitee) => invitee.signeeType === SigneeType.APPROVE);

  const insertionIndex = createStore.hasApprovers
    ? firstPossibleApprover!.signOrder + 1
    : 0;

  tempData.splice(insertionIndex, 0, [userField]);
  createStore.updateDocumentSignOrder(
    createStore.document!.documentId,
    tempData
  );

  createStore.document!.signeesOrdered = tempData;

  if (createStore.placeSignaturesModel) {
    placeSignaturesAutomatically();
  }
}

function moveUserFieldToFirstPossiblePositionInLocalData(userField: Signee) {
  createStore.document!.signeesOrdered[0].unshift(userField);
}

async function removeAllUserFields() {
  const tempData = createStore.document?.signeesOrdered || [[]];
  const userFields: Array<Array<Signee>> =
    tempData.map((group) =>
      group.filter(
        (invitee: Signee) =>
          invitee.email?.toLowerCase() ===
            AuthService.user?.email.toLowerCase() &&
          invitee.signeeType === SigneeType.SIGN
      )
    ) || [];

  for (const userField of userFields.flat()) {
    await createStore.removeInvitee(userField);
    if (createStore.placeSignaturesModel) {
      const userSignaturePlaceHolder = window.document.getElementById(
        `signature-preview-${userField.signeeId}`
      );
      if (userSignaturePlaceHolder) {
        userSignaturePlaceHolder.remove();
      }
    }
  }
}

async function unorderedUserIsSigning(userIsSigningValue: boolean) {
  const payload = {
    documentId: createStore.document!.documentId,
    email: AuthService.user?.email || '',
    signeeType: SigneeType.SIGN
  };

  if (userIsSigningValue) {
    if (isSingleFieldWithoutEmailCase.value) {
      // one single empty field
      await updateExistingSingleField();
    } else if (!createStore.hasSignees) {
      // no signee fields
      const response = await createStore.addInvitee(payload);
      createStore.addInviteeToLocalData(response.data);
    } else if (createStore.hasSignees) {
      //some fields
      const response = await createStore.addInvitee(payload);
      moveUserFieldToFirstPossiblePositionInLocalData(response.data);
    }
    if (createStore.placeSignaturesModel) {
      placeSignaturesAutomatically();
    }
  } else {
    await removeAllUserFields();
    if (!createStore.hasSignees) {
      const payloadWithoutEmail = {
        ...payload,
        email: null
      };
      const response = await createStore.addInvitee(payloadWithoutEmail);
      createStore.addInviteeToLocalData(response.data);
      if (createStore.placeSignaturesModel) {
        placeSignaturesAutomatically();
      }
    }
  }
}

async function orderedUserIsSigning(userIsSigningValue: boolean) {
  const payload: Partial<AddSigneePayload> = {
    documentId: createStore.document!.documentId,
    email: AuthService.user?.email || '',
    signeeType: SigneeType.SIGN,
    signOrder: createStore.nextGroupIndex
  };
  try {
    if (userIsSigningValue) {
      if (isSingleFieldWithoutEmailCase.value) {
        // update field in group
        await updateExistingSingleField();
      } else {
        //some fields
        const response = await createStore.addInvitee(payload);
        moveUserFieldToFirstPossibleGroupPositionInLocalData(response.data);
      }
    } else {
      // remove all user fields
      await removeAllUserFields();
      if (!createStore.hasSignees) {
        const payloadWithoutEmail = {
          ...payload,
          signOrder: 0,
          email: null
        };
        const response = await createStore.addInvitee(payloadWithoutEmail);
        createStore.addInviteeToLocalData(response.data);
        if (createStore.placeSignaturesModel) {
          placeSignaturesAutomatically();
        }
      }
    }
  } catch (e) {
    console.error('Ordered user sign action failed ', e);
  }
}

async function onUserSigning(userIsSigningValue: boolean) {
  try {
    if (createStore.ordered) {
      await orderedUserIsSigning(userIsSigningValue);
    } else {
      await unorderedUserIsSigning(userIsSigningValue);
    }
  } catch (e) {
    console.error('Failure during i am signing ', e);
  }
}
</script>
