<template>
  <v-dialog
    v-model="globalStore.documentDialog"
    width="600"
    @click:outside.prevent="globalStore.documentDialogState = null"
  >
    <v-card class="pa-6" rounded="lg">
      <v-card-title class="dialog-card-title justify-center whitespace-normal">
        {{ title }}
      </v-card-title>
      <v-card-text class="pa-0">
        <ForwardForm
          v-if="globalStore.documentDialogState === 'forward'"
          @close-dialog="closeDialog"
        />
        <InviteForm
          v-if="globalStore.documentDialogState === 'invite'"
          @close-dialog="closeDialog"
        />
        <RemoveSigneeCard
          v-if="globalStore.documentDialogState === 'remove-signee'"
          @close-dialog="closeDialog"
        />
        <RemoveApproverCard
          v-if="globalStore.documentDialogState === 'remove-approver'"
          @close-dialog="closeDialog"
        />
        <ResendInvitationCard
          v-if="globalStore.documentDialogState === 'resend-invitation'"
          @close-dialog="closeDialog"
        />
        <UpdateEmailForm
          v-if="globalStore.documentDialogState === 'edit'"
          @close-dialog="closeDialog"
        />
        <ObserverForm
          v-if="globalStore.documentDialogState === 'add-observer'"
          @close-dialog="closeDialog"
        />
        <RemoveObserverCard
          v-if="globalStore.documentDialogState === 'remove-observer'"
          @close-dialog="closeDialog"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { useGlobalStore } from '@/stores/global/global';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const i18n = useI18n();

const globalStore = useGlobalStore();

function closeDialog() {
  globalStore.documentDialog = false;
}

// Title
const title = computed(() => {
  switch (globalStore.documentDialogState) {
    case 'invite':
      return i18n.t('dialogs.document_dialog.titles.invite_signee');
    case 'forward':
      return i18n.t('dialogs.document_dialog.titles.forward_document');
    case 'remove-signee':
      return i18n.t('dialogs.document_dialog.titles.remove_signee');
    case 'remove-approver':
      return i18n.t('dialogs.document_dialog.titles.remove_approver');
    case 'resend-invitation':
      return i18n.t('dialogs.document_dialog.titles.resend_invitation');
    case 'edit':
      return i18n.t('dialogs.document_dialog.titles.edit_email');
    case 'add-observer':
      return i18n.t('dialogs.document_dialog.titles.add_observer');
    case 'remove-observer':
      return i18n.t('dialogs.document_dialog.titles.remove_observer');
    default:
      return '';
  }
});
</script>
