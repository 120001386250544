<template>
  <button
    class="flex items-center gap-4"
    data-test-id="back-to-deepbox"
    @click="goBack"
  >
    <i class="fas fa-chevron-left text-xl pl-4" />
    <div class="hidden md:flex md:items-center font-medium">
      <span v-if="fromDeepbox || isDashboard">
        {{ i18n.t('buttons.back_to_deepbox') }}
      </span>
      <span v-else-if="settingsBackRoute">
        {{ i18n.t('settings.buttons.back') }}
      </span>
      <span v-else>
        {{ i18n.t('buttons.overview') }}
      </span>
    </div>
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import AuthService from '@/services/auth-service';
import { useI18n } from 'vue-i18n';
import {
  getDeepBoxBackRoute,
  getSettingsBackRoute
} from '@/composables/useUtils';
import { useGlobalStore } from '@/stores/global/global';

const route = useRoute();
const router = useRouter();
const i18n = useI18n();
const globalStore = useGlobalStore();

const fromDeepbox = computed(() =>
  globalStore.history.some((route) => route.startsWith('/deepbox'))
);

const isDashboard = computed(() => {
  return route.name === 'dashboard';
});

const settingsBackRoute = computed(() => {
  if (!AuthService.user?.sub) {
    return null;
  }
  return getSettingsBackRoute(AuthService.user?.sub);
});

function goBack() {
  if (settingsBackRoute.value && !isDashboard.value) {
    return router.push(settingsBackRoute.value);
  } else if (fromDeepbox.value || isDashboard.value) {
    const backRoute = getDeepBoxBackRoute(AuthService.user?.sub);
    const url = import.meta.env.VITE_DEEPBOX_FRONTEND_BASE_URL + backRoute;
    window.open(url, '_self');
  } else {
    const filterSignStatus = sessionStorage.getItem('filterSignStatusSession');
    const filterDocumentStatus = sessionStorage.getItem(
      'documentSignStatusSession'
    );

    router.push({
      name: 'dashboard',
      query: {
        ...route.query,
        ...(filterDocumentStatus
          ? filterDocumentStatus.split(',').reduce(
              (acc, status) => {
                // eslint-disable-next-line no-param-reassign
                acc.filterDocumentStatus = acc.filterDocumentStatus
                  ? [...acc.filterDocumentStatus, status]
                  : [status];
                return acc;
              },
              {} as { filterDocumentStatus?: string[] }
            )
          : {}),
        ...(filterSignStatus
          ? filterSignStatus.split(',').reduce(
              (acc, status) => {
                // eslint-disable-next-line no-param-reassign
                acc.filterSignStatus = acc.filterSignStatus
                  ? [...acc.filterSignStatus, status]
                  : [status];
                return acc;
              },
              {} as { filterSignStatus?: string[] }
            )
          : {})
      }
    });
  }
}
</script>
