<template>
  <DeepHeader :env="currentEnv" :logo="logo">
    <template #prepend-start>
      <div class="border-solid border-r">
        <v-btn
          class="flex xl:hidden"
          color="primary"
          size="56"
          icon
          @click="handleCLick"
        >
          <v-icon size="large" color="black">
            {{ isOpen ? 'fa-thin fa-xmark' : 'fa-regular fa-bars' }}
          </v-icon>
        </v-btn>
      </div>
    </template>
    <template #append>
      <div id="app-switcher-container">
        <DeepAppSwitcher
          v-if="AuthService.isAuthenticated"
          id="app-switcher"
          :env="currentEnv"
        />
      </div>
      <UserAccountMenu />
    </template>
  </DeepHeader>
</template>
<script setup lang="ts">
import { DeepHeader, DeepAppSwitcher } from '@deepcloud/deep-ui-lib';
import { ref } from 'vue';
import { useEmitter } from '@/composables/useEmitter.ts';
import AuthService from '@/services/auth-service.ts';

const emitter = useEmitter();
const currentEnv = import.meta.env.VITE_ENVIRONMENT;

defineEmits(['toggle-sidebar']);
defineProps({
  isOpen: {
    type: Boolean,
    default: false
  }
});

const logo = ref({
  appId: 'deepsign',
  to: '/?filterDocumentStatus=in-progress&filterSignStatus=pending'
});

function handleCLick() {
  emitter.$emit('toggle-sidebar');
}
</script>
<style lang="scss" scoped>
:deep(.AppSwitcherTrigger) {
  margin-right: 8px;
}
#app-switcher-container :deep(.v-btn.v-btn--icon) {
  display: flex !important;
  justify-items: center !important;
  align-items: center !important;
  font-size: 16px !important;
}

#app-switcher-container :deep(.fas.fa-grid-round.v-icon) {
  font-size: 18px !important;
  padding-bottom: 1.25rem !important;
}

:deep(.v-avatar) {
  font-size: 14px;
  letter-spacing: initial;
}

:deep(.v-toolbar.pr-2) {
  padding-right: 4px !important;
}

:deep(header) {
  border: none;
}
</style>
