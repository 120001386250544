import type { AutographPosition } from '@/types/AutographPosition';

export function isMobileOrTablePortrait(
  isMobile: boolean,
  isTablet: boolean,
  mdAndDown: boolean
) {
  if (isMobile || mdAndDown) {
    return true;
  }
  return isTablet;
}

export function overlaps(rect1: AutographPosition, rect2: AutographPosition) {
  const isInHorizontalBounds =
    rect1.x < rect2.x + rect2.width && rect1.x + rect1.width > rect2.x;
  const isInVerticalBounds =
    rect1.y < rect2.y + rect2.height && rect1.y + rect1.height > rect2.y;
  return isInHorizontalBounds && isInVerticalBounds;
}
