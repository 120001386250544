<template>
  <v-dialog
    :model-value="modelValue"
    max-height="300"
    width="600"
    @keydown.esc="closeDialog"
    @click:outside="closeDialog"
  >
    <v-card class="dialog-container" rounded="lg">
      <div class="d-flex flex-row align-center justify-center card-title">
        <h2>{{ i18n.t('titles.comment') }}</h2>
      </div>
      <div class="signee-email-container text-truncate mx-4 mb-4">
        {{ signeeEmail }}
        <div>
          {{ signedTime }}
        </div>
      </div>

      <v-card-text
        class="d-flex flex-column align-center justify-center pa-0 mb-6"
      >
        <div class="text-content">
          <pre class="text-subtitle-1 text-pre-wrap">{{ comment }}</pre>
        </div>
      </v-card-text>
      <div>
        <DeepButton
          :action="closeDialog"
          max-width="405px"
          variant="outlined"
          :text="i18n.t('buttons.close')"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  }
});
</script>

<script setup>
import { computed } from 'vue';
import { formatDateTimeToLocale } from '@/composables/useUtils';

import { useI18n } from 'vue-i18n';

const props = defineProps({
  signee: {
    type: Object,
    required: true
  },
  comment: {
    type: String,
    required: true
  },
  modelValue: {
    type: Boolean
  }
});

const emit = defineEmits(['update:modelValue']);
const i18n = useI18n();

const signeeEmail = computed(() => props.signee?.email || '');

const signedTime = computed(() => {
  return (
    formatDateTimeToLocale(props.signee?.signedTime, i18n.locale, true) || ''
  );
});

function closeDialog() {
  emit('update:modelValue', !props.modelValue);
}
</script>

<style lang="scss" scoped>
@import '@/styles/core/colors';

.dialog-container {
  padding: 24px !important;
}

.card-title {
  word-break: break-word;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  padding: 0 !important;
  margin-bottom: 16px !important;
}

.text-content {
  border-radius: 12px;
  min-width: 100%;
  text-align: start;
  background: $grey-lighten-4;
  padding: 24px;
  color: black;
}

.button-container {
  margin: 0 24px;
  padding: 0 40px;
}

.signee-email-container {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: black;

  div {
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.004em;
    color: $grey;
  }
}
</style>
