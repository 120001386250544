<template>
  <div class="d-flex flex-row align-center" style="color: #b1b1b7">
    <!-- FILE NAME -->
    <v-col class="pa-0 flex-fill text-truncate file-name">
      {{ fileName }}
    </v-col>
    <!-- /FILE NAME -->

    <!-- ICONS -->
    <v-col class="pa-0 flex-fill text-right" style="max-width: 80px">
      <div
        v-if="file.status === UploadStatus.UNSUPPORTED"
        class="d-flex flex-column align-end"
      >
        <v-menu location="left top" max-width="300" open-on-hover open-on-click>
          <template #activator="{ props: ActivatorProps }">
            <v-icon v-bind="ActivatorProps" color="error" size="12px">
              far fa-file-slash
            </v-icon>
          </template>
          <v-card>
            <v-card-text class="signature-level-description">
              {{ i18n.t('texts.unsupported_file') }}
            </v-card-text>
          </v-card>
        </v-menu>
      </div>
      <div
        v-else-if="file.status === UploadStatus.EXCLUDED || file.isTooLarge"
        class="d-flex flex-column align-end"
      >
        <v-menu location="left top" max-width="300" open-on-hover open-on-click>
          <template #activator="{ props: ActivatorProps }">
            <v-icon v-bind="ActivatorProps" color="#F99C2C" size="12px">
              fal fa-triangle-exclamation
            </v-icon>
          </template>
          <v-card>
            <v-card-text>
              {{
                file.apiError
                  ? i18n.t(`errors.api.${file.apiError}`)
                  : i18n.t('texts.file_too_large_short')
              }}
            </v-card-text>
          </v-card>
        </v-menu>
      </div>
      <div v-if="isUploaded" class="file-size">
        {{ fileSize }}
      </div>
      <div
        v-if="file.status === UploadStatus.PENDING"
        class="d-flex flex-column align-end"
      >
        <v-icon size="12px"> fal fa-hourglass-start </v-icon>
      </div>
    </v-col>
    <!-- /ICONS -->

    <v-col class="pa-0">
      <v-btn
        v-if="
          file.status !== UploadStatus.UPLOADED &&
          file.status !== UploadStatus.ERROR &&
          file.status !== UploadStatus.PROGRESS
        "
        class="ml-2"
        icon
        size="small"
        variant="text"
        @click.stop="removeFile"
      >
        <v-icon color="philippineSilver"> fal fa-close </v-icon>
      </v-btn>
      <div v-else class="flex pr-1.5 w-[36px] h-[28px] justify-end">
        <v-icon
          v-if="file.status === UploadStatus.UPLOADED"
          color="success"
          size="17"
        >
          fal fa-circle-check
        </v-icon>
        <v-progress-circular
          v-else-if="file.status === UploadStatus.PROGRESS"
          color="primary"
          indeterminate
          class="mt-1.5"
          size="17"
          width="1"
        />
        <v-menu
          v-else
          location="left top"
          max-width="300"
          open-on-hover
          open-on-click
        >
          <template #activator="{ props: ActivatorProps }">
            <v-icon v-bind="ActivatorProps" color="#F99C2C" size="17">
              fal fa-triangle-exclamation
            </v-icon>
          </template>
          <v-card>
            <v-card-text>
              {{
                file.apiError
                  ? i18n.t(`errors.api.${file.apiError}`)
                  : i18n.t('texts.file_too_large_short')
              }}
            </v-card-text>
          </v-card>
        </v-menu>
      </div>
    </v-col>
  </div>
</template>

<script setup>
import { UploadStatus } from '@/types/enums/UploadStatus.ts';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { humanFileSize } from '@/composables/useUtils';

const props = defineProps({
  file: {
    type: Object,
    default: undefined
  },
  excluded: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['remove-uploaded-file']);

const i18n = useI18n();

const isUploaded = computed(() => {
  return props.file.status === UploadStatus.UPLOADED;
});
const fileSize = computed(() => {
  return humanFileSize(props.file.file.size);
});

const fileName = computed(() => {
  return props.file.file.name;
});

function removeFile() {
  emit('remove-uploaded-file', props.file);
}
</script>

<style scoped lang="scss">
@import '@/styles/core/colors';

.file-name {
  color: $light-theme-black;
}

.tooltip {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
}

.file-size {
  color: $light-theme-black;
}
</style>
