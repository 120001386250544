<template>
  <v-navigation-drawer
    ref="drawerRef"
    v-model="settingsDevStore.devShowSettings"
    class="dev-settings-drawer"
    :width="drawerWidth"
    location="right"
    :touchless="false"
    temporary
    disable-route-watcher
  >
    <v-toolbar flat height="52">
      <v-app-bar-nav-icon>
        <v-btn
          :icon="isDefaultWidth ? 'far fa-angles-left' : 'far fa-angles-right'"
          @click="onSetToggleWidth"
        >
        </v-btn>
      </v-app-bar-nav-icon>
      <v-spacer />
      <v-toolbar-title>
        <span
          :class="{ 'text-h5': !isDefaultWidth, 'text-h6': isDefaultWidth }"
        >
          DeepSign DEV Settings
        </span>
      </v-toolbar-title>
      <v-spacer />
      <v-btn
        icon="$close"
        data-test-id="dev-settings-close"
        @click="settingsDevStore.devShowSettings = false"
      >
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-switch
        v-model="settingsDevStore.devModeEnabled"
        label="DevMode Enabled"
        inset
        color="success"
        true-icon="far fa-check"
        false-icon="$close"
        density="compact"
        hide-details
      >
      </v-switch>
      <span class="text-caption"> Enable or disable the dev mode </span>

      <v-divider class="ma-4" />

      <v-form :disabled="!settingsDevStore.devModeEnabled">
        <div v-for="feature in featuresItems" :key="feature.id">
          <v-switch
            v-model="settingsDevStore.devModeFeatures"
            :value="feature.value"
            :label="feature.text"
            inset
            color="success"
            true-icon="far fa-check"
            false-icon="$close"
            density="compact"
            data-test-id="new-dashboard"
            hide-details
            multiple
          >
            <template #label>
              <div class="flex-grow-1">
                {{ feature.text }}
              </div>

              <v-chip v-if="feature.flag" class="ml-1" size="x-small">
                {{ feature.flag }}
              </v-chip>
            </template>
          </v-switch>
          <span v-if="feature.description" class="text-caption">
            {{ feature.description }}
          </span>
          <v-form
            v-if="feature.settings && feature.settings.length > 0"
            class="pl-8"
            :disabled="
              !settingsDevStore.devModeFeatures.includes(feature.value) ||
              !settingsDevStore.devModeEnabled
            "
          >
            <template
              v-for="featureSetting in feature.settings"
              :key="featureSetting.id"
            >
              <v-checkbox
                :label="featureSetting.text"
                :model-value="featureSetting.value"
                density="compact"
                color="primary"
                hide-details
                @update:model-value="featureSetting.click"
              >
              </v-checkbox>
              <span v-if="featureSetting.description" class="text-caption">
                {{ featureSetting.description }}
              </span>
            </template>
          </v-form>
        </div>
        <v-switch
          v-model="settingsDevStore.devDebugEnabled"
          label="Debug Enabled"
          inset
          color="success"
          true-icon="far fa-check"
          false-icon="$close"
          density="compact"
          hide-details
        >
        </v-switch>
        <span class="text-caption"> Enables debug infos on UI </span>
        <v-switch
          :model-value="appTheme.isDark()"
          :label="`Theme: ${appTheme.isDark() ? 'dark' : 'light'}`"
          class="me-auto"
          inset
          color="success"
          true-icon="far fa-moon"
          false-icon="far fa-sun-bright"
          density="compact"
          hide-details
          @update:model-value="appTheme.toggleTheme()"
        >
        </v-switch>
        <span class="text-caption"> Switch theme to dark/light </span>
        <template v-if="isLocalEnv()">
          <v-divider class="ma-4" />
          <div class="text-body-2">Theme</div>
          <v-list-subheader>Local only</v-list-subheader>
          <div class="d-flex">
            <div class="d-flex">
              <v-menu z-index="2501" :close-on-content-click="false">
                <template #activator="{ props: ActivatorProps }">
                  <v-btn color="primary" v-bind="ActivatorProps">
                    Primary Color
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text>
                    <v-color-picker
                      v-model="theme.current.value.colors.primary"
                    ></v-color-picker>
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
          </div>
        </template>
      </v-form>
    </v-card-text>

    <template #append>
      <v-card-text>
        <v-divider />
        <div
          v-for="releaseInfo in releaseInfos"
          :key="releaseInfo.title"
          class="text-medium-emphasis text-caption py-2 px-3 d-flex align-center"
        >
          <div class="d-inline-flex align-center">
            <v-icon start size="small">{{ releaseInfo.icon }}</v-icon>
            {{ releaseInfo.title }}
          </div>
          <div class="text-none px-2 ms-auto">
            <v-btn
              v-if="releaseInfo.value"
              class="text-none px-2 ms-auto"
              density="compact"
              variant="text"
              rounded
              color="primary"
              :loading="
                isCopyToClipboardLoading &&
                valueToCopyToClipboard === releaseInfo.value
              "
              @click="
                onCopyValueToClipboard(
                  releaseInfo.valueToCopy
                    ? releaseInfo.valueToCopy
                    : releaseInfo.value
                )
              "
            >
              {{ releaseInfo.value }}
            </v-btn>
            <div v-else class="text-error">Not available</div>
          </div>
        </div>
      </v-card-text>
    </template>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import { computed, inject, onMounted, onUnmounted, ref, watch } from 'vue';
import { useAppTheme } from '@/composables/useAppTheme.ts';
import { useTheme } from 'vuetify';
import { useClipboard } from '@vueuse/core';
import { formatDateTimeToLocaleTime } from '@/composables/useUtils';
import { DeviceKey } from '@/plugins/device-detector-js.ts';
import { useSettingsDevStore } from '@/stores/settings/settings-dev.ts';
import AuthService from '@/services/auth-service.ts';
import { isLocalEnv } from '@/utils/env.ts';
import { useI18n } from 'vue-i18n';
import { type DevModeFeatures } from '@/components/dev/const.ts';

interface DevFeatureItem {
  id: DevModeFeatures;
  text: string;
  value: DevModeFeatures;
  flag: string;
  description: string;
}

const appTheme = useAppTheme();
const theme = useTheme();
const { locale } = useI18n();

const settingsDevStore = useSettingsDevStore();
// always close drawer on load
settingsDevStore.devShowSettings = false;

const DRAWER_DEFAULT_WIDTH = 400;
const drawerWidth = ref(DRAWER_DEFAULT_WIDTH);

const isDefaultWidth = computed(
  () => drawerWidth.value === DRAWER_DEFAULT_WIDTH
);

function resetDrawerWidth() {
  drawerWidth.value = DRAWER_DEFAULT_WIDTH;
}

function onSetToggleWidth() {
  if (isDefaultWidth.value) {
    drawerWidth.value = window.innerWidth;
  } else {
    resetDrawerWidth();
  }
}

watch(
  () => settingsDevStore.devShowSettings,
  (newValue) => {
    if (!newValue) {
      resetDrawerWidth();
    }
  }
);

const featuresItems = computed((): DevFeatureItem[] => [
  {
    id: 'DASHBOARD_BULK',
    text: 'New Dashboard',
    value: 'DASHBOARD_BULK',
    flag: 'DEV',
    description:
      'New Dashboard UI with bulk actions like: Batch signing or delete'
  }
]);

const releaseInfos = computed(() => [
  {
    title: 'Environment',
    icon: 'far fa-code',
    value: import.meta.env.VITE_ENVIRONMENT
  },
  {
    title: 'Tag',
    icon: 'far fa-tag',
    value: import.meta.env.VITE_RELEASE_TAG
  },
  {
    title: 'Datetime',
    icon: 'far fa-clock',
    value: formatDateTimeToLocaleTime(
      import.meta.env.VITE_RELEASE_DATETIME,
      locale.value
    )
  },
  {
    title: 'Commit ID',
    icon: 'far fa-code-commit',
    value: import.meta.env.VITE_RELEASE_COMMIT_ID
  },
  {
    title: 'Auth Token',
    icon: 'far fa-coin',
    value: AuthService.fullAccessToken
      ? `${AuthService.fullAccessToken?.substring(0, 10)}...`
      : '',
    valueToCopy: AuthService.fullAccessToken
  }
]);

const { copy } = useClipboard();
const isCopyToClipboardLoading = ref(false);
const valueToCopyToClipboard = ref('');

async function onCopyValueToClipboard(value: string) {
  isCopyToClipboardLoading.value = true;
  valueToCopyToClipboard.value = value;
  await copy(value);
  valueToCopyToClipboard.value = '';
  isCopyToClipboardLoading.value = false;
}

const device = inject(DeviceKey);

function onKeyDown(e: KeyboardEvent) {
  let keyCommandMatch;
  if (device?.isMacOS) {
    keyCommandMatch = e.metaKey && e.key === 'd';
  } else {
    keyCommandMatch = e.ctrlKey && e.key === 'd';
  }
  if (keyCommandMatch) {
    settingsDevStore.devShowSettings = !settingsDevStore.devShowSettings;
    e.preventDefault();
  }
}

onMounted(() => window.addEventListener('keydown', onKeyDown));
onUnmounted(() => window.removeEventListener('keydown', onKeyDown));
</script>

<style scoped lang="scss">
.dev-settings-drawer {
  // show drawer over dialogs
  // 2500 the nr is the default z-index of VOverlay + 1
  z-index: 2501 !important;
}
</style>
