<template>
  <v-col
    ref="dropzone"
    class="dropzone"
    :class="{ dragover: hover }"
    @dragenter.prevent="dragenter"
    @dragleave.prevent="dragleave"
    @dragover.prevent="dragover"
    @drop.prevent="drop"
  >
    <input
      ref="fileInput"
      :accept="acceptedExtensions"
      class="fileInput"
      multiple
      type="file"
      @input="upload"
    />
    <v-row
      align="center"
      class="d-flex flex-column mb-4"
      dense
      justify="center"
      @click="fileInput.click()"
    >
      <v-img class="mt-3" src="/svg/cloud-upload.svg" width="60" />
      <p class="opacity-70 text-center">
        {{ i18n.t('hints.attachments_drop_zone') }}
      </p>
    </v-row>

    <v-col v-if="uploadedFiles.length > 0" class="pa-0">
      <v-list-item
        v-for="item in uploadedFiles"
        :key="item.name"
        class="file-details"
      >
        <v-list-item-title class="flex flex-wrap">
          <span class="flex-grow truncate">
            {{ item.name }}
          </span>
        </v-list-item-title>
        <template #append>
          <span class="mr-2">
            {{ humanFileSize(item.size) }}
          </span>
          <v-icon size="18" @click.stop="remove(item)">
            far fa-circle-xmark
          </v-icon>
        </template>
      </v-list-item>
    </v-col>

    <v-alert
      v-if="uploadedFiles.length > 0"
      class="mt-4 mb-0 mx-2"
      :color="tooLarge ? 'red' : 'green'"
      density="compact"
      variant="outlined"
      :type="tooLarge ? 'error' : 'success'"
    >
      <div class="font-bold flex justify-start">
        {{ i18n.t('hints.attachments_size') }}
        <v-spacer />
        {{ humanFileSize(totalSize) + ' / ' + maxFileSizeMB + ' MB' }}
      </div>
    </v-alert>

    <div
      class="text-center files-hint"
      :class="uploadedFiles.length > 0 ? 'pt-3 mx-4' : ''"
    >
      {{ i18n.t('hints.attachments_size_hint') }}
    </div>
  </v-col>
</template>

<script setup>
import { ref, computed, onUnmounted, watch } from 'vue';
import { useEmitter } from '@/composables/useEmitter.ts';
import { useI18n } from 'vue-i18n';
import {
  humanFileSize,
  getAllFileEntriesFromButtonUpload,
  getAllFileEntries
} from '@/composables/useUtils';

const acceptedExtensions = ref('video/*,image/*,.pdf');
const hover = ref(false);
const uploadedFiles = ref([]);
const maxFileSizeMB = ref(5);
const emitter = useEmitter();
const i18n = useI18n();
const fileInput = ref();

const totalSize = computed(() => {
  let size = 0;
  uploadedFiles.value.forEach((file) => {
    size += file.size;
  });
  return size;
});

const tooLarge = computed(() => {
  return (totalSize.value / 1000000).toFixed(1) > maxFileSizeMB.value;
});

watch(
  uploadedFiles.value,
  (newValue) => {
    emitter.$emit('update-attachments', {
      tooLarge: tooLarge.value,
      signeeAttachments: newValue
    });
  },
  {
    deep: true
  }
);

emitter.$on('reset-attachments', resetAttachments);

onUnmounted(() => {
  emitter.$off('reset-attachments', resetAttachments);
});

function dragleave() {
  hover.value = false;
}

function dragenter() {
  hover.value = true;
}

function dragover() {
  hover.value = true;
}

async function drop(event) {
  hover.value = false;
  event.preventDefault();
  hover.value = false;
  const entries = await getAllFileEntries(event);
  handleFiles(entries.files);
}

function remove(file) {
  const arr = uploadedFiles.value;
  arr.splice(arr.indexOf(file), 1);
}

function upload(event) {
  if (!event?.target?.files[0]) {
    return;
  }
  const entries = getAllFileEntriesFromButtonUpload(event);
  handleFiles(entries.files);
}

function handleFiles(files) {
  files.forEach((selectedFile) => {
    if (isValid(selectedFile.file)) {
      uploadedFiles.value.push(selectedFile.file);
    }
  });
}

function isValid(file) {
  const supportedFileExtensions = [
    'pdf',
    'avi',
    'mov',
    'wmv',
    'gif',
    'png',
    'jpg',
    'jpeg'
  ];
  if (
    file.type.includes('image') ||
    file.type.includes('video') ||
    file.type.includes('pdf')
  ) {
    return true;
  } else if (file.type === '') {
    if (
      supportedFileExtensions.includes(file.name.split('.').pop().toLowerCase())
    ) {
      return true;
    } else {
      console.warn(file.name + " file's format is not supported!");
    }
  } else {
    console.warn(file.name + " file's format is not supported!");
    return false;
  }
}

function resetAttachments() {
  uploadedFiles.value = [];
}
</script>

<style scoped lang="scss">
input.fileInput {
  display: none;
}

.dropzone {
  border: 1px dashed #999999;
  border-radius: 4px;
  transition: background-color 0.2s;
  margin-bottom: 40px;
  &:hover {
    cursor: pointer;
  }
}

.dragover {
  background-color: rgba(0, 82, 255, 0.1);
}

.file-details {
  height: 40px;
  padding: 0;
  margin-left: 16px;
  margin-right: 16px;
  border-bottom: 1px solid #adb5bd;
}

.file-details:last-of-type {
  height: 40px;
  border-bottom: none;
}

.files-hint {
  font-size: 10px;
  font-weight: normal;
}

.alert-content {
  font-weight: bold;
  font-size: 14px;
}

.file-title {
  font-weight: 400;
  font-size: 14px;
}
</style>
