<template>
  <div class="container pa-0 min-w-full">
    <!-- HEADER -->
    <div class="d-flex flex-row align-center header-container">
      <div class="drawer-section-title text-uppercase mr-2">
        {{ title }}
      </div>
      <v-menu location="left top" max-width="300" open-on-hover open-on-click>
        <template #activator="{ props: ActivatorProps }">
          <v-icon v-bind="ActivatorProps" class="icon" color="grey" size="13">
            fa-regular fa-circle-info
          </v-icon>
        </template>
        <v-card class="pa-3" flat>
          <v-card-text
            v-for="(item, index) in items"
            :key="item.key"
            class="signature-level-description"
          >
            <strong>{{ item.title }}</strong> · {{ item.description }}
            <template v-if="items.length - 1 !== index">
              <v-divider class="signature-level-description-divider" />
            </template>
          </v-card-text>
          <a class="more-information" :href="learnMoreLink" target="_blank">{{
            i18n.t('texts.learn_more')
          }}</a>
        </v-card>
      </v-menu>
    </div>
    <!-- /HEADER -->

    <!-- CARDS -->
    <div class="grid gap-3 grid-flow-col grid-cols-3">
      <template v-for="(level, index) in items" :key="index">
        <v-card
          class="flex h-[42px] rounded-md border-gray-300"
          :class="{
            'border-primary': level.key === selectedLevel
          }"
          flat
          variant="outlined"
          :data-test-id="`signature-${level.dataTestId}`"
          :data-test-is-selected="level.key === selectedLevel"
          @click="selectLevel(level.key)"
        >
          <v-card-text
            class="flex items-center justify-center text-xs md:text-sm"
            :class="{
              'bg-primary border-primary text-white':
                level.key === selectedLevel,
              mobile: $vuetify.display.smAndDown
            }"
          >
            {{ level.title }}
          </v-card-text>
        </v-card>
      </template>
    </div>
    <!-- /CARDS -->

    <!-- JURISDICTION -->
    <div
      v-if="selectedLevel !== SignatureMode.TIMESTAMP"
      class="px-1 py-3 mt-6 bg-[#f7f8fa] rounded-lg"
    >
      <v-radio-group
        v-model="validityArea"
        color="primary"
        @update:model-value="selectJurisdiction"
      >
        <v-radio v-for="area in areas" :key="area.key" :value="area.key">
          <template #label>
            <div
              class="flex items-center"
              :data-test-id="`${area.key}`"
              :data-test-is-selected="area.key === validityArea"
            >
              <v-img
                cover
                height="16"
                :src="`/svg/flags/${area.flag}.svg`"
                width="24"
              />
              <span class="font-normal text-gray-500 ml-2">
                {{ area.label }}
              </span>
            </div>
          </template>
        </v-radio>
      </v-radio-group>
    </div>
    <!-- JURISDICTION -->
  </div>
</template>
<script setup lang="ts">
import AuthService from '@/services/auth-service';
import { SignatureMode } from '@/types/enums/SignatureMode';
import { Jurisdiction } from '@/types/enums/Jurisdiction';
import { AuthorityService } from '@/types/enums/AuthorityService';
import { BetaFeature } from '@/types/enums/BetaFeature';
import { ref, computed, watchEffect, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useEmitter } from '@/composables/useEmitter';
import { useDocumentContext } from '@/composables/useDocumentContext';

const { currentDocument } = useDocumentContext();

const panel = ref();
const validityArea = ref(Jurisdiction.ZERTES);
const selectedLevel = ref(SignatureMode.TIMESTAMP);
const i18n = useI18n();
const emitter = useEmitter();
const title = computed(() => i18n.t('titles.level_of_security'));
const items = computed(() => {
  return [
    {
      title: i18n.t('titles.basic'),
      description: i18n.t('texts.basic'),
      key: SignatureMode.TIMESTAMP,
      dataTestId: 'simple'
    },
    {
      title: i18n.t('titles.advanced'),
      description: i18n.t('texts.advanced'),
      key: SignatureMode.ADVANCED,
      dataTestId: 'advanced'
    },
    {
      title: i18n.t('titles.qualified'),
      description: i18n.t('texts.qualified'),
      key: SignatureMode.QUALIFIED,
      dataTestId: 'qualified'
    }
  ];
});

const areas = computed(() => {
  if (selectedLevel.value === SignatureMode.ADVANCED && !canUseDeepID.value) {
    return [
      {
        key: Jurisdiction.ZERTES,
        label: i18n.t('certificates.ch'),
        flag: 'ch'
      }
    ];
  } else {
    return [
      {
        key: Jurisdiction.ZERTES,
        label: i18n.t('certificates.ch'),
        flag: 'ch'
      },
      {
        key: Jurisdiction.EIDAS,
        label: i18n.t('certificates.eu'),
        flag: 'eu'
      }
    ];
  }
});

const learnMoreLink = computed(() => {
  return `${import.meta.env.VITE_KNOWLEDGE_CENTER_URL}${i18n.t(
    'slugs.signature_levels'
  )}`;
});

const canUseDeepID = computed(() => {
  return (
    import.meta.env.VITE_AUTHORITY_SERVICE_ENABLED === 'true' ||
    AuthService.hasBetaFeature(BetaFeature.DEEP_ID_SIGN)
  );
});

watchEffect(() => {
  if (
    currentDocument.value &&
    currentDocument.value.authorityService !== AuthorityService.DEEP_ID
  ) {
    currentDocument.value.jurisdiction;
    validityArea.value = Jurisdiction.ZERTES;
  }
});

watch(
  () => currentDocument.value,
  (document) => {
    if (document) {
      validityArea.value = document.jurisdiction;
      selectedLevel.value = document.signatureMode;
    }
  },
  {
    deep: true
  }
);

if (currentDocument.value) {
  selectedLevel.value = currentDocument.value.signatureMode;
  validityArea.value = currentDocument.value.jurisdiction;
  panel.value = items.value.findIndex(
    (item) => item.key === selectedLevel.value
  );
}

function selectLevel(level: SignatureMode) {
  selectedLevel.value = level;
  let jurisdiction = validityArea.value;
  if (level !== SignatureMode.QUALIFIED) {
    jurisdiction = Jurisdiction.ZERTES;
    validityArea.value = jurisdiction;
  }
  emitter.$emit('update-signature-level', {
    level: selectedLevel.value,
    newJurisdiction: validityArea.value
  });
}

function selectJurisdiction(jurisdiction: Jurisdiction) {
  validityArea.value = jurisdiction;
  emitter.$emit('update-signature-level', {
    level: selectedLevel.value,
    newJurisdiction: validityArea.value
  });
}
</script>

<style lang="scss" scoped>
@import '@/styles/core/colors';

.header-container {
  margin-bottom: 12px;
}

.signature-level-card {
  margin-right: 16px;
  width: 132px;
  height: 42px;
  background: white;
  border-radius: 8px;

  &:last-of-type {
    margin-right: 0;
  }

  &.mobile {
    width: auto;
    padding-right: 16px;
    padding-left: 16px;
  }
}

.signature-level-card-active {
  width: 132px;
  height: 42px;
  background: $primary-color !important;
  border: 1px solid $primary-color;
  border-radius: 8px;
  color: $primary-color !important;
}

.signature-level-card-text {
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: black !important;

  &.mobile {
    font-size: 12px;
  }
}

.signature-level-card-text-active {
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: white !important;
}

.signature-level-description {
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.004em;
  color: #0009;
}

.signature-level-description-divider {
  margin: 12px 0;
  border-color: #d9d9d9 !important;
}

.radio-label-text {
  margin-left: 8px;
  font-weight: 400;
}

.more-information {
  font-size: 12px;
  text-transform: none;
  color: $primary-color;
}

.v-radio-group :deep(.v-input__details) {
  display: none;
}

.v-radio-group :deep(.v-label) {
  opacity: 1;
}
</style>
