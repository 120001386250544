import { ref } from 'vue';
import mitt from 'mitt';
import type { Handler, Emitter } from 'mitt';
interface MyEvents {
  [key: string]: any;
  [key: symbol]: any;
}

let emitter: Emitter<MyEvents> | null = null;

export function useEmitter() {
  if (!emitter) emitter = mitt();

  const emitterRef = ref(emitter);

  function $on<E extends keyof MyEvents>(
    type: E,
    handler: Handler<MyEvents[E]>
  ) {
    emitterRef.value.on(type, handler);
  }

  function $off<E extends keyof MyEvents>(
    type: E,
    handler: Handler<MyEvents[E]>
  ) {
    emitterRef.value.off(type, handler);
  }

  function $emit<E extends keyof MyEvents>(type: E, event?: MyEvents[E]) {
    emitterRef.value.emit(type, event);
  }

  return {
    $on,
    $off,
    $emit
  };
}
