import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import type { UserInfo } from '@/types/deepadmin/UserInfo';
import type { AxiosRequestConfig } from 'axios';
import type { UpdateUserPayload } from '@/types/deepadmin/payloads/UpdateUserPayload';
import type { SendEmailRequestPayload } from '@/types/deepadmin/payloads/SendEmailRequestPayload';
import { useMessageStore } from '@/stores/message/message';
import { EmailRequestType } from '@/types/deepadmin/enums/EmailRequestType';
import type { CheckMailRefPayload } from '@/types/deepadmin/payloads/CheckMailRefPayload';
import deepadminApi from '@/api/deepadmin/deepadmin';
import type { AddressFilterPayload } from '@/types/AddressFilterPayload';
import globalAxios from '@/composables/useAxios';
import type { Company } from '@/types/deepadmin/Company';

export const useDeepAdminStore = defineStore('deepadmin', () => {
  // API base url
  const apiBaseUrl = import.meta.env.VITE_DEEPADMIN_API_BASE_URL;

  // User Info
  const fetchUserInfoPending = ref(false);
  const storeUserLanguagePending = ref(false);
  const updateUserPending = ref(false);
  const userInfo: Ref<UserInfo | null> = ref(null);
  const userLang = ref('');
  const checkMailRefPending = ref(false);
  const emailHasAccount = ref(false);

  async function fetchUserInfo() {
    try {
      fetchUserInfoPending.value = true;
      const response = await globalAxios.get(`${apiBaseUrl}users/me`);
      userInfo.value = response.data as UserInfo;
      fetchUserInfoPending.value = false;
      return Promise.resolve(response);
    } catch (error) {
      fetchUserInfoPending.value = false;
      return Promise.reject(error);
    }
  }

  async function storeUserLanguage(lang: string) {
    try {
      storeUserLanguagePending.value = true;
      const response = await globalAxios.post(
        `${apiBaseUrl}users/me/language/`,
        {
          lang
        }
      );
      storeUserLanguagePending.value = false;
      userLang.value = response.data.lang;
      return Promise.resolve(response);
    } catch (error) {
      storeUserLanguagePending.value = false;
      return Promise.reject(error);
    }
  }

  async function updateUser(payload: UpdateUserPayload) {
    try {
      updateUserPending.value = true;
      const userId = payload.id;
      const response = await globalAxios.put(
        `${apiBaseUrl}users/${userId}`,
        payload
      );
      updateUserPending.value = false;
      return Promise.resolve(response);
    } catch (error) {
      updateUserPending.value = false;
      return Promise.reject(error);
    }
  }

  const settingsSelectableOrganizations = computed(() => {
    const companies = userInfo.value?.companies;
    if (!companies) {
      return new Array<Company>();
    }

    const verifiedCompanies = companies
      .filter(
        (org) =>
          ['strong', 'weak'].includes(org.verification_state) &&
          org.roles.includes('MANAGE_COMPANY')
      )
      .sort((a, b) => a.display_name.localeCompare(b.display_name));

    const orgs = new Array<Company>();
    const personalSpaces = new Array<Company>();

    for (const company of verifiedCompanies) {
      if (company.structure === 'personal') {
        personalSpaces.push(company);
      } else {
        orgs.push(company);
      }
    }

    return [...orgs, ...personalSpaces];
  });

  const personalSpaceId = computed(() => {
    return userInfo.value?.companies.find((org) => org.structure === 'personal')
      ?.group_id;
  });

  // Address
  const userAddress = ref();
  const fetchUserAddressPending = ref(false);

  async function fetchUserAddress(payload: AddressFilterPayload) {
    try {
      fetchUserAddressPending.value = true;

      const queryParams =
        '?' +
        Object.keys(payload)
          .map((key) => {
            return `${key}=${encodeURIComponent(payload[key])}`;
          })
          .join('&');
      const response = await deepadminApi.get(`users/address${queryParams}`);
      userAddress.value = response.data;
      fetchUserAddressPending.value = false;
      return Promise.resolve(response);
    } catch (error) {
      fetchUserAddressPending.value = false;
      return Promise.reject(error);
    }
  }

  // Mail ref
  async function checkMailRef(payload: CheckMailRefPayload) {
    if (!payload.key || !payload.email) return;
    try {
      checkMailRefPending.value = true;
      const response = await globalAxios.post(
        `${apiBaseUrl}users/check_email/`,
        payload,
        { progress: false } as AxiosRequestConfig
      );
      checkMailRefPending.value = false;
      if (response.data) {
        emailHasAccount.value = response.data.is_valid;
      }
      return Promise.resolve(response);
    } catch (error) {
      checkMailRefPending.value = false;
      return Promise.reject(error);
    }
  }

  // Mobile ID
  const checkMobileIDPending = ref(false);

  async function checkMobileID(payload: string) {
    try {
      checkMobileIDPending.value = true;
      const body = { phone: payload };
      const response = await globalAxios.post(
        `${apiBaseUrl}mobileid/check`,
        body
      );
      checkMobileIDPending.value = false;
      return Promise.resolve(response);
    } catch (error) {
      checkMobileIDPending.value = false;
      return Promise.reject(error);
    }
  }

  // Email request
  const messageStore = useMessageStore();
  const sendEmailRequestPending = ref(false);

  async function sendEmailRequest(payload: SendEmailRequestPayload) {
    try {
      sendEmailRequestPending.value = true;
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      } as AxiosRequestConfig;
      const formData = new FormData();
      if (payload.files) {
        payload.files.forEach((file) => formData.append('files', file));
      }
      formData.append('type', payload.type);
      formData.append('text', payload.text);
      formData.append('contact', payload.contact);
      const response = await globalAxios.post(
        `${apiBaseUrl}users/support`,
        formData,
        config
      );
      sendEmailRequestPending.value = false;
      messageStore.showMessage({
        key:
          payload.type === EmailRequestType.SUPPORT
            ? 'snackbars.support_requested'
            : 'snackbars.feedback_sent',
        color: 'success',
        icon: 'fa fa-circle-check'
      });
      return Promise.resolve(response);
    } catch (error) {
      sendEmailRequestPending.value = false;
      messageStore.showMessage({
        key: 'snackbars.feedback_error',
        color: 'error',
        icon: 'fa fa-circle-exclamation'
      });
      return Promise.reject(error);
    }
  }

  return {
    // User Info
    fetchUserInfoPending,
    settingsSelectableOrganizations,
    storeUserLanguagePending,
    updateUserPending,
    userInfo,
    userLang,
    personalSpaceId,
    fetchUserInfo,
    storeUserLanguage,
    updateUser,

    // User Address
    userAddress,
    fetchUserAddressPending,
    fetchUserAddress,

    // Mail Ref
    checkMailRefPending,
    checkMailRef,
    emailHasAccount,

    // Mobile ID
    checkMobileIDPending,
    checkMobileID,

    // Email
    sendEmailRequestPending,
    sendEmailRequest
  };
});
