<template>
  <SignaturePad
    :key="signStore.signInfo?.defaultAutographUrl"
    :has-default-autograph="true"
    :initial-signature-url="signStore.signInfo?.defaultAutographUrl"
    :pen-color="'rgba(22,32,  81,0.84)'"
    :signer-email="signContext.userEmail.value"
  />
</template>

<script setup lang="ts">
import { useSignStore } from '@/stores/sign/sign';
import { useSignContext } from '@/composables/useSignContext';

const signContext = useSignContext();
const signStore = useSignStore();
</script>
