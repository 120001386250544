import { constants } from '@/constants/constants';
import { defineStore } from 'pinia';
import { ref } from 'vue';

interface Snackbar {
  key: string | null;
  active: boolean;
  color: string;
  text: string | null;
  icon: string | null;
  timeout: number;
  actions: SnackbarAction[];
}

interface SnackbarAction {
  text: string;
  href: string;
  color: string;
  icon: string;
  separator: boolean;
}

interface ErrorData {
  key: string;
  color: string;
}

const defaultSnackbar = {
  key: '',
  active: false,
  color: 'success',
  text: '',
  icon: '',
  timeout: constants.SNACKBAR.DURATION,
  actions: new Array<SnackbarAction>()
};

export const useMessageStore = defineStore('message', () => {
  const snackbar = ref<Snackbar>({
    ...defaultSnackbar
  });

  function showMessage(messageData: object) {
    Object.assign(snackbar.value, {
      ...messageData,
      active: true
    });
    setTimeout(() => {
      hideMessage();
    }, constants.SNACKBAR.DURATION);
  }

  function hideMessage() {
    snackbar.value = {
      ...defaultSnackbar
    };
  }

  function errorMessage() {
    hideMessage();
  }

  function showMessageValidateError() {
    const data: ErrorData = { key: 'form_validation_error', color: 'error' };
    showMessage(data);
  }

  return {
    // State
    snackbar,
    // Actions
    showMessage,
    hideMessage,
    showMessageValidateError,
    errorMessage
  };
});
