<template>
  <v-dialog v-model="showDialog" width="600" persistent>
    <v-card class="pa-6" rounded="lg">
      <!-- TITLE -->
      <v-card-title class="justify-center">
        <h5 class="card-title">
          {{ titleI18nByErrorCode }}
        </h5>
      </v-card-title>
      <!-- /TITLE -->

      <!-- TEXT -->
      <v-card-text class="pa-4 text-black text-center mb-10">
        {{ msgI18nByErrorCode }}
      </v-card-text>
      <!-- /TEXT -->

      <!-- ACTIONS -->
      <DeepButton
        :text="i18n.t('dialogs.session_invalid.btn_login')"
        :action="refresh"
      />
      <!-- /ACTIONS -->
    </v-card>
  </v-dialog>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { ref, computed, onBeforeUnmount } from 'vue';
import { useEmitter } from '@/composables/useEmitter.ts';

const emitter = useEmitter();
emitter.$on('open-session-invalid-dialog', openDialog);

const ERROR_CODES = {
  SESSION_EXPIRED: 'SESSION_EXPIRED',
  LOGGED_OUT: 'LOGGED_OUT'
};

const i18n = useI18n();
const showDialog = ref(false);
const errorCode = ref(ERROR_CODES.LOGGED_OUT);

const msgI18nByErrorCode = computed(() => {
  switch (errorCode.value) {
    case ERROR_CODES.LOGGED_OUT:
      return i18n.t('dialogs.session_invalid.loggedout.msg');
    case ERROR_CODES.SESSION_EXPIRED:
      return i18n.t('dialogs.session_invalid.session_expired.msg');
    default:
      return '';
  }
});

const titleI18nByErrorCode = computed(() => {
  switch (errorCode.value) {
    case ERROR_CODES.LOGGED_OUT:
      return i18n.t('dialogs.session_invalid.loggedout.title');
    case ERROR_CODES.SESSION_EXPIRED:
      return i18n.t('dialogs.session_invalid.session_expired.title');
    default:
      return '';
  }
});

onBeforeUnmount(() => {
  clear();
  emitter.$off('open-session-invalid-dialog', openDialog);
});

function openDialog({ message }) {
  errorCode.value = message;
  showDialog.value = true;
}

function clear() {
  errorCode.value = null;
  showDialog.value = false;
}

function refresh() {
  showDialog.value = false;
  return window.location.reload();
}
</script>
