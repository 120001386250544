<template>
  <v-dialog
    v-model="showDialog"
    persistent
    width="600"
    @keydown.enter="reject"
    @keydown.esc="() => (showDialog = false)"
  >
    <v-card class="p-6" rounded="lg">
      <!-- TITLE -->
      <v-card-title class="w-full mx-auto text-center">
        <span class="text-2xl font-bold">
          {{ i18n.t('titles.reject_signature') }}
        </span>
      </v-card-title>
      <!-- TEXT -->
      <v-card-text class="!p-0 text-black text-center mt-6 mb-8">
        {{ i18n.t('texts.reject_signature') }}
      </v-card-text>
      <div class="text-sm text-zinc-600 mb-1">
        {{ i18n.t('titles.reject_signature_text') }}
      </div>

      <v-textarea
        v-model="comment"
        no-resize
        variant="outlined"
        :placeholder="i18n.t('placeholders.reject_signature')"
      />

      <div class="pt-2">
        <DeepButton
          :action="reject"
          class="mb-2"
          :loading="
            signStore.rejectSignaturePending ||
            signStore.rejectAuthSignaturePending
          "
          :text="i18n.t('buttons.reject')"
        />
        <DeepButton
          :action="() => (showDialog = false)"
          variant="outlined"
          :text="i18n.t('buttons.cancel')"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup>
import AuthService from '@/services/auth-service';
import { useSignStore } from '@/stores/sign/sign.ts';
import { ref, onUnmounted, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import { useEmitter } from '@/composables/useEmitter';
import { useMessageStore } from '@/stores/message/message';

const i18n = useI18n();
const emitter = useEmitter();
const signStore = useSignStore();
const router = useRouter();
const route = useRoute();
const showDialog = ref(false);
const comment = ref(null);
const messageStore = useMessageStore();

const document_id = computed(() => {
  if (
    signStore.signInfo &&
    signStore.signInfo.document &&
    signStore.signInfo.document.documentId
  ) {
    return signStore.signInfo.document.documentId;
  } else {
    return route.params.document;
  }
});

emitter.$on('open-reject-dialog', () => (showDialog.value = true));

onUnmounted(() => {
  emitter.$off('open-reject-dialog', () => (showDialog.value = true));
});

const signKey = computed(() => signStore.signInfo.signKey);

async function reject() {
  try {
    const payload = {
      comment: comment.value,
      signKey: signKey.value
    };
    if (AuthService.isAuthenticated) {
      await signStore.rejectAuthSignature(payload);
      showDialog.value = false;
      messageStore.showMessage({
        key: 'snackbars.signature_rejected',
        color: 'success',
        icon: 'fa fa-circle-check'
      });
      await router.push({
        name: 'document-details',
        params: { document: document_id.value }
      });
    } else {
      await signStore.rejectSignature(payload);
      showDialog.value = false;
      messageStore.showMessage({
        key: 'snackbars.signature_rejected',
        color: 'success',
        icon: 'fa fa-circle-check'
      });
      window.location.reload();
    }
  } catch (error) {
    showDialog.value = false;
    messageStore.showMessage({
      key: 'errors.api.reject_signature',
      color: 'error',
      icon: 'fa fa-circle-exclamation'
    });
    console.error(
      'Error rejecting signature, signKey : ',
      signKey.value,
      error
    );
  }
}
</script>

<style lang="scss" scoped>
.cancel-button {
  margin-bottom: 10px;
}
</style>
