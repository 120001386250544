<template>
  <div class="d-flex flex-column align-center" style="width: 100%">
    <img src="/svg/authority-services/did-logo.svg" class="mb-3" alt="" />
    <div class="deepid-title mb-3">
      {{ i18n.t('titles.check_identity') }}
    </div>
    <div class="deep-id-hint">
      {{
        $vuetify.display.smAndDown && !showQr
          ? i18n.t('texts.check_identity_did_hint_mobile')
          : i18n.t('texts.check_identity_did_hint')
      }}
    </div>
    <GetDeepIDCard
      v-if="
        ($vuetify.display.mdAndUp && deepAdminInternalStore.userValidations) ||
        showQr
      "
      :user-validations="deepAdminInternalStore.userValidations"
    />
    <MobileDeepIDSigningCard v-else-if="!showQr" @show-qr="showQr = true" />

    <!-- ACTIONS -->
    <DeepButton
      v-if="$vuetify.display.smAndDown && !showQr"
      :action="getApp"
      :class="{ 'mb-3': $vuetify.display.smAndDown && !showQr }"
      color="primary"
      :text="i18n.t('buttons.download')"
    />
    <DeepButton
      :action="closeDialog"
      class="mt-1"
      variant="outlined"
      :text="i18n.t('buttons.cancel')"
    />
    <!-- /ACTIONS -->
  </div>
</template>

<script setup lang="ts">
import { useDeepAdminInternalStore } from '@/stores/deepadmin/deepadmin-internal';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
const emit = defineEmits(['close-dialog']);

const deepAdminInternalStore = useDeepAdminInternalStore();
const i18n = useI18n();
const showQr = ref(false);

function closeDialog() {
  emit('close-dialog');
}

function getApp() {
  if (
    deepAdminInternalStore.userValidations &&
    deepAdminInternalStore.userValidations.slice(-1)[0].URL
  ) {
    window.open(
      deepAdminInternalStore.userValidations.slice(-1)[0].URL,
      '_self'
    );
  } else {
    closeDialog();
  }
}
</script>

<style scoped lang="scss">
.deepid-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: #000000;
}

.deep-id-hint {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #000000;
  max-width: 412px;
  margin-bottom: 24px;
}
</style>
