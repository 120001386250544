import type { AxiosRequestConfig } from 'axios';
import deepSignApi from '@/api/deepsign/deepsignApi';
import type { DocumentObserversRequest } from '@/types/Document';

export const storeObserver = (
  documentId: string,
  data: DocumentObserversRequest,
  config?: AxiosRequestConfig
) => deepSignApi.post(`documents/${documentId}/observers`, data, config);

export const destroyObserver = (
  documentId: string,
  observerId: string,
  config?: AxiosRequestConfig
) =>
  deepSignApi.delete(`documents/${documentId}/observers/${observerId}`, config);

export const updateObserver = (
  documentId: string,
  observerId: string,
  data?: DocumentObserversRequest,
  config?: AxiosRequestConfig
) =>
  deepSignApi.put(
    `documents/${documentId}/observers/${observerId}`,
    data,
    config
  );
