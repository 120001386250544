<template>
  <v-dialog
    v-model="showUploadDialog"
    width="600"
    style="z-index: 10000; position: relative"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card class="pa-6" rounded="lg">
      <!-- TITLE -->
      <v-card-title class="justify-center py-0">
        <h5 class="card-title">
          {{ i18n.t('titles.upload_document') }}
        </h5>
      </v-card-title>
      <!-- /TITLE -->

      <DeepSignAlert v-if="showAlert" error class="text-red-500">
        <div class="flex flex-row align-center">
          <i class="fal fa-triangle-exclamation me-2 text-[28px]" />
          <span>{{ showAlertMessage }}</span>
        </div>
      </DeepSignAlert>

      <v-card-text class="text-center px-0 card-body">
        <div
          class="d-flex flex-column upload-zone align-center mb-6"
          :class="uploadedFiles.length === 0 ? 'cursor-pointer' : 'cursor-auto'"
          @click="onCardClick"
        >
          <div class="w-full py-1 h-full grid items-center justify-center">
            <input
              ref="fileInput"
              :accept="acceptedExtensions"
              class="fileInput"
              type="file"
              :disabled="uploadStarted"
              @input="upload"
            />
            <template v-if="uploadedFiles.length === 0">
              <i class="fa-duotone fa-file-plus file-plus-icon"></i>
              <div class="deep-footnote deep-link">
                {{ i18n.t('texts.drag_drop_or') }}
                <a>{{ i18n.t('texts.upload_file') }}</a>
              </div>
              <div class="text-caption">
                {{ i18n.t('texts.document_size_limit_upload') }}
              </div>
            </template>

            <template v-else-if="uploadedFiles.length === 1">
              <div class="grid h-full items-center justify-center">
                <div class="text-wrap px-2" style="max-width: 375px">
                  {{ uploadedFiles[0].file.name }}
                </div>

                <div class="d-flex flex-row justify-center hint-message">
                  <div>
                    {{ humanFileSize(uploadedFiles[0].file.size, 0) }}
                  </div>

                  <v-divider class="mx-3 upload-divider" vertical />
                  <div
                    class="remove-button"
                    @click.stop="removeFileAndReset(uploadedFiles[0])"
                  >
                    {{ i18n.t('buttons.remove') }}
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </v-card-text>

      <DeepButton
        :action="uploadDocumentForValidation"
        :disabled="validateSignaturesDisable"
        color="primary"
        :text="i18n.t('buttons.continue')"
      />
      <DeepButton
        v-if="
          uploadStarted &&
          successfullyUploadedFiles.length === 0 &&
          errorsCount.length > 1 &&
          !addDocumentsFromFilesPending
        "
        :action="clear"
        :text="i18n.t('buttons.clear')"
      />
      <DeepButton
        :action="closeDialog"
        :loading="addDocumentsFromFilesPending || isLoading"
        class="mt-3"
        variant="outlined"
        :text="i18n.t(uploadStarted ? 'buttons.close' : 'buttons.cancel')"
      />
      <!-- /USER HAS VALID ORGANIZATION -->

      <!-- NO VALID ORGANIZATION -->
      <div v-show="hover" class="drop-overlay"></div>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { constants } from '@/constants/constants';
import { computed, onUnmounted, ref } from 'vue';
import { useEmitter } from '@/composables/useEmitter';
import { getAllFileEntries, humanFileSize } from '@/composables/useUtils';
import { useI18n } from 'vue-i18n';
import { UploadStatus } from '@/types/enums/UploadStatus';
import { v4 as uuid } from 'uuid';
import type { DropEvent } from '@/utils/types/DropEvent';
import type { FileType } from '@/types/ui';

const emitter = useEmitter();
const i18n = useI18n();

const showUploadDialog = ref(false);
const acceptedExtensions = ref('application/pdf');
const hover = ref(false);
const uploadedFiles = ref<FileType[]>([]);
const selectedOrganization = ref(null);
const maxFileSizeMB = ref(40);
const uploadStarted = ref(false);
const addDocumentsFromFilesPending = ref(false);
const fileInput = ref();

const showAlert = computed(() => {
  return uploadedFiles.value.some((file) => {
    return (
      file.isTooLarge ||
      file.status === UploadStatus.UNSUPPORTED ||
      (file.status === UploadStatus.ERROR && uploadedFiles.value.length === 1)
    );
  });
});

const showAlertMessage = computed(() => {
  if (uploadedFiles.value.length !== 1) {
    return i18n.t('texts.unsupported_file_format_or_too_large');
  } else if (uploadedFiles.value[0].isTooLarge) {
    return i18n.t('texts.file_too_large');
  } else if (uploadedFiles.value[0].status === UploadStatus.UNSUPPORTED) {
    return i18n.t('texts.unsupported_file_format');
  } else {
    return i18n.t('errors.api.' + uploadedFiles.value[0].apiError);
  }
});

const validateSignaturesDisable = computed(() => {
  return uploadedFiles.value.length === 0 || showAlert.value;
});

const successfullyUploadedFiles = computed(() => {
  return uploadedFiles.value.filter(
    (file) => file.status === UploadStatus.UPLOADED
  );
});

const errorsCount = computed(() => {
  const apiErrorCounts = uploadedFiles.value.reduce((map, { apiError }) => {
    if (apiError !== null) {
      map.set(apiError, (map.get(apiError) || 0) + 1);
    }
    return map;
  }, new Map());

  return Array.from(apiErrorCounts, ([apiError, count]) => ({
    apiError,
    count
  }));
});

const isLoading = computed(() => {
  return uploadedFiles.value.some(
    (file) => file.status === UploadStatus.PROGRESS
  );
});

emitter.$on('open-upload-document-validation-dialog', openDialog);

onUnmounted(() => {
  emitter.$off('open-upload-document-validation-dialog', openDialog);
});

function openDialog() {
  showUploadDialog.value = true;
  uploadStarted.value = false;
  window.addEventListener('dragenter', dragenter);
  window.addEventListener('dragover', dragover);
  window.addEventListener('dragleave', dragleave);
  window.addEventListener('drop', drop);
}

function closeDialog() {
  window.removeEventListener('dragenter', dragenter);
  window.removeEventListener('dragover', dragover);
  window.removeEventListener('dragleave', dragleave);
  window.removeEventListener('drop', drop);
  if (fileInput.value) fileInput.value.value = null;
  showUploadDialog.value = false;
  uploadedFiles.value = [];
  selectedOrganization.value = null;
}

function dragenter(event: Event) {
  event.preventDefault();
  hover.value = true;
}

function dragleave(event: Event) {
  event.preventDefault();
  hover.value = false;
}

function dragover(event: Event) {
  event.preventDefault();
  hover.value = true;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function upload(event: any) {
  // no selected file
  if (!event?.target?.files[0]) {
    return;
  }

  // selected files
  uploadStarted.value = false;
  const filesToProcess = [...event.target.files];
  filesToProcess.forEach((file) => {
    let status = UploadStatus.READY;
    if (!constants.SUPPORTED_DOCUMENT_TYPES.includes(file.type)) {
      status = UploadStatus.UNSUPPORTED;
    }
    uploadedFiles.value.push({
      key: uuid(),
      file: file,
      isTooLarge: isTooLarge(file),
      apiError: undefined,
      status: status
    });
  });

  fileInput.value.value = null;
}

async function drop(event: DropEvent) {
  event.preventDefault();
  if (uploadedFiles.value.length > 0) return;
  hover.value = false;
  uploadStarted.value = false;
  const entries = await getAllFileEntries(event);
  entries.files.forEach((file) => {
    let status = UploadStatus.READY;
    if (
      file.file &&
      !constants.SUPPORTED_DOCUMENT_TYPES.includes(file.file.type)
    ) {
      status = UploadStatus.UNSUPPORTED;
    }
    if (file.file) {
      uploadedFiles.value.push({
        key: uuid(),
        file: file.file,
        isTooLarge: isTooLarge(file.file),
        apiError: undefined,
        status: status
      });
    }
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function removeFile(file: any) {
  uploadedFiles.value = uploadedFiles.value.filter((uf) => uf.key !== file.key);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function removeFileAndReset(file: any) {
  removeFile(file);
  uploadStarted.value = false;
}

function clear() {
  uploadedFiles.value = [];
  uploadStarted.value = false;
}

async function uploadDocumentForValidation() {
  emitter.$emit('upload-document-for-validation', uploadedFiles.value[0].file);
  closeDialog();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isTooLarge(file: any) {
  return Number((file.size / 1000000).toFixed(1)) > maxFileSizeMB.value;
}

function onCardClick() {
  if (uploadedFiles.value.length === 0) {
    fileInput.value.click();
  }
}
</script>

<style lang="scss">
@import '@/styles/core/colors';

.dialog-container {
  padding: 60px 82px;
}

.card-title {
  word-break: break-word;
  font-weight: 600;
  font-size: 24px !important;
  text-align: center;
  margin: 0 0 30px;
  white-space: normal;
}

.instructions {
  margin-bottom: 90px;
  text-align: center;
}

.mobile-title {
  margin-left: 0;
  margin-right: 0;
}

.mobile-instructions {
  margin-bottom: 45px;
}

.create-document-dialog {
  overflow-x: hidden;
  border-radius: 12px;
}

.deep-card-title {
  padding: 21px 24px !important;
}

.upload-zone {
  height: 172px;
  border-radius: 8px;
  border: 1px dashed $philippine-silver;
  max-height: 172px;
  overflow-y: auto;
  overflow-x: hidden;
  &.multiple {
    height: auto;
  }
  &.disabled:hover {
    cursor: default;
  }
}

.file-plus-icon {
  margin-top: 38px;
  margin-bottom: 24px;
  font-size: 34px;
  color: $primary-color;
}

.deep-footnote {
  color: $black-text;
  margin-bottom: 8px;

  a {
    text-decoration: none;
  }
}

input.fileInput {
  display: none;
}

.dragover {
  background-color: rgba(93, 74, 198, 0.05);
}

.upload-divider {
  margin-top: 2px;
  min-height: 75%;
}

.hint-message {
  margin-top: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #8e8e93;
}

.deep-callout {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: $black-text;
}

.deepbox-logo {
  margin-top: 2px;
}

.choose-deepbox {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}

.remove-button {
  &:hover {
    cursor: pointer !important;
    color: $primary-color !important;
  }
}

.unsupported-file-warning {
  margin-top: 30px;
  border-radius: 10px !important;
  height: 52px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.no-company {
  max-width: 340px;
}

.drop-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
</style>
