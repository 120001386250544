<template>
  <div
    class="grid items-center justify-center grid-cols-12 h-12"
    style="color: #b1b1b7"
  >
    <!-- FILE NAME -->
    <div class="grid col-span-8" :data-test-id="`attached-${fileName}`">
      <button
        v-if="
          attachmentType === 'attachment' &&
          currentDocument?.documentStatus !== DocumentStatus.DRAFT
        "
        :class="{
          underline:
            attachmentType === 'attachment' &&
            currentDocument?.documentStatus !== DocumentStatus.DRAFT
        }"
        class="xl:w-[20rem] w-full text-truncate file-name mt-0.5 text-left"
        @click="emit('preview-file', file)"
      >
        {{ fileName }}
      </button>
      <span v-else class="xl:w-[20rem] w-full text-truncate file-name mt-0.5">
        {{ fileName }}
      </span>
    </div>
    <!-- /FILE NAME -->

    <!-- Status -->
    <div
      class="grid col-span-3 justify-end"
      :class="{
        'col-span-4':
          file.status === AttachmentStatus.PENDING ||
          file.status === AttachmentStatus.PROGRESS ||
          file.status === AttachmentStatus.DISABLED
      }"
    >
      <!-- Error state -->
      <div
        v-if="
          file.status === AttachmentStatus.EXCLUDED ||
          file.status === AttachmentStatus.ERROR
        "
        class="d-flex flex-column align-end"
        data-test-id="exclamation-triangle"
      >
        <v-menu location="left top" max-width="300" open-on-hover open-on-click>
          <template #activator="{ props: ActivatorProps }">
            <v-icon
              v-bind="ActivatorProps"
              color="#F99C2C"
              size="16"
              data-test-id="icon-password-protected"
            >
              fal fa-triangle-exclamation
            </v-icon>
          </template>
          <v-card>
            <v-card-text data-test-id="label-tooltip-password-protected">
              {{
                file.errorId
                  ? i18n.t(`errors.api.${file.errorId}`)
                  : i18n.t('texts.file_too_large')
              }}
            </v-card-text>
          </v-card>
        </v-menu>
      </div>
      <!-- Attached state -->
      <div
        v-if="file.status === AttachmentStatus.ATTACHED"
        class="text-black text-sm"
      >
        {{ fileSize }}
      </div>

      <!-- Inprogress state -->
      <div
        v-if="
          file.status === AttachmentStatus.PROGRESS ||
          file.status === AttachmentStatus.DISABLED
        "
        class="d-flex flex-column align-end"
      >
        <v-icon color="primary" size="16"> fas fa-circle-notch fa-spin </v-icon>
      </div>
      <div
        v-if="file.status === AttachmentStatus.PENDING"
        class="d-flex flex-column align-end"
      >
        <v-icon size="16"> fal fa-hourglass-start </v-icon>
      </div>

      <!-- Unsupported state -->
      <div
        v-if="file.status === AttachmentStatus.UNSUPPORTED"
        class="d-flex flex-column align-end"
      >
        <v-menu location="left top" max-width="300" open-on-hover open-on-click>
          <template #activator="{ props: ActivatorProps }">
            <v-icon v-bind="ActivatorProps" color="error" size="14">
              far fa-file-slash
            </v-icon>
          </template>
          <v-card>
            <v-card-text class="signature-level-description">
              {{ i18n.t('texts.unsupported_attachment') }}
            </v-card-text>
          </v-card>
        </v-menu>
      </div>
    </div>

    <!-- Actions -->
    <div
      v-if="
        file.status !== AttachmentStatus.PENDING &&
        file.status !== AttachmentStatus.PROGRESS &&
        file.status !== AttachmentStatus.DISABLED
      "
      class="grid col-span-1 items-center justify-end"
    >
      <!-- REMOVE -->
      <!-- //currentDocument?.documentStatus === DocumentStatus.DRAFT -->
      <div
        v-if="
          currentDocument?.documentStatus === DocumentStatus.DRAFT &&
          attachmentType === 'attachment'
        "
        class="flex items-center justify-center"
      >
        <v-icon
          data-test-id="remove-file"
          @click="emit('remove-uploaded-attachment', props.file)"
        >
          fal fa-close
        </v-icon>
      </div>
      <div
        v-if="
          currentDocument?.documentStatus !== DocumentStatus.DRAFT &&
          attachmentType === 'signee-attachment'
        "
        class="flex items-center justify-center"
      >
        <v-icon @click="emit('remove-uploaded-attachment', props.file)">
          fal fa-close
        </v-icon>
      </div>
      <!-- /REMOVE -->
      <div
        v-if="
          currentDocument?.documentStatus !== DocumentStatus.DRAFT &&
          attachmentType === 'attachment'
        "
        class="flex items-center justify-center gap-3"
      >
        <!-- DOWNLOAD -->
        <!-- currentDocument?.documentStatus !== DocumentStatus.DRAFT -->
        <div class="flex items-center justify-center">
          <v-icon
            v-tooltip="i18n.t('buttons.download')"
            size="16"
            color="black"
            :data-test-id="`download-attachment-${fileName}`"
            @click="emit('download-file', props.file)"
          >
            fa-regular fa-download
          </v-icon>
        </div>
        <!-- /DOWNLOAD -->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AttachmentStatus } from '@/types/enums/AttachmentStatus.ts';
import { computed, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { humanFileSize } from '@/composables/useUtils';
import type { AttachmentFile } from '@/types/Attachment';
import { useDocumentContext } from '@/composables/useDocumentContext';
import { DocumentStatus } from '@/types/enums/DocumentStatus';

const props = defineProps({
  file: {
    type: Object as PropType<AttachmentFile>,
    default: undefined
  },
  excluded: {
    type: Boolean,
    default: false
  },
  attachmentType: {
    type: String as PropType<'attachment' | 'signee-attachment'>,
    default: undefined
  }
});

const emit = defineEmits([
  'remove-uploaded-attachment',
  'download-file',
  'preview-file'
]);
const { currentDocument } = useDocumentContext();
const i18n = useI18n();

const fileSize = computed(() => {
  return props.file?.status === AttachmentStatus.ATTACHED &&
    props.file &&
    props.file.size
    ? humanFileSize(props.file.size)
    : '';
});

const fileName = computed(() => {
  return props.file?.name;
});
</script>

<style scoped lang="scss">
@import '@/styles/core/colors';

.file-name {
  color: $light-theme-black;
}

.tooltip {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
}
</style>
