import type { SignKeyInfo } from '@/types/SignKeyInfo';
import type { UserInfo } from '@/types/deepadmin/UserInfo';
import { SignatureMode } from '@/types/enums/SignatureMode';
import { assign, createMachine } from 'xstate';

interface Context {
  isInvite: boolean;
  user: undefined | UserInfo;
  invitationLink: undefined | string;
  info: undefined | SignKeyInfo;
  signKey: undefined | string;
  signStore: undefined | object;
}

interface LoginEvent {
  type: 'LOGIN';
}

interface LogoutEvent {
  type: 'LOGOUT';
}

interface UpdateInfoEvent {
  type: 'UPDATE_INFO';
  data: SignKeyInfo;
}

export const inviteMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QEsB2A3ZAXMA6ArrGAE64A2A9lDBAPL5YDEAMrQOICSAcgNoAMAXUSgADhVjZkFVMJAAPRACY+AVlwrFADgCMAZgBs27QHZ9xxYt0AaEAE9E2gCz7cATmPHdixxu0rjepoAvkE2aJg4BESklNSQHKgs7LQAqgAq-EJIIGISWFIy2QoI2q4umsbufJq6xnzams7Wdkq6uDX6fI66uk6K2lrBoSDh2HgQFADG+AC2YKhYuJMAFmCTANZoUABKFAxgjJmyuZLSssWWjrjGOor6+q6ujlq6FTb2CCpeuI7aZn5-PRPRQhMIYMa4CbTOYLJarDZbXb7Q7aLKicSnQqgYp+RzGNwmRSuHqmTx8RTvRA9VzqMz9J6uVTdfSgkbgyJQ2bzRaUACGEC2jAmqDw4Qo6zwfIgJCO2RO+TORUQ+hUmnUvSJfD4ulU9VclM+1Tcjnq2lUJnMKm0rNGHKmXNh6BIyAAZrYtilogBlLC8nCHQTHDEKrHyRCPfFE-Q1Ur+VSeA36bw-P4BXGuAZEm3s8b2mGLJ3EV3u1BQT0kH1+g48VFBvIFc7h9y4KMx1xx-zND4aFxarV4ozmVS6bMRXPQ7m4QvFj3RANonLBhtKkqdRS4FWdVNfPwqA3GRw07xaP5eRQqHzGUcQzn5qfOt2zkgohfy5fYhwqfwb4wqLURhonmMRNDBTVx+j4Mo-CZa87QnWERF5YgJFLBIx2YNB1nnOtMUbEoiRpQd7kePgkxUfRHANE01S-aNagsZQvFg8cHUWRDkK2NCxgw1AsJrV8l0VD8Sl6K5fmJL4OxuLtEGcdcfCAvxNGjK0+CvYZbRYu92JQqAuJwHi+MUAT6yEsMRO6DcHleTRXGUwxwINL51wzf4rUMXRgRCYZUAoaV4GyTScJDPCAFoTRbe5umcB5DyaA1QoBXBTzKLUkwPXRHCGMExyiEhgvfcz8U8XRyK+HU-y+AIEv0Np7k88jSO1RpPGYvKYioGh6CwAqzOKL9rh6Mqel1KqDUaFs7l6IxbPuP9rQ0nN2vITr4lDN8+qpEx2gPZRHAUzpf3GlxzFqoxALm+o2tvbletDYp8UZJNMrqTRLk8mSEHPddSICVyDAvTRVGuvNJxWNZNlLJEcDuvCBqey5Xve-UWk+HUrLME1CTKUwQfgnkKH5LZYZXE0aW1AYPAac8bi6Jy1OS+4HgqNSlOBxbcpux0HxLMtvV9GG5UE+7ECB8oBj-LUXmHJz0aZupTSl148dY+8i0fUty2IEnhIGI1vCyjw3p0Rk91RpN1yZh4KPcX9vBV7SkN0-SwEMnXzKMZ4fi+IHajqZQKVRvFislwITUPZTHAdydeTISgAHdIHdnFyKuEwvyJTz+juem6ooixow8ZRXOj2EBVgWOE6ToXTJFkovzaWpI8ML8KnuJyvmSwYnnEipGm8oIgA */
    context: {
      isInvite: false,
      user: undefined,
      invitationLink: undefined,
      info: undefined,
      signKey: undefined,
      signStore: undefined
    },
    id: 'invite',
    states: {
      user: {
        initial: 'loggedOut',
        states: {
          loggedOut: {
            on: {
              LOGIN: {
                target: 'loggedIn'
              }
            }
          },
          loggedIn: {
            on: {
              LOGOUT: {
                target: 'loggedOut'
              }
            }
          }
        }
      },
      document: {
        initial: 'checkingRoute',
        states: {
          checkingRoute: {
            always: [
              { target: 'loading', cond: 'isInvitePage' },
              { target: 'allowed' }
            ]
          },
          loading: {
            invoke: {
              id: 'loader',
              src: 'getKeyInfo',
              onDone: {
                target: 'verifyingUserState',
                actions: assign({
                  info: (_context, event) => event.data?.data || undefined
                })
              }
            }
          },

          verifyingUserState: {
            always: [
              { target: 'verifyingUser', cond: 'isUserLoggedIn' },
              { target: 'parsingInviteLink' }
            ]
          },

          verifyingUser: {
            always: [
              { target: 'allowed', cond: 'isInviteUserMatching' },
              { target: 'disallowed' }
            ]
          },

          parsingInviteLink: {
            always: [
              {
                target: 'allowed',
                cond: 'isValidLink'
              },
              {
                target: 'allowed',
                cond: 'isPartiallyValid'
              },
              {
                target: 'disallowed'
              }
            ]
          },

          allowed: {},
          disallowed: {}
        }
      }
    },
    type: 'parallel',
    schema: {
      events: {} as LoginEvent | LogoutEvent | UpdateInfoEvent,
      context: {} as Context
    },
    predictableActionArguments: true
  },
  {
    services: {
      getKeyInfo: (context) =>
        getSignKeyInfo(context?.signKey, context.signStore)
    },
    guards: {
      isInvitePage: (context) => context.isInvite,

      isInviteUserMatching: (context) =>
        isInvitationForLoggedInUser(context.user, context.info),

      isValidLink: (context) =>
        isInvitationLinkValid(
          context.invitationLink,
          context.info,
          context.isInvite
        ),

      isPartiallyValid: (context) =>
        isInvitationLinkPartiallyValid(context.invitationLink),

      isUserLoggedIn: (context) => !!(context.user && context.user?.email)
    }
  }
);

function isInvitationLinkValid(
  link?: string,
  signKeyInfo?: SignKeyInfo,
  isInvite?: boolean
) {
  if (!link) return false;
  if (!isInvite) return true;
  const linkUrl = new URL(link);
  const params = linkUrl.searchParams;
  if (signKeyInfo?.signatureMode !== SignatureMode.TIMESTAMP) {
    return !!params.get('mail-ref');
  }
  return !!(params.get('sid') && params.get('mail-ref'));
}

function isInvitationLinkPartiallyValid(link?: string) {
  if (!link) return false;
  const linkUrl = new URL(link);
  const params = linkUrl.searchParams;
  return !!params.get('sid');
}

function isInvitationForLoggedInUser(
  user?: UserInfo,
  signKeyInfo?: SignKeyInfo
) {
  if (!user || !signKeyInfo) return false;
  return user.email.toLowerCase() === signKeyInfo.email.toLowerCase();
}

function getSignKeyInfo(signKey: string | undefined, signStore) {
  if (signKey) {
    return signStore.fetchSignKeyInfo(signKey);
  }
  return Promise.resolve();
}
