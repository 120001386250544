import {
  destroySignOrder,
  showSignOrder,
  updateSignOrder
} from '@/api/deepsign/sign-order.ts';
import type { AddSigneePayload } from '@/types/AddSigneePayload.ts';
import globalAxios from '@/composables/useAxios.ts';
import { SignatureType } from '@/types/enums/SignatureType.ts';
import { SigneeType } from '@/types/enums/SigneeType.ts';
import type { RemoveSigneePayload } from '@/types/RemoveSigneePayload.ts';
import type { UpdateSigneePayload } from '@/types/UpdateSigneePayload.ts';
import type { Signee } from '@/types/Signee.ts';
import type { AxiosRequestConfig } from 'axios';
import type { DocumentRequest } from '@/types/Document.ts';
import { updateDocument } from '@/api/deepsign/document.ts';
import { SignatureMode } from '@/types/enums/SignatureMode.ts';
import { Jurisdiction } from '@/types/enums/Jurisdiction.ts';
import { AuthorityService } from '@/types/enums/AuthorityService.ts';
import { useEmitter } from '@/composables/useEmitter.ts';
import { destroyObserver } from '@/api/deepsign/observer.ts';

export function useInviteesActions(store) {
  const emitter = useEmitter();
  const createStore = store;
  async function updateDocumentSignOrder(
    documentId: string,
    inviteesGroups: Array<Array<Signee>>
  ) {
    try {
      const data = createUpdateSignOrderPayload(inviteesGroups);
      await updateSignOrder(documentId, data);
    } catch (e) {
      console.error(
        `Failed to update sign order for document with Document ID: ${documentId} `,
        e
      );
    }
  }

  function createUpdateSignOrderPayload(inviteesGroups: Array<Array<Signee>>) {
    const filteredGroups = inviteesGroups.filter((group) => group.length > 0);
    return filteredGroups.map((group) => {
      return group.map((signee) => signee.signeeId);
    });
  }

  async function updateSignOrderForInitialGrouping(documentId: string) {
    try {
      const requestBody: string[][] = [];
      const signeesOrderedFlat = createStore.document!.signeesOrdered.flat();
      signeesOrderedFlat.forEach((signee: Signee) => {
        if (signee) {
          requestBody.push([signee.signeeId]);
        }
      });
      await updateSignOrder(documentId, requestBody);
    } catch (e) {
      console.error(
        `Failed to update sign order for document with Document ID: ${documentId} `,
        e
      );
    }
  }

  async function updateSignOrderForInitialApproverGrouping(
    documentId: string,
    tempGroups: Array<Array<Signee>>
  ) {
    try {
      const updatedGroups =
        createStore.createInitialGroupsWithApprovers(tempGroups);
      const requestBody: string[][] = updatedGroups.map((group) =>
        group.map((signee) => signee.signeeId)
      );

      await updateSignOrder(documentId, requestBody);
    } catch (e) {
      console.error(
        `Failed to update sign order for document with Document ID: ${documentId} `,
        e
      );
    }
  }

  async function deleteDocumentSignOrder(documentId: string) {
    try {
      await destroySignOrder(documentId);
      await getAndSetSignOrderContext(documentId);
    } catch (e) {
      console.error(
        `Failed to delete document sign order for document with Document ID: ${documentId} `,
        e
      );
    }
  }

  async function getSignOrder(documentId: string) {
    try {
      const newOrderContext = await showSignOrder(documentId);
      return newOrderContext.data?.context || '';
    } catch (e) {
      console.error(`Get sign order failed. Reason: ${e}`);
      return '';
    }
  }

  async function getAndSetSignOrderContext(documentId: string) {
    if (
      createStore.document &&
      createStore.document.signeesOrdered.length > 0
    ) {
      createStore.signOrderContextLabel = await getSignOrder(documentId);
    }
  }

  async function addInvitee(payload: Partial<AddSigneePayload>) {
    try {
      const response = await globalAxios.post(
        `${import.meta.env.VITE_DEEPSIGN_API_BASE_URL}documents/${
          payload.documentId
        }/signees`,
        {
          email: payload?.email || null,
          comment: payload?.comment || '',
          signOrder: payload?.signOrder || 0,
          autographPosition: payload?.autographPosition || null,
          signatureType: payload.signatureType || SignatureType.SIGNATURE,
          signeeType: payload?.signeeType || SigneeType.SIGN
        }
      );

      await getAndSetSignOrderContext(createStore.document!.documentId);

      return response;
    } catch (e) {
      console.error('Error while adding Invitee ', e);
      return e;
    }
  }

  async function removeInvitee(payload: RemoveSigneePayload) {
    try {
      const response = await globalAxios.delete(
        `${import.meta.env.VITE_DEEPSIGN_API_BASE_URL}documents/${
          createStore.document!.documentId
        }/signees/${payload.signeeId}`
      );
      createStore.removeInviteeFromLocalData(payload.signeeId);
      await getAndSetSignOrderContext(createStore.document!.documentId);
      return response;
    } catch (e) {
      return e;
    }
  }

  async function updateInvitee(payload: UpdateSigneePayload) {
    try {
      const body = {
        autographPosition: payload?.autographPosition || null,
        email: payload?.email || null,
        comment: payload.comment,
        signatureType: payload.signatureType
      };
      const response = await globalAxios.put(
        `${import.meta.env.VITE_DEEPSIGN_API_BASE_URL}documents/${
          payload.documentId
        }/signees/${payload.signeeId}`,
        body
      );
      await getAndSetSignOrderContext(createStore.document!.documentId);
      return response;
    } catch (e) {
      return e;
    }
  }

  async function updateDocumentById(
    id: string,
    data: DocumentRequest,
    config?: AxiosRequestConfig
  ) {
    await updateDocument(id, data, config);
    if (createStore.document) {
      if (data.signeeAttachmentsAllowed || !data.signeeAttachmentsAllowed) {
        createStore.document.signeeAttachmentsAllowed =
          data.signeeAttachmentsAllowed;
      }

      if (data.requiredAuthorityService !== undefined) {
        createStore.document.requiredAuthorityService =
          data.requiredAuthorityService;
      }
      if (data.jurisdiction) {
        createStore.document.jurisdiction = data.jurisdiction;
      }
      if (data.signatureMode) {
        createStore.document.signatureMode = data.signatureMode;
      }
    }
  }

  async function updateSignatureMessage(newMessage) {
    try {
      await updateDocumentById(createStore.document!.documentId, {
        comment: newMessage
      } as DocumentRequest);
    } catch (error) {
      console.error('Error adding document comment ', error);
    }
  }

  async function updateAttachmentsAllow(value) {
    if (value !== createStore.document!.signeeAttachmentsAllowed) {
      try {
        await updateDocumentById(createStore.document!.documentId, {
          signeeAttachmentsAllowed: value
        } as DocumentRequest);
      } catch (error) {
        console.error('error updating attachments option ', error);
      }
    }
  }

  async function updateSignatureLevel({ level, newJurisdiction }) {
    try {
      await updateDocumentById(createStore.document!.documentId, {
        signatureMode: level,
        jurisdiction: newJurisdiction,
        signeeAttachmentsAllowed: createStore.document!.signeeAttachmentsAllowed
      } as DocumentRequest);
      if (
        level === SignatureMode.ADVANCED &&
        newJurisdiction === Jurisdiction.EIDAS
      ) {
        emitter.$emit('set-authority-service', AuthorityService.DEEP_ID);
      }
    } catch (error) {
      console.error('error updating document signature level ', error);
    }
  }

  async function enforceAuthorityService(serviceKey) {
    try {
      await updateDocumentById(createStore.document!.documentId, {
        requiredAuthorityService: serviceKey
      } as DocumentRequest);
    } catch (error) {
      console.warn('Error enforcing authority service', error);
    }
  }

  async function updateAuthorityService(authorityService) {
    try {
      let jurisdiction = createStore.document!.jurisdiction;
      if (authorityService !== AuthorityService.DEEP_ID) {
        jurisdiction = Jurisdiction.ZERTES;
      }
      await updateDocumentById(createStore.document!.documentId, {
        authorityService,
        jurisdiction
      } as unknown as DocumentRequest);
    } catch (error) {
      console.error('error updating document authority service ', error);
    }
  }

  async function deleteObserver(documentId: string, observerId: string) {
    await destroyObserver(documentId, observerId);
    if (createStore.document) {
      createStore.document.observers = createStore.document.observers.filter(
        (obs) => obs.observerId !== observerId
      );
    }
  }

  async function removeAllInviteeFieldsWithEmptyEmail() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const promises: Array<Promise<any>> = [];
    for (const invitee of createStore.document!.signeesOrdered.flat()) {
      if (invitee.email === null) {
        promises.push(
          createStore.removeInvitee({
            documentId: createStore.document!.documentId,
            signeeId: invitee.signeeId
          })
        );
      }
    }
    await Promise.all(promises);
  }

  async function removeAllObserversWithEmptyEmail() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const promises: Array<Promise<any>> = [];
    for (const observer of createStore.document!.observers) {
      if (observer.email === null) {
        promises.push(
          createStore.deleteObserver(
            createStore.document!.documentId,
            observer.observerId
          )
        );
      }
    }
    await Promise.all(promises);
  }

  return {
    addInvitee,
    deleteDocumentSignOrder,
    deleteObserver,
    createUpdateSignOrderPayload,
    enforceAuthorityService,
    getSignOrder,
    getAndSetSignOrderContext,
    updateDocumentSignOrder,
    updateDocumentById,
    updateSignatureMessage,
    updateAttachmentsAllow,
    updateAuthorityService,
    updateSignatureLevel,
    updateInvitee,
    updateSignOrderForInitialGrouping,
    updateSignOrderForInitialApproverGrouping,
    removeInvitee,
    removeAllInviteeFieldsWithEmptyEmail,
    removeAllObserversWithEmptyEmail
  };
}
