<template>
  <div class="d-flex flex-row align-center h-[30px]">
    <button :class="labelClassValue" :disabled="disabled" @click="onLabelClick">
      {{ label }}
    </button>

    <slot name="label-append"></slot>

    <v-spacer />

    <v-switch
      :id="switchId"
      v-model="switchValue"
      class="ma-0"
      :class="{ active: switchValue }"
      :disabled="disabled"
      hide-details
      :model-value="initialValue"
      :data-test-id="dataTestId"
      @update:model-value="onChange"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
  initialValue: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    required: true
  },
  labelClass: {
    type: String,
    default: null
  },
  switchId: {
    type: String,
    default: null
  },
  dataTestId: {
    type: String,
    default: null
  }
});

const emit = defineEmits(['value-changed']);

const switchValue = ref(props.initialValue);

const labelClassValue = computed(() => {
  let labelClassString = '';
  if (props.labelClass) {
    labelClassString += props.labelClass;
  }
  if (props.disabled === true) {
    if (labelClassString.length > 0) {
      labelClassString += ' ';
    }
    labelClassString += 'disabled';
  }
  return labelClassString;
});

const onChange = (event) => {
  emit('value-changed', event);
};

const onLabelClick = () => {
  if (!props.disabled) {
    switchValue.value = !switchValue.value;
    onChange(switchValue.value);
  }
};

watch(
  () => props.initialValue,
  (newVal) => {
    switchValue.value = newVal;
  }
);
</script>

<style scoped lang="scss">
@import '@/styles/core/colors';

.disabled {
  color: $grey;
}

.v-switch.active :deep(.v-switch__track) {
  background-color: #adc7ff !important;
}
.v-switch.active :deep(.v-switch__thumb) {
  background-color: #0052ff;
}
</style>
