<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="device?.isMobile || device?.isTablet"
    max-width="800"
    scrollable
    data-test-id="pdf-preview-dialog"
    @keydown.esc="closeDialog"
  >
    <v-card class="">
      <v-card-title class="text-center border-b">
        <h5
          class="md:text-base text-sm flex items-center justify-center my-2 whitespace-normal text-center"
        >
          {{ title }}
        </h5>
      </v-card-title>
      <v-card-text class="p-4 bg-[#757575]" style="height: 900px">
        <Loader v-if="!isPdfRendered" />
        <VuePdfEmbed
          v-show="documentUrl && isPdfRendered"
          id="pdf-preview"
          ref="pdfPreview"
          class="pdf-preview"
          :source="{
            verbosity: 0,
            url: documentUrl
          }"
          @rendered="handleRendered"
        />
      </v-card-text>
      <v-card-actions class="flex flex-col p-4 border-t">
        <v-btn
          class="w-full"
          color="primary"
          variant="flat"
          data-test-id="preview-download"
          @click="emit('download-file', file)"
        >
          {{ i18n.t('buttons.download') }}
        </v-btn>
        <v-btn
          class="w-full"
          color="primary"
          variant="outlined"
          data-test-id="preview-close"
          @click="closeDialog"
        >
          {{ i18n.t('buttons.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import VuePdfEmbed from 'vue-pdf-embed';
import { ref, onUnmounted, inject } from 'vue';
import { useEmitter } from '@/composables/useEmitter.ts';
import { useI18n } from 'vue-i18n';
import type { AttachmentFile } from '@/types/Attachment';
import { DeviceKey } from '@/plugins/device-detector-js.ts';

const i18n = useI18n();
const device = inject(DeviceKey);
const emitter = useEmitter();
const dialog = ref(false);
const documentUrl = ref();
const file = ref<AttachmentFile>();
const title = ref();
const isPdfRendered = ref(false);

emitter.$on('show-pdf-preview-dialog', showDialog);

const emit = defineEmits(['download-file']);

onUnmounted(() => {
  emitter.$off('show-pdf-preview-dialog', showDialog);
});

function handleRendered() {
  isPdfRendered.value = true;
}

function showDialog(data: { file: AttachmentFile; documentUrl: string }) {
  dialog.value = true;
  if (data.documentUrl) documentUrl.value = data.documentUrl;
  if (data.file.name) title.value = data.file.name;
  if (data.file) file.value = data.file;
}

function closeDialog() {
  dialog.value = false;
}
</script>
