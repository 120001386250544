import { useSanitizeQueryParams } from '@/composables/useUtils';
import type { Member } from '@/types/deepsign/settings';
import type { Pagination } from '@/types/deepsign/Pagination';
import type { AxiosResponse } from 'axios';
import deepadminApi from '@/api/deepadmin/deepadmin';

export interface CompanyMembersRequest {
  page?: string;
  page_size?: string;
  search?: string;
  subject_type?: ['user' | 'service_user'];
}

export function getSubjects(
  companyId: string,
  payload: { search: string; subject_type: string[] }
): Promise<AxiosResponse<Pagination<Member>>> {
  const params = useSanitizeQueryParams(payload);

  return deepadminApi.get(`users/organisations/${companyId}/subjects`, {
    params
  });
}
