<template>
  <div class="flex flex-col">
    <div
      v-if="['idle', 'progress'].includes(removeObserverStatus)"
      class="dialog-text-hint"
    >
      <I18nT keypath="dialogs.document_dialog.subtitles.remove_observer" />
    </div>
    <DeepSignAlert v-if="removeObserverStatus === 'error'" class="mb-6" error>
      <div class="text-sm">
        {{ i18n.t('errors.generic') }}
      </div>
    </DeepSignAlert>

    <v-btn
      v-if="['idle', 'progress'].includes(removeObserverStatus)"
      ref="removeObserverButton"
      class="mt-2"
      color="primary"
      elevation="0"
      @click="removeObserver"
    >
      <I18nT keypath="buttons.remove_observer" />
    </v-btn>

    <v-btn
      class="mt-2"
      variant="outlined"
      color="primary"
      @click="emit('closeDialog')"
    >
      <I18nT
        :keypath="
          removeObserverStatus === 'error' ? 'buttons.close' : 'buttons.cancel'
        "
      />
    </v-btn>
  </div>
</template>

<script setup lang="ts">
import { deleteObserver } from '@/api/deepsign/sign-observer';
import { useDocumentContext } from '@/composables/useDocumentContext';
import { useGlobalStore } from '@/stores/global/global';
import { useMessageStore } from '@/stores/message/message';
import { useSignStore } from '@/stores/sign/sign';
import type { ComponentState } from '@/types/ui';
import { nextTick } from 'vue';
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

const emit = defineEmits(['closeDialog']);
const removeObserverStatus = ref<ComponentState>('idle');
const { currentDocument } = useDocumentContext();
const globalStore = useGlobalStore();
const signStore = useSignStore();
const messageStore = useMessageStore();
const i18n = useI18n();
const removeObserverButton = ref();

async function removeObserver() {
  try {
    removeObserverStatus.value = 'progress';
    if (globalStore.selectedObserver && currentDocument.value) {
      await deleteObserver(
        currentDocument.value.documentId,
        globalStore.selectedObserver.observerId
      );
      signStore.removeObserver(globalStore.selectedObserver.observerId);
      removeObserverStatus.value = 'success';
      messageStore.showMessage({
        text: i18n.t('snackbars.observer_removed'),
        color: 'success',
        icon: 'fa fa-circle-check'
      });
      emit('closeDialog');
    } else {
      removeObserverStatus.value = 'idle';
    }
  } catch (error) {
    removeObserverStatus.value = 'error';
  }
}

onMounted(() => {
  nextTick(() => {
    removeObserverButton.value.$el.focus();
  });
});
</script>
