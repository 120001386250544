import axios from 'axios';
import { setAuthorizationHeaders } from '@/api/utils';
import { onRejected } from '@/api/interceptor';

const deepadminApi = axios.create({
  baseURL: import.meta.env.VITE_DEEPADMIN_API_BASE_URL
});

deepadminApi.interceptors.request.use(async (config) => {
  const headers = {};

  await setAuthorizationHeaders(headers);

  Object.assign(config.headers, headers);
  return {
    ...config
  };
});

deepadminApi.interceptors.response.use((response) => {
  return response;
}, onRejected);

export default deepadminApi;
