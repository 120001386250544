import { computed } from 'vue';
import { useSignStore } from '@/stores/sign/sign';
import { useRoute } from 'vue-router';
import { useCreateStore } from '@/stores/create.ts';

export function useDocumentContext() {
  const signStore = useSignStore();
  const createStore = useCreateStore();

  const route = useRoute();

  const isDraft = computed(() => {
    return (
      route.name === 'document-create' || route.name === 'p-document-create'
    );
  });

  const currentDocument = computed(() => {
    if (isDraft.value || isDocumentAuthRoute.value) {
      return createStore.document;
    } else {
      return signStore.signInfo?.document;
    }
  });

  const currentPlacedSignatures = computed(() => {
    if (isDraft.value || isDocumentAuthRoute.value) {
      return createStore.placedSignatures;
    } else {
      return signStore.placedSignatures;
    }
  });

  const currentSignees = computed(() => {
    if (isDraft.value || isDocumentAuthRoute.value) {
      return createStore.filteredSignees;
    } else {
      return signStore.signInfo?.document?.signees;
    }
  });

  const isDocumentAuthRoute = computed(() => {
    return route.name?.startsWith('p-document') ?? false;
  });

  return {
    isDocumentAuthRoute,
    isDraft,
    currentDocument,
    currentPlacedSignatures,
    currentSignees
  };
}
