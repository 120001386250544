<template>
  <v-card rounded="lg" color="#F7F8FA" class="pa-8 mb-2" flat>
    <div class="d-flex flex-column align-center">
      <v-img src="/img/deepsign_app.png" class="app-logo mb-8" />
      <div class="hint-text mb-2">
        {{ i18n.t('texts.open_deep_id') }}
      </div>
      <AppStoresCard />
      <a class="action-link" @click="emit('show-qr')">
        {{ i18n.t('actions.open_on_different_device') }}
      </a>
    </div>
  </v-card>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const i18n = useI18n();
const emit = defineEmits(['show-qr']);
</script>

<style scoped lang="scss">
.app-logo {
  aspect-ratio: 1 !important;
  height: 102px;
  width: 102px;
  border-radius: 16px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
.hint-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}
.action-link {
  margin-top: 1rem;
  text-decoration: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
</style>
