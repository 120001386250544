import axios from 'axios';
import { onRejected } from '@/api/interceptor';

const publicAxios = axios.create({
  baseURL: `${import.meta.env.VITE_DEEPSIGN_API_BASE_URL}p/`,
  headers: {
    common: {
      Accept: 'application/json'
    }
  }
});

publicAxios.interceptors.response.use(function (response) {
  return response;
}, onRejected);

export default publicAxios;
