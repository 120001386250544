import { computed, ref } from 'vue';
import { useJwt } from '@vueuse/integrations/useJwt';

export function useDocumentAuth() {
  const logoutTimer = ref<number>();

  function login(id: string, token: string) {
    sessionStorage.setItem('documentId', id);
    sessionStorage.setItem('documentToken', token);
  }

  function logout() {
    sessionStorage.removeItem('documentId');
    sessionStorage.removeItem('documentToken');
    window.open('/document-access', '_self');
  }

  const isAuthenticated = computed(() => {
    return (
      !!sessionStorage.getItem('documentId') &&
      !!sessionStorage.getItem('documentToken')
    );
  });

  const getDocumentToken = computed(() =>
    sessionStorage.getItem('documentToken')
  );

  const getDocumentId = computed(() => sessionStorage.getItem('documentId'));

  function checkToken() {
    if (logoutTimer.value) {
      clearTimeout(logoutTimer.value);
      logoutTimer.value = undefined;
    }

    if (getDocumentToken.value) {
      try {
        const decodedToken = useJwt(getDocumentToken.value);
        if (decodedToken.payload.value?.exp) {
          const currentTime = Math.floor(Date.now() / 1000);
          const timeToLogout =
            (decodedToken.payload.value?.exp - currentTime - 5) * 1000;
          if (timeToLogout > 0) {
            logoutTimer.value = setTimeout(logout, timeToLogout);
          } else {
            logout();
          }
        }
      } catch (error) {
        console.error('Error reading or decoding JWT token:', error);
        logout();
      }
    }
  }

  function clearTokenTimeout() {
    if (logoutTimer.value) {
      clearTimeout(logoutTimer.value);
      logoutTimer.value = undefined;
      logout();
    }
  }

  return {
    isAuthenticated,
    getDocumentToken,
    getDocumentId,
    clearTokenTimeout,
    checkToken,
    login,
    logout
  };
}
