<template>
  <v-dialog
    v-model="dialog"
    width="600"
    persistent
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card class="pa-6" rounded="lg">
      <!-- TITLE -->
      <v-card-title class="justify-center py-0">
        <h5 class="card-title">
          {{ title }}
        </h5>
      </v-card-title>
      <!-- /TITLE -->

      <!-- TEXT -->
      <v-card-text v-show="!!message" class="pa-4 text-black text-center mb-2">
        <div v-html="message"></div>
      </v-card-text>
      <!-- /TEXT -->

      <!-- ACTIONS -->

      <DeepButton
        :action="agree"
        class="mb-3"
        data-test-id="dialog-continue-button"
        :text="
          options.confirmActionText
            ? options.confirmActionText
            : i18n.t('buttons.proceed')
        "
      />

      <DeepButton
        v-if="!options.noCancel"
        :action="cancel"
        data-test-id="dialog-cancel-button"
        variant="outlined"
        :text="i18n.t('buttons.cancel')"
      />
      <!-- /ACTIONS -->
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const i18n = useI18n();
const dialog = ref(false);
const resolve = ref();
const reject = ref();
const message = ref();
const title = ref();
const options = ref({
  width: 500,
  confirmActionText: null,
  noCancel: false,
  zIndex: 2401
});

function open(titleParam, messageParam, actionParam, optionsParam) {
  dialog.value = true;
  title.value = titleParam;
  message.value = messageParam;
  options.value = Object.assign(options.value, optionsParam);
  return new Promise((resolveParam, rejectParam) => {
    resolve.value = resolveParam;
    reject.value = rejectParam;
  });
}

function agree() {
  resolve.value(true);
  dialog.value = false;
}

function cancel() {
  resolve.value(false);
  dialog.value = false;
}

defineExpose({
  agree,
  cancel,
  open
});
</script>

<style scoped lang="scss">
.card-title {
  word-break: break-word;
  font-weight: 600;
  font-size: 24px !important;
  text-align: center;
  white-space: normal;
}
</style>
