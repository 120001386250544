import {
  createRouter,
  createWebHistory,
  type NavigationGuardNext
} from 'vue-router';
import { captureException } from '@sentry/browser';
import type { Extras } from '@sentry/types/types/extra';
import publicAxios from '@/plugins/publicAxios';
import AuthService from '@/services/auth-service';
import { useErrorStore } from '@/stores/error/error';
import { useGlobalStore } from '@/stores/global/global';
import deepBoxDocumentGuardian from '@/router/guardians/deepBoxDocumentGuardian';
import reconnectGuardian from '@/router/guardians/reconnectGuardian';
import Dashboard from '@/layouts/DashboardView.vue';
import EmptyView from '@/views/EmptyView.vue';
import settingsGuardian from './guardians/settingsGuardian';

const routes = [
  {
    path: '/',
    alias: '/dashboard',
    components: {
      sidebar: () => import('@/views/sidebars/Dashboard.vue'),
      default: EmptyView
    },
    meta: {
      layout: 'LayoutSidebarLeft'
    },
    children: [
      {
        path: '',
        components: {
          default: Dashboard
        },
        name: 'dashboard'
      }
    ]
  },
  {
    path: '/batch',
    component: () => import('@/layouts/DocumentBatchView.vue'),
    name: 'document-batch',
    children: [
      {
        path: '/batch/sign',
        component: () => import('@/views/DocumentBatchPage.vue'),
        name: 'document-batch-sign'
      }
    ]
  },
  {
    path: '/deepbox',
    component: () => import('@/layouts/DocumentView.vue'),
    beforeEnter: (to, _from, next: NavigationGuardNext) => {
      const globalStore = useGlobalStore();
      globalStore.history = [];
      globalStore.addRouteToHistory(to.fullPath);
      next();
    },
    name: 'deepbox',
    children: [
      {
        path: '/deepbox/:id',
        component: EmptyView,
        beforeEnter: deepBoxDocumentGuardian(),
        name: 'deepbox-id'
      },
      {
        path: '/deepbox/:id?/pending',
        component: EmptyView,
        beforeEnter: deepBoxDocumentGuardian(),
        name: 'deepbox-id-pending'
      },
      {
        path: '/deepbox/:id?/signed',
        component: EmptyView,
        beforeEnter: deepBoxDocumentGuardian(),
        name: 'deepbox-id-signed'
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/DocumentView.vue'),
    children: [
      {
        path: '/sign/:id?',
        component: () => import('@/views/DocumentPage.vue'),
        name: 'sign-id',
        beforeEnter: (to, _from, next: NavigationGuardNext) => {
          if (to.query && to.query.sid) {
            publicAxios.defaults.headers['Authorization'] =
              `SID ${to.query.sid}`;
          }
          next();
        },
        meta: {
          auth: false
        }
      },
      {
        path: '/document-access',
        name: 'document-access',
        component: () => import('@/views/DocumentAccessPage.vue'),
        meta: {
          auth: false
        }
      },
      {
        path: '/new-link/:id?',
        component: () => import('@/views/NewLinkPage.vue'),
        name: 'new-link',
        meta: {
          auth: false
        }
      }
    ]
  },

  {
    path: '/document',
    components: {
      default: EmptyView
    },
    meta: {
      layout: 'DocumentView'
    },
    children: [
      {
        path: '/document/:document?/create',
        component: () => import('@/views/DocumentPage.vue'),
        name: 'document-create'
      },
      {
        path: '/document/:document?/details',
        component: () => import('@/views/DocumentPage.vue'),
        name: 'document-details',
        beforeEnter: (_to, _from, next: NavigationGuardNext) => {
          _to.params?.document ? next() : next({ name: 'dashboard' });
        }
      },
      {
        path: '/document/:document?/sign',
        component: () => import('@/views/DocumentPage.vue'),
        name: 'document-sign',
        beforeEnter: (_to, _from, next: NavigationGuardNext) => {
          _to.params?.document ? next() : next({ name: 'dashboard' });
        }
      },
      {
        path: '/document/:document?/seal',
        component: () => import('@/views/DocumentSealPage.vue'),
        name: 'document-seal'
      }
    ]
  },
  {
    path: '/p/document',
    name: 'public-document',
    component: () => import('@/layouts/DocumentView.vue'),
    meta: {
      auth: false
    },
    children: [
      {
        path: ':document?/create',
        component: () => import('@/views/DocumentPublicPage.vue'),
        name: 'p-document-create',
        meta: {
          auth: false
        }
      },
      {
        path: ':document?/view',
        component: () => import('@/views/DocumentPublicPage.vue'),
        name: 'p-document-view',
        meta: {
          auth: false
        }
      }
    ]
  },
  {
    path: '/reconnect',
    component: EmptyView,
    name: 'reconnect',
    beforeEnter: reconnectGuardian(),
    meta: {
      auth: false
    }
  },
  {
    path: '/settings',
    name: 'settings',
    beforeEnter: settingsGuardian()
  },
  {
    path: '/404',
    name: 'error-page',
    component: () => import('@/views/NotFound.vue'),
    meta: {
      auth: false
    }
  },
  {
    path: '/:catchAll(.*)*',
    alias: '/404',
    component: () => import('@/views/NotFound.vue'),
    name: '404',
    meta: {
      auth: false
    }
  }
];

// Create fn to init the router in order to solve an issue where KC query params stay on the URL
// https://github.com/dsb-norge/vue-keycloak-js/issues/94#issuecomment-1794403391
// https://github.com/keycloak/keycloak/issues/14742#issuecomment-1663069438
const initializeRouter = () => {
  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
    scrollBehavior(to) {
      if (to.params.savePosition) {
        return null;
      }
      return { x: 0, y: 0 };
    }
  });

  interface LoginOptions {
    redirectUri: string;
    locale?: string;
  }

  router.beforeEach(async (to, _from, next) => {
    if (AuthService.isAuthenticated && to.name !== 'settings') {
      sessionStorage.removeItem(
        `deepsign.${AuthService.user?.sub}.settings.back.route`
      );
    }

    if (!to.name?.startsWith('p-document')) {
      sessionStorage.removeItem('documentToken');
      sessionStorage.removeItem('documentId');
    }
    const errorStore = useErrorStore();
    if (errorStore.isError) {
      errorStore.clear();
    }

    const basePath = window.location.origin;
    const keycloakRedirectUri = basePath + to.fullPath;
    if (AuthService.isAuthenticated) {
      await AuthService.updateToken();
      next();
    } else {
      if (to.meta && to.meta.auth !== false) {
        const routeLang: string = (to.query?.lang as string) || '';
        const loginOptions: LoginOptions = { redirectUri: keycloakRedirectUri };
        if (routeLang) {
          loginOptions.locale = routeLang;
        }
        return AuthService.login(loginOptions);
      }
      next();
    }
  });

  router.onError((error: Error) => {
    if (error.message.includes('Failed to fetch dynamically imported module')) {
      const extras: Extras = { extra: error.message };
      captureException(
        'Triggered automatic reload after failed to fetch dynamically imported module. ',
        extras
      );
      return () => {
        window.location.reload();
      };
    }
  });

  console.log('ROUTER: router initialized');

  return router;
};

export default initializeRouter;
