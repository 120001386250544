<!-- eslint-disable -->
<template>
  <v-card class="signing-card" flat>
    <DocumentMenu
      ref="documentMenu"
      :document="document"
      :policy="isSignatureTypeSeal ? {} : policy"
      :sign-key="signKey"
    >
      <template #activator="{ props: ActivatorProps }">
        <v-btn
          v-bind="ActivatorProps"
          style="position: absolute; right: 4px; top: 8px"
          icon
          data-test-id="signing-card-options"
        >
          <VIcon class="text-gray-500"> fa-regular fa-ellipsis-vertical </VIcon>
        </v-btn>
      </template>
    </DocumentMenu>
    <div class="flex flex-row">
      <div class="flex flex-col w-full">
        <!-- TITLE -->
        <div class="flex flex-row h-[24px] rounded-0">
          {{
            isSignatureTypeSeal
              ? i18n.t('titles.sealed_as')
              : isSignedByUser
                ? i18n.t('titles.signed_as')
                : i18n.t('titles.signing_as')
          }}
        </div>
        <!-- /TITLE -->

        <!-- REQUESTOR DETAILS -->
        <SignerSignDetails
          :email="
            isSignatureTypeSeal ? document.initiatorCompanyDisplayName : email
          "
        >
          <template #signature-level>
            <DocumentSignatureLevel
              v-if="signStore.signInfo?.signatureType !== 'seal'"
              :jurisdiction="signStore.signInfo?.document.jurisdiction"
              :signature-mode="signStore.signInfo?.document.signatureMode"
            />
          </template>
          <template v-if="ownSignatureStatus === SignStatus.SIGNED" #checkmark>
            <v-icon class="status-icon mr-1" color="success" size="18">
              fas fa-circle-check
            </v-icon>
          </template>
        </SignerSignDetails>
        <!-- /REQUESTOR DETAILS -->
      </div>
      <DocumentDialog />
    </div>

    <!-- VISUAL SIGNATURE -->
    <div v-if="ownSignatureStatus !== SignStatus.SIGNED && userIsVerified">
      <div class="flex justify-center">
        <GuestSignaturePreview
          v-show="isGuest && isSignaturePlaced"
          class="w-full"
        />
        <AuthenticatedSignaturePreview v-show="!isGuest && isSignaturePlaced" />
      </div>
      <!-- /STORED VISUAL SIGNATURE -->
      <div
        v-if="
          isSignaturePlaced && signStore.signInfo.canModifyAutographPosition
        "
        class="pt-2.5 mb-2"
      >
        <MoveSignaturesMenu
          v-if="signStore.signInfo.canModifyAutographPosition"
          v-model="ownSignature"
          :update-only-one="true"
        />
      </div>
    </div>
    <!-- /VISUAL SIGNATURE -->

    <!-- ON HOLD ALERT -->
    <v-alert
      v-if="ownSignatureStatus === SignStatus.ON_HOLD"
      class="deep-caption my-4"
      variant="outlined"
      type="info"
    >
      {{
        waitingOnApproval
          ? i18n.t('texts.on_hold_approval_hint')
          : i18n.t('texts.on_hold_hint')
      }}
    </v-alert>
    <!-- /ON HOLD ALERT -->

    <!-- ACTIONS SECTION -->
    <div
      v-if="ownSignatureStatus !== SignStatus.SIGNED"
      class="d-flex flex-row justify-center align-center"
    >
      <div
        class="d-flex flex-column mb-2 actions-container"
        :class="{ 'align-center': !isSignedByUser }"
      >
        <!-- ATTACHMENTS -->
        <div
          v-if="
            canModifySigneeAttachments &&
            ownSignatureStatus !== SignStatus.ON_HOLD
          "
          class="grid w-full gap-4"
        >
          <v-divider class="mt-4" />
          <Attachments
            v-model="uploadedFiles"
            supported-types="application/pdf"
            attachment-type="signee-attachment"
            @update:model-value="handleUpload"
            @remove-file="handleRemove"
          />
          <v-divider class="mb-4 mt-2" />
        </div>
        <!-- /ATTACHMENTS -->

        <!-- ACTIONS -->

        <div class="flex flex-col w-full max-w-[380px] lg:max-w-[418px]">
          <!-- PLACE SIGNATURE -->

          <DeepButton
            v-if="showPlaceSignaturesButton"
            :action="placeSignaturesAutomatically"
            class="mt-2 w-full"
            :disabled="
              !globalStore.pdfPreviewRendered ||
              ownSignatureStatus === SignStatus.ON_HOLD
            "
            :text="signatureButtonText"
            data-test-id="place-signature"
            block
          />
          <!-- /PLACE SIGNATURE -->

          <!-- IDENTIFY -->
          <DeepButton
            v-if="hasSigneeIssues"
            :action="openIdentifyDialog"
            class="mt-2"
            :text="i18n.t('buttons.identify')"
            data-test-id="itentify-btn"
          />
          <!-- /IDENTIFY -->

          <!-- SIGN -->
          <DeepButton
            v-if="!isSignedByUser && !hideSignButton"
            data-test-id="sign-now"
            :action="sign"
            class="mt-2"
            :disabled="
              !signButtonEnabled ||
              ownSignatureStatus === SignStatus.ON_HOLD ||
              areAttachmentFilesInvalid ||
              hasSigneeIssues ||
              hasRequiredAutographUnsetted
            "
            :loading="signStore.signPending"
            :variant="
              !isSignaturePlaced &&
              autographRequirementLevel !==
                VisualSignatureRequirement.PROHIBITED
                ? 'outlined'
                : undefined
            "
            :text="i18n.t('buttons.sign_now')"
          />
          <!-- /SIGN -->

          <!-- AUTHORITY SERVICE -->
          <v-row
            v-if="showAuthorityMenu"
            class="d-flex align-center authority-service deep-footnote"
            no-gutters
          >
            <v-spacer />
            <div class="mt-1">
              {{ i18n.t('labels.id_service') }}
            </div>
            <AuthorityServiceMenu
              v-if="availableAuthorityServices.length > 0"
              :signature-mode="document.signatureMode"
              :authority-service="authorityService"
              :authority-services="availableAuthorityServices"
            />
          </v-row>
          <!-- /AUTHORITY SERVICE -->

          <!-- /ACTIONS -->
        </div>
      </div>
    </div>
    <!-- /ACTIONS SECTION -->

    <!-- SIGNING DETAILS OF SIGNED DOCUMENTS -->
    <div v-else class="flex items-center justify-center">
      <span class="signed">
        {{
          isSignatureTypeSeal
            ? i18n.t('document_status.sealed')
            : i18n.t('document_status.signed')
        }}:
      </span>
      <div class="flex items-center">
        <span class="text-gray-400 text-sm">
          {{ formatDateToLocale(ownSignature.signedTime, i18n.locale, true) }}
        </span>
        <v-tooltip content-class="" location="top" open-on-click>
          <VIcon :size="14" class="text-gray-400"> fa-regular fa-clock </VIcon>
          <span>
            {{
              formatDateTimeToLocaleTimeWithTimeZone(
                ownSignature.signedTime,
                i18n.locale
              )
            }}
          </span>
        </v-tooltip>
      </div>
      <v-spacer />
    </div>
    <!-- IDENTIFY DIALOG -->
    <IdentifyDialog />
  </v-card>
</template>

<script setup lang="ts">
import AuthService from '@/services/auth-service';
import { useGlobalStore } from '@/stores/global/global.ts';
import { SignatureMode } from '@/types/enums/SignatureMode.ts';
import { AuthorityService } from '@/types/enums/AuthorityService.ts';
import { SignStatus } from '@/types/enums/SignStatus.ts';
import { BetaFeature } from '@/types/enums/BetaFeature.ts';
import { AttachmentStatus } from '@/types/enums/AttachmentStatus.ts';
import { useSignStore } from '@/stores/sign/sign.ts';
import { usePreferencesStore } from '@/stores/deepadmin/preferences.ts';
import { watch, onUnmounted, ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useEmitter } from '@/composables/useEmitter.ts';
import { useI18n } from 'vue-i18n';
import {
  orderBy,
  formatDateToLocale,
  formatDateTimeToLocaleTimeWithTimeZone
} from '@/composables/useUtils';
import { useSignContext } from '@/composables/useSignContext';
import { useDocumentContext } from '@/composables/useDocumentContext';
import { VisualSignatureRequirement } from '@/types/enums/VisualSignatureRequirement';
import { v4 as uuid } from 'uuid';
import { SigneeType } from '@/types/enums/SigneeType';

const props = defineProps({
  authorityServiceKey: {
    type: String,
    default: null
  },
  document: {
    type: Object,
    default: undefined
  },
  signatures: {
    type: Array,
    default: () => []
  },
  email: {
    type: String,
    default: ''
  },
  canAddAutograph: {
    type: Boolean,
    default: false
  },
  canSign: {
    type: Boolean,
    default: false
  },
  isSignedByUser: {
    type: Boolean,
    default: false
  },
  signButtonEnabled: {
    type: Boolean,
    default: false
  },
  sign: {
    type: Function,
    required: true
  }
});

const emit = defineEmits(['update-authority-service', 'update-uploaded-files']);

const globalStore = useGlobalStore();
const signStore = useSignStore();
const preferencesStore = usePreferencesStore();
const route = useRoute();
const emitter = useEmitter();
const i18n = useI18n();
const { policy, isSignatureTypeSeal, isGuest, signKey } = useSignContext();
const { currentDocument } = useDocumentContext();
const changeAutograph = ref(false);
const uploadedFiles = ref([]);
const authorityService = ref(null);
const ownSignatureModel = ref();

const isSignaturePlaced = computed(() => {
  return signStore.placedSignatures.some(
    (signee) => signee.signeeId === signStore.signInfo?.signeeId
  );
});

const hasRequiredAutographUnsetted = computed(() => {
  return (
    currentDocument.value.autographRequired &&
    !(signStore.signInfo?.defaultAutographUrl || signStore.autographData) &&
    isSignaturePlaced.value
  );
});

const autographRequirementLevel = computed(() => {
  return currentDocument.value.visualSignatureRequirement;
});

const hideSignButton = computed(() => {
  return (
    autographRequirementLevel.value === VisualSignatureRequirement.REQUIRED &&
    !isSignaturePlaced.value
  );
});

const signaturesToPlace = computed(() => {
  const filteredSignees = props.signatures.filter(
    (signee) => signee.signeeType === SigneeType.SIGN
  );
  switch (route.name) {
    case 'document-sign':
    case 'sign-id':
      if (signStore.signInfo?.canModifyAutographPosition) {
        return props.signatures.filter(
          (signee) => signee.signeeId === signStore.signInfo?.signeeId
        );
      }
      return [];
    case 'document-create':
      return filteredSignees;
    case 'document-seal':
      return filteredSignees;
    default:
      return [];
  }
});

if (props.signatures) {
  ownSignatureModel.value = props.signatures.find(
    (signee) =>
      signee.signeeId === signStore.signInfo?.signeeId &&
      signee.signeeType === SigneeType.SIGN
  );
}

const ownSignature = computed({
  get: () =>
    props.signatures.find(
      (signee) =>
        signee.signeeId === signStore.signInfo?.signeeId &&
        signee.signeeType === SigneeType.SIGN
    ),
  set: (newValue) => {
    ownSignatureModel.value = newValue;
  }
});

const showPlaceSignaturesButton = computed(() => {
  if (autographRequirementLevel.value === VisualSignatureRequirement.PROHIBITED)
    return false;

  if (!props.canAddAutograph) return false;

  if (route.name === 'document-details') {
    return false;
  }
  if (route.name === 'sign-id') {
    return (
      ownSignature.value.autographPosition === null && !hasSigneeIssues.value
    );
  }
  return !isSignaturePlaced.value && !hasSigneeIssues.value;
});

const ownSignatureStatus = computed(() => ownSignature.value?.signStatus || '');
const waitingOnApproval = computed(() => {
  return !!props.signatures.find(
    (signee) =>
      (signee.signeeType === SigneeType.APPROVE &&
        signee.signStatus === SignStatus.ON_HOLD) ||
      signee.signStatus === SignStatus.PENDING
  );
});

const canModifySigneeAttachments = computed(() => {
  return policy.value.canModifySigneeAttachments;
});

const areAttachmentFilesInvalid = computed(() => {
  return uploadedFiles.value.filter(
    (file) => file.status !== AttachmentStatus.ATTACHED
  ).length;
});

const availableAuthorityServices = computed(() => {
  let services = [];
  if (signStore.signInfo) {
    services = Object.entries(signStore.signInfo?.availableAuthorityServices);
  }

  const availableServices = [];
  services.forEach((service) => {
    if (service[0] !== AuthorityService.YAPEAL_ID) {
      availableServices.push({
        key: service[0],
        ...service[1]
      });
    }
  });

  if (!canUseDeepID.value) {
    return availableServices.filter(
      (service) => service.key === AuthorityService.MOBILE_ID
    );
  }
  return orderBy(availableServices, ['key']);
});

const hasSigneeIssues = computed(
  () => authorityService.value?.signeeIssues.length > 0 || false
);

const signatureButtonText = computed(() => {
  if (route.name === 'document-seal') {
    return i18n.t('buttons.place_seal');
  } else {
    return i18n.t('buttons.place_signatures', signaturesToPlace.value.length);
  }
});

const showAuthorityMenu = computed(
  () => props.document?.signatureMode !== SignatureMode.TIMESTAMP
);

const userIsVerified = computed(() => authorityService.value?.hasRequiredLevel);

const canUseDeepID = computed(() => {
  return (
    import.meta.env.VITE_AUTHORITY_SERVICE_ENABLED === 'true' ||
    AuthService.hasBetaFeature(BetaFeature.DEEP_ID_SIGN)
  );
});

emitter.$on('update-change-autograph', updateChangeAutograph);
emitter.$on('set-authority-service', setAuthorityService);
emitter.$on('enable-sms-code', enableSmsCode);
emitter.$on('enable-deep-id', enableDeepId);

getAuthorityService();

if (authorityService.value === null || authorityService.value === undefined) {
  const lastAuthorityServiceKey =
    preferencesStore.preferences?.defaultAuthorityService;
  const lastAuthorityService = availableAuthorityServices.value.find(
    (service) => service.key === lastAuthorityServiceKey
  );
  if (lastAuthorityService) {
    authorityService.value = lastAuthorityService;
  } else {
    authorityService.value = availableAuthorityServices.value[0];
  }
}
signStore.signInfo?.signeeAttachments.forEach((attachment) => {
  uploadedFiles.value.push({
    file: null,
    status: AttachmentStatus.ATTACHED,
    attachment
  });
});

onUnmounted(() => {
  emitter.$off('update-change-autograph', updateChangeAutograph);
  emitter.$off('set-authority-service', setAuthorityService);
  emitter.$off('enable-sms-code', enableSmsCode);
  emitter.$off('enable-deep-id', enableDeepId);
});

function placeSignaturesAutomatically() {
  emitter.$emit('place-signatures-automatically', signaturesToPlace.value);
}

function updateChangeAutograph(value) {
  changeAutograph.value = value;
}

async function handleUpload(filesToUpload) {
  const isPublic = !AuthService.isAuthenticated;
  uploadedFiles.value = filesToUpload;

  const uploadPromises = uploadedFiles.value.map(async (file) => {
    if (file.status === AttachmentStatus.PENDING) {
      try {
        // eslint-disable-next-line no-param-reassign
        file.status = AttachmentStatus.PROGRESS;

        const response = await signStore.postAttachment({
          file: file.file,
          isPublic,
          signKey: signKey.value
        });

        const { signeeAttachmentId, name, size, mimeType } = response.data;

        const newFileData = {
          attachmentId: signeeAttachmentId,
          name,
          size,
          mimeType,
          uploadId: file.uploadId,
          status: AttachmentStatus.ATTACHED
        };

        uploadedFiles.value = uploadedFiles.value?.map((fileItem) => {
          return fileItem.uploadId === file.uploadId ? newFileData : fileItem;
        });

        emit('update-uploaded-files', uploadedFiles.value);
      } catch (error) {
        // eslint-disable-next-line no-param-reassign
        file.status = AttachmentStatus.ERROR;
        // eslint-disable-next-line no-param-reassign
        file.errorId = error.response?.data?.messageId || 'Unknown error';
      }
    }
  });

  await Promise.all(uploadPromises);
}

async function handleRemove(fileToRemove) {
  try {
    uploadedFiles.value = uploadedFiles.value?.map((file) =>
      file.uploadId === fileToRemove.uploadId
        ? { ...file, status: AttachmentStatus.DISABLED }
        : file
    );

    if (fileToRemove.attachmentId) {
      const isPublic = !AuthService.isAuthenticated;
      await signStore.deleteAttachment({
        isPublic,
        signKey: signKey.value,
        signeeAttachmentId: fileToRemove.attachmentId
      });

      uploadedFiles.value = uploadedFiles.value.filter(
        (file) => file.uploadId !== fileToRemove.uploadId
      );
      emit('update-uploaded-files', uploadedFiles.value);
    } else {
      uploadedFiles.value = uploadedFiles.value.filter(
        (file) => file.uploadId !== fileToRemove.uploadId
      );
      emit('update-uploaded-files', uploadedFiles.value);
    }
  } catch (error) {
    console.error('Error removing attachment:', error);

    // eslint-disable-next-line no-param-reassign
    fileToRemove.status = fileToRemove.attachment
      ? AttachmentStatus.ATTACHED
      : AttachmentStatus.PENDING;
  }
}

function setAuthorityService(event) {
  authorityService.value = availableAuthorityServices.value.find(
    (service) => service.key === event
  );
}

function openIdentifyDialog() {
  emitter.$emit('open-identify-dialog', {
    authorityService: authorityService.value,
    signatureMode: props.document.signatureMode
  });
}

function getAuthorityService() {
  authorityService.value = availableAuthorityServices.value.find(
    (service) => service.key === props.authorityServiceKey
  );
}

function enableSmsCode() {
  authorityService.value = availableAuthorityServices.value.find(
    (service) => service.key === 'ras'
  );
}

function enableDeepId() {
  authorityService.value = availableAuthorityServices.value.find(
    (service) => service.key === 'did'
  );
}

async function updateDefaultAutograph() {
  // Validate if a string is a URL
  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  // Check if defaultAutographUrl exists and is a valid URL
  const autographUrl = signStore.signInfo?.defaultAutographUrl;
  if (
    !autographUrl ||
    !isValidUrl(autographUrl) ||
    !autographUrl.startsWith('https://')
  ) {
    return;
  }

  try {
    const response = await fetch(autographUrl);
    if (!response.ok) {
      throw new Error(`Error fetching image: ${response.statusText}`);
    }
    const imageBlob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (signStore.signInfo) {
          signStore.signInfo.defaultAutographUrl = reader.result;
        }

        resolve();
      };
      reader.onerror = () => {
        signStore.signInfo.defaultAutographUrl = null;
        reject(new Error('Error converting image to Base64'));
      };
      reader.readAsDataURL(imageBlob);
    });
  } catch (error) {
    console.error('Error downloading image:', error);
    signStore.signInfo.defaultAutographUrl = null;
  }
}

watch(
  () => signStore.signInfo,
  (newValue) => {
    if (newValue?.defaultAutographUrl) {
      updateDefaultAutograph();
    }
  },
  {
    immediate: true,
    deep: true
  }
);

watch(
  () => authorityService.value,
  (value) => {
    emit('update-authority-service', value?.key || null);
  },
  {
    immediate: true,
    deep: true
  }
);

watch(
  () => signStore.signInfo?.signeeAttachments,
  (newValue) => {
    if (newValue) {
      uploadedFiles.value = newValue.map((file) => {
        return {
          attachmentId: file.signeeAttachmentId,
          mimeType: file.mimeType,
          name: file.name,
          size: file.size,
          uploadId: uuid(),
          status: AttachmentStatus.ATTACHED
        };
      });
      emit('update-uploaded-files', uploadedFiles.value);
    }
  },
  {
    deep: true
  }
);
</script>

<style scoped lang="scss">
@import '@/styles/core/colors';

.signing-card {
  box-sizing: border-box;
  border-radius: 7px;
  background-color: $grey-background;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15) !important;
  border: 1px solid $grey-border;
  margin-bottom: 32px;
  margin-top: 16px;
  padding: 1rem;
}

.signee-email {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.0025em;
  color: black !important;
  padding-bottom: 8px;

  &.mobile {
    font-size: 16px;
  }
}

.signature-level {
  color: $grey;
  font-weight: 400 !important;
  margin-right: 6px;
  font-size: 14px !important;
}

.actions-container {
  width: 100%;
}

.signed {
  margin-right: 4px;
  color: $success;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

.signed-icon {
  margin-top: -28px;
  color: $success;
}

.date {
  color: $grey;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

.default-autograph {
  margin-top: 16px;
  margin-bottom: 10px;
  width: 100%;
  aspect-ratio: 196/48;
  border: 1px solid $grey;
  border-radius: 4px;
}

.change-default-autograph {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.change-button {
  &:hover {
    color: $primary-color;
    cursor: pointer;
  }

  &:hover :deep(.v-icon.v-icon) {
    color: $primary-color;
    cursor: pointer;
  }
}

.menu-icon {
  color: $grey;
}

.tooltip {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
}

.deep-caption-grey {
  color: $philippine-silver;
}

.too-large {
  color: $error;
}

.authority-service {
  color: #8e8e93;
  margin-top: 8px;
  width: 100%;
}
</style>
