<template>
  <div
    class="flex items-center justify-between"
    :class="{ 'mt-2': isSignatureTypeSeal }"
  >
    <div class="d-flex flex-column flex-fill" style="min-width: 0">
      <!-- EMAIL -->
      <div
        class="signer-email deep-truncate"
        :class="{ mobile: $vuetify.display.smAndDown }"
        data-test-id="signer-email"
      >
        {{ email }}
      </div>
      <!-- /EMAIL -->

      <slot name="signature-level"> </slot>
    </div>
    <!-- CHECK MARK -->
    <slot name="checkmark"> </slot>
    <!-- /CHECK MARK -->
  </div>
</template>

<script lang="ts" setup>
import { useSignStore } from '@/stores/sign/sign';
import { computed } from 'vue';

defineProps({
  email: {
    type: String,
    default: ''
  }
});

const signStore = useSignStore();

const isSignatureTypeSeal = computed(() => {
  return signStore.signInfo?.signatureType === 'seal';
});
</script>

<style scoped lang="scss">
@import '@/styles/core/colors';

.signer-email {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.0025em;
  color: black !important;

  &.mobile {
    font-size: 16px;
  }
}
</style>
