import AuthService from '@/services/auth-service';
import { computed } from 'vue';

export function useOnboarding() {
  const baseUrl = computed(() => {
    return `${window.location.origin}${window.location.pathname}`;
  });

  function openRegisterPage(redirectUrl: string, email: string | undefined) {
    const encodedRedirectUrl = redirectUrl.startsWith(baseUrl.value)
      ? redirectUrl
      : window.location.origin + redirectUrl;

    let url;
    const urlToTest = new URL(window.location.href);

    if (email && !urlToTest.searchParams.has('email')) {
      url = `${
        import.meta.env.VITE_ONBOARDING_FRONTEND_BASE_URL
      }register?onboarding=deepsign&redirectUrl=${encodedRedirectUrl}&email=${encodeURIComponent(
        email
      )}`;
    } else {
      url = `${
        import.meta.env.VITE_ONBOARDING_FRONTEND_BASE_URL
      }register?onboarding=deepsign&redirectUrl=${encodedRedirectUrl}`;
    }

    window.open(url, '_self');
  }

  async function logoutAndSetRedirectUrlParam(email: string | undefined) {
    const url = new URL(window.location.href);
    url.searchParams.set('autoRedirect', 'deep-sign');

    if (email && !url.searchParams.has('email')) {
      url.searchParams.set('email', email);
    }

    await AuthService.logout({
      redirectUri: url.href
    });
  }

  return {
    openRegisterPage,
    logoutAndSetRedirectUrlParam
  };
}
