import type { AxiosRequestConfig } from 'axios';
import deepSignApi from '@/api/deepsign/deepsignApi';
import type { Document, DocumentRequest } from '@/types/Document';

const url = 'documents';

export const listDocuments = async (
  config?: AxiosRequestConfig
): Promise<Document[]> => {
  const response = await deepSignApi.get(url, config);
  return response.data.documents;
};

export const showDocument = async (
  id: string,
  config?: AxiosRequestConfig
): Promise<Document> => {
  const response = await deepSignApi.get<Document>(`${url}/${id}`, config);
  return response.data;
};

export const destroyDocument = (id: string, config?: AxiosRequestConfig) =>
  deepSignApi.delete(`${url}/${id}`, config);

export const updateDocument = (
  id: string,
  data: DocumentRequest,
  config?: AxiosRequestConfig
) => deepSignApi.put(`${url}/${id}`, data, config);
