import deepSignApi from '@/api/deepsign/deepsignApi';
import type { ForwardSignee } from '@/types/deepsign/ForwardSignee';
import type { UpdateSignSignee } from '@/types/deepsign/UpdateSignSignee';

export const addSignee = (documentId: string, payload: ForwardSignee) =>
  deepSignApi.post(`sign/documents/${documentId}/signees`, payload);

export const deleteSignee = (documentId: string, signeeId: string) =>
  deepSignApi.delete(`sign/documents/${documentId}/signees/${signeeId}`);

export const updateSignee = (
  documentId: string,
  signeeId: string,
  payload: UpdateSignSignee
) =>
  deepSignApi.put(`sign/documents/${documentId}/signees/${signeeId}`, payload);

export const updateSigneeSignatureType = (
  documentId: string,
  signeeId: string,
  signatureType: 'seal' | 'signature'
) =>
  deepSignApi.put(`sign/documents/${documentId}/signees/${signeeId}`, {
    signatureType
  });
