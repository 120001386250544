import deepSignApi from '@/api/deepsign/deepsignApi';

export const getSealPreview = (sealId: string, sizeFactor: string) =>
  deepSignApi.post(
    `users/me/seals/${sealId}/preview`,
    { sizeFactor },
    {
      responseType: 'blob'
    }
  );
