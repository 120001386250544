import type { Signee } from '@/types/Signee.ts';

export function useInviteesSignaturePlacement(store) {
  const createStore = store;
  //SIGNATURE RELATED ACTIONS
  function removeAllPlacedSignatures() {
    const pdfPreviewNode = window.document.getElementById('pdf-preview');
    if (pdfPreviewNode) {
      const placedSignatures =
        pdfPreviewNode.getElementsByClassName('signature-preview');
      placedSignatures.forEach((signature: HTMLElement) => signature.remove());
    }
  }

  async function removeSignature(eventData) {
    try {
      const signee = createStore.filteredSignees.find(
        (signee: Signee) => signee.signeeId === eventData.id
      );
      const payload = {
        documentId: createStore.document!.documentId,
        signeeId: signee.signeeId,
        email: signee.email,
        comment: signee.signeeComment,
        autographPosition: null
      };
      const response = await createStore.updateInvitee(payload);
      createStore.updateInviteeInLocalData(response.data);

      resetSignaturePreviewStyle(eventData.id);
    } catch (error) {
      console.error('Error removing signature ', error);
    }
  }

  function resetSignaturePreviewStyle(signeeId: string) {
    const signature = window.document.getElementById(
      `signature-preview-${signeeId}`
    );
    if (signature) {
      signature.classList.remove('out-of-limits');
      signature.style.position = 'relative';
      signature.style.left = null;
      signature.style.top = null;
      signature.style.width = '100%';
      signature.style.height = '100%';
      signature.style.maxHeight = null;
      signature.style.maxWidth = null;
      signature.style.display = 'none';
    }
  }

  function deleteSignatureElement(signeeId: string) {
    const signature = window.document.getElementById(
      `signature-preview-${signeeId}`
    );
    if (signature) {
      signature.remove();
    }
  }

  return {
    deleteSignatureElement,
    removeAllPlacedSignatures,
    removeSignature,
    resetSignaturePreviewStyle
  };
}
