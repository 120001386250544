import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

export function useValidationRules() {
  const i18n = useI18n();

  const emailRules = computed(() => {
    const fieldName = i18n.t('labels.email');
    return [
      (v: any) => !!v || i18n.t('validation.required', { name: fieldName }),
      (v: any) =>
        /^[\w!#$%&'*+/=?^_`{|}~-]+(\.[\w!#$%&'*+/=?^_`{|}~-]+)*@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/i.test(
          v
        ) || i18n.t('validation.email', { name: fieldName })
    ];
  });

  const feedbackMessageRules = computed(() => {
    const fieldName = i18n.t('labels.feedback_message');
    return [
      (v: any) => !!v || i18n.t('validation.required', { name: fieldName })
    ];
  });

  const signatureRules = computed(() => {
    return [
      (v: any) =>
        v === null ||
        v.length === 0 ||
        /^[\w!#$%&'*+/=?^_`{|}~-]+(\.[\w!#$%&'*+/=?^_`{|}~-]+)*@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/i.test(
          v
        ) ||
        i18n.t('validation.email')
    ];
  });

  return {
    emailRules,
    feedbackMessageRules,
    signatureRules
  };
}
