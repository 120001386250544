import { defineStore } from 'pinia';
import { ref } from 'vue';
import {
  updateDocumentSubscriptionCheck,
  updateSubscriptionCheck
} from '@/api/subscription/subscription';
import type { SubscriptionCheck } from '@/types/deepsign/SubscriptionCheck';
import type { AxiosRequestConfig } from 'axios';

//TODO remove this along with all logic parts that use it @Juan @Daniel as this endpoint is deprecated and boolean is always set to true
export const useSubscriptionStore = defineStore('subscription', () => {
  const hasValidSubscription = ref(false);

  async function checkSubscription(data: SubscriptionCheck) {
    await updateSubscriptionCheck(data, {
      progress: false
    } as AxiosRequestConfig);
    hasValidSubscription.value = true;
  }

  async function checkDocumentSubscription(
    documentId: string,
    data: SubscriptionCheck
  ) {
    await updateDocumentSubscriptionCheck(documentId, data, {
      progress: false
    } as AxiosRequestConfig);
    hasValidSubscription.value = true;
  }

  return {
    hasValidSubscription,
    checkSubscription,
    checkDocumentSubscription
  };
});
