<template>
  <v-phone-input
    ref="phoneInput"
    :validate-on="validateOn"
    v-bind="$attrs"
    :include-countries="onlyCountries"
    :data-test-id="dataTestId"
    @update:model-value="updateModelValue"
  />
</template>
<script lang="ts" setup>
import { ref, type PropType } from 'vue';
import { VPhoneInput } from 'v-phone-input';
import { parsePhoneNumber } from 'awesome-phonenumber';
export type ValidateOnValue = 'blur' | 'input' | 'submit';
const emit = defineEmits(['update:modelValue']);
const phoneInput = ref();
defineProps({
  onlyCountries: {
    type: Array,
    default: () => []
  },
  validateOn: {
    type: String as PropType<
      | ValidateOnValue
      | `${ValidateOnValue} lazy`
      | `lazy ${ValidateOnValue}`
      | 'lazy'
    >,
    default: undefined
  },
  dataTestId: {
    type: String,
    default: 'input-phone'
  }
});

function updateModelValue(formattedNumber: string) {
  const phone = parsePhoneNumber(formattedNumber);
  emit('update:modelValue', phone);
}
</script>

<style lang="scss" scoped>
.v-phone-input__phone__input :deep(.v-input__append) {
  background-color: red;
}

.v-phone-input :deep(.v-messages__message.v-messages) {
  transition: none !important;
}
</style>
