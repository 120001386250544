import type { Jurisdiction } from '@/types/enums/Jurisdiction';
import { useI18n } from 'vue-i18n';

export function useTerms() {
  const { t, locale } = useI18n();

  // Define the links for each locale and jurisdiction
  const termsLinks = {
    en: {
      eidas: 'https://w3.swissdigicert.ch/TermsOfUse_Pers_EU-en.pdf',
      guest_ses:
        'https://www.deepcloud.swiss/legal/NB-DeepCloud-AG_DeepSign_EN.pdf',
      zertes: 'https://w3.swissdigicert.ch/TermsOfUse_Pers_CH-en.pdf'
    },
    de: {
      eidas: 'https://w3.swissdigicert.ch/TermsOfUse_Pers_EU-de.pdf',
      guest_ses:
        'https://www.deepcloud.swiss/legal/NB-DeepCloud-AG_DeepSign_DE.pdf',
      zertes: 'https://w3.swissdigicert.ch/TermsOfUse_Pers_CH-de.pdf'
    },
    fr: {
      eidas: 'https://w3.swissdigicert.ch/TermsOfUse_Pers_EU-en.pdf',
      guest_ses:
        'https://www.deepcloud.swiss/legal/NB-DeepCloud-AG_DeepSign_FR.pdf',
      zertes: 'https://w3.swissdigicert.ch/TermsOfUse_Pers_CH-fr.pdf'
    },
    it: {
      eidas: 'https://w3.swissdigicert.ch/TermsOfUse_Pers_EU-en.pdf',
      guest_ses:
        'https://www.deepcloud.swiss/legal/NB-DeepCloud-AG_DeepSign_IT.pdf',
      zertes: 'https://w3.swissdigicert.ch/TermsOfUse_Pers_CH-it.pdf'
    }
  };

  const noValidCompanyLinks = {
    de: 'https://www.deepsign.swiss/de/preise/',
    en: 'https://www.deepsign.swiss/en/pricing/',
    fr: 'https://www.deepsign.swiss/fr/tarifs/',
    it: 'https://www.deepsign.swiss/it/prezzi/'
  };

  // Function to get the translated terms based on jurisdiction and locale
  const getTranslatedTerms = (jurisdiction: Jurisdiction) => {
    const currentLocale = locale.value;
    const termsText = t(`terms.${jurisdiction}`);

    // Get the dynamic link based on locale and jurisdiction
    const link = termsLinks[currentLocale]?.[jurisdiction] || '';

    if (!link) {
      // Handle case where no link exists for the current locale/jurisdiction
      return termsText;
    }

    // Special handling for 'guest_ses' with two links
    if (jurisdiction === 'guest_ses') {
      const privacyLink =
        currentLocale === 'de'
          ? 'https://www.deepcloud.swiss/de/datenschutz/'
          : `https://www.deepcloud.swiss/${currentLocale}/privacy-policy/`;

      return termsText
        .replace(
          '_link_',
          `<a class="text-primary" target="_blank" href="${link}">${t('text.terms_and_conditions')}</a>`
        )
        .replace(
          '_privacy_',
          `<a class="text-primary" target="_blank" href="${privacyLink}">${t('text.privacy_policy')}</a>`
        );
    }

    // Default case
    return termsText
      .replace(
        '_link_',
        `<a class="text-primary" target="_blank" href="${link}">Swisscom GTC</a>`
      )
      .replace(
        '_countries_',
        `<a class="text-primary" target="_blank" href="https://www.deepcloud.swiss/registry-files/SupportedDocs-Deepcloud-AG_DeepID_DE.pdf">${t('text.countries')}</a>`
      );
  };

  const getTranslatedUpgradeAccount = () => {
    const currentLocale = locale.value;

    // Get the dynamic link based on locale
    const link = noValidCompanyLinks[currentLocale];
    const termsText = t('cards.no_valid_company_2');

    // Default case
    return termsText.replace(
      '_link_',
      `<a class="text-primary" target="_blank" href="${link}">${t('text.website')}</a>`
    );
  };

  const getAutographRequiredErrorMessage = () => {
    // Get the dynamic link based on locale
    const link = `${import.meta.env.VITE_DEEPADMIN_BASE_URL}account/signature`;
    const termsText = t('batch.error.sign.document.autograph.required');

    // Default case
    return termsText.replace(
      '_link_',
      `<a class="text-primary underline" target="_blank" href="${link}">${t('text.settings')}</a>`
    );
  };

  return {
    getTranslatedTerms,
    getTranslatedUpgradeAccount,
    getAutographRequiredErrorMessage
  };
}
