<template>
  <component :is="selectedLayout"></component>
  <DevSettingsDrawer v-if="isLocalOrDevEnv()" />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import LayoutDefault from './LayoutDefault.vue';
import LayoutSidebarLeft from '@/layouts/LayoutSidebarLeft.vue';
import LayoutSidebarRight from '@/layouts/LayoutSidebarRight.vue';
import DocumentView from '@/layouts/DocumentView.vue';
import { useRoute } from 'vue-router';
import { isLocalOrDevEnv } from '@/utils/env.ts';

const route = useRoute();

const layoutMap = {
  LayoutSidebarLeft,
  LayoutSidebarRight,
  LayoutDefault,
  DocumentView
};

const selectedLayout = computed(() => {
  return (
    layoutMap[route.meta.layout as keyof typeof layoutMap] || LayoutDefault
  );
});
</script>
