<template>
  <div ref="root" class="h-10 w-full" role="presentation"></div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';

const emit = defineEmits(['load-more']);

const root = ref();

const observer = new IntersectionObserver(
  (entries: IntersectionObserverEntry[]) => {
    const entry = entries[0];
    if (entry.isIntersecting) {
      emit('load-more');
    }
  }
);

onMounted(() => {
  observer.observe(root.value);
});

onBeforeUnmount(() => {
  observer.disconnect();
});
</script>
