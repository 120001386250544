<template>
  <VApp>
    <Header />
    <VNavigationDrawer
      v-model="isOpen"
      :temporary="mobile"
      :permanent="!mobile"
    >
      <RouterView name="sidebar"></RouterView>
    </VNavigationDrawer>
    <VMain>
      <RouterView />
    </VMain>
  </VApp>
</template>

<script setup lang="ts">
import { ref, watchEffect } from 'vue';

import { useDisplay } from 'vuetify';

const { mobile } = useDisplay();

watchEffect(() => {
  if (mobile.value) {
    isOpen.value = false;
  }
});

const isOpen = ref(true);
</script>
