<template>
  <div class="flex justify-between gap-2 pb-4 md:pb-5">
    <SearchBar class="min-w-0 lg:flex-grow-1" />
    <div
      class="flex gap-1"
      :class="{ 'opacity-30 pointer-events-none': disabled }"
    >
      <VBtn
        id="grid-switcher"
        class="view-switcher-btn"
        icon="fa-regular fa-grid-2"
        value="grid"
        :data-test-id="`grid-view`"
        size="small"
        :active="viewOption === 'grid'"
        rounded="lg"
        @click="handleViewChange('grid')"
      ></VBtn>
      <VBtn
        class="view-switcher-btn"
        icon="fa-solid fa-bars"
        value="list"
        :data-test-id="`list-view`"
        size="small"
        :active="viewOption === 'list'"
        rounded="lg"
        @click="handleViewChange('list')"
      ></VBtn>
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePreferencesStore } from '@/stores/deepadmin/preferences.ts';
import { onBeforeMount, ref } from 'vue';
import { useEmitter } from '@/composables/useEmitter';

const preferencesStore = usePreferencesStore();
const emitter = useEmitter();
defineProps({
  disabled: {
    type: Boolean,
    default: false
  }
});

const viewOption = ref('grid');

function handleViewChange(newValue: 'grid' | 'list') {
  preferencesStore.setPreference('viewOption', newValue);
  viewOption.value = newValue;
  emitter.$emit('change-view-preference', newValue);
}

onBeforeMount(() => {
  viewOption.value =
    (preferencesStore!.preferences?.viewOption as string) || 'grid';
  emitter.$emit('change-view-preference', viewOption.value);
});
</script>

<style lang="scss" scoped>
.view-switcher-btn.v-btn--active :deep(.v-btn__overlay) {
  @apply opacity-0;
}

.view-switcher-btn.v-btn--active {
  @apply bg-blue-400/15 text-primary;
}
</style>
