import type { AxiosRequestConfig } from 'axios';
import deepSignApi from '@/api/deepsign/deepsignApi';

export const showSignOrder = (id: string, config?: AxiosRequestConfig) =>
  deepSignApi.get(`documents/${id}/sign-order`, config);

export const updateSignOrder = (
  id: string,
  data?: string[][],
  config?: AxiosRequestConfig
) => deepSignApi.put(`documents/${id}/sign-order`, data, config);

export const destroySignOrder = (id: string, config?: AxiosRequestConfig) =>
  deepSignApi.delete(`documents/${id}/sign-order`, config);
