<template>
  <v-expansion-panel ref="attachmentPreview" variant="accordion" value="true">
    <v-expansion-panel-title class="font-medium expansion-panel-title">
      {{ i18n.t('titles.attachments') }}
      <template #actions="{ expanded }">
        <VIcon :size="12" :class="{ 'rotate-180': expanded }">
          fa-regular fa-chevron-down
        </VIcon>
      </template>
    </v-expansion-panel-title>
    <v-expansion-panel-text class="signees-panel-content">
      <div class="grid gap-4" style="width: 100%">
        <div class="files-container">
          <UploadedAttachment
            v-for="(file, index) in attachments"
            :key="index"
            :file="file"
            attachment-type="attachment"
            @download-file="handleDownloadFile"
            @preview-file="handlePreviewFile"
          />
        </div>
      </div>
    </v-expansion-panel-text>
  </v-expansion-panel>
  <PdfPreviewDialog @download-file="handleDownloadFile" />
</template>

<script setup lang="ts">
import UploadedAttachment from '@/components/items/UploadedAttachment.vue';
import { ref, type PropType } from 'vue';
import type { AttachmentFile } from '@/types/Attachment';
import globalAxios from '@/composables/useAxios';
import { useDocumentContext } from '@/composables/useDocumentContext';
import { downloadFile } from '@/composables/useUtils';
import { useEmitter } from '@/composables/useEmitter';
import { useI18n } from 'vue-i18n';
import { useSignContext } from '@/composables/useSignContext';
import authService from '@/services/auth-service';
import publicAxios from '@/plugins/publicAxios';

const apiBaseUrl = import.meta.env.VITE_DEEPSIGN_API_BASE_URL;
const { currentDocument } = useDocumentContext();
const emitter = useEmitter();
const i18n = useI18n();
const { signKey } = useSignContext();
const attachmentPreview = ref();

defineProps({
  attachments: {
    type: Array as PropType<AttachmentFile[]>,
    default: () => []
  }
});

const previewFile = ref();

async function fetchFileUrl(file: AttachmentFile): Promise<string | null> {
  let url = '';

  if (authService.isAuthenticated) {
    url = signKey.value
      ? `${apiBaseUrl}sign/${signKey.value}/attachments/${file.attachmentId}/download-url`
      : `${apiBaseUrl}documents/${currentDocument.value?.documentId}/attachments/${file.attachmentId}/download-url`;
    const response = await globalAxios.get(url);
    return response.data || null;
  } else {
    url = `${apiBaseUrl}p/sign/${signKey.value}/attachments/${file.attachmentId}/download-url`;
    const response = await publicAxios.get(url);
    return response.data || null;
  }
}

async function handleDownloadFile(file: AttachmentFile) {
  try {
    const fileUrl = await fetchFileUrl(file);
    if (fileUrl && file.name) {
      downloadFile(fileUrl, file.name);
    }
  } catch (error) {
    console.error('Error downloading attachment:', error);
  }
}

async function handlePreviewFile(file: AttachmentFile) {
  try {
    const fileUrl = await fetchFileUrl(file);
    if (fileUrl && file.name) {
      if (previewFile.value !== fileUrl) {
        previewFile.value = fileUrl;
      }
      emitter.$emit('show-pdf-preview-dialog', {
        file,
        documentUrl: previewFile.value
      });
    }
  } catch (error) {
    console.error('Error downloading attachment:', error);
  }
}
</script>

<style lang="scss" scoped>
.expansion-panel-title {
  @apply py-0;
  min-height: 40px !important;
}

.signees-panel-content :deep(.v-expansion-panel-text__wrapper) {
  @apply py-1;
}
</style>
