<template>
  <div class="loader">
    <svg
      id="Layer_1"
      height="40px"
      viewBox="0 0 24 30"
      width="30px"
      x="0px"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      y="0px"
    >
      <rect height="10" opacity="0.2" width="4" x="0" y="10">
        <animate
          attributeName="opacity"
          attributeType="XML"
          begin="0s"
          dur="1s"
          repeatCount="indefinite"
          values="0.2; 1; .2"
        />
        <animate
          attributeName="height"
          attributeType="XML"
          begin="0s"
          dur="1s"
          repeatCount="indefinite"
          values="10; 20; 10"
        />
        <animate
          attributeName="y"
          attributeType="XML"
          begin="0s"
          dur="1s"
          repeatCount="indefinite"
          values="10; 5; 10"
        />
      </rect>
      <rect height="10" opacity="0.2" width="4" x="8" y="10">
        <animate
          attributeName="opacity"
          attributeType="XML"
          begin="0.15s"
          dur="1s"
          repeatCount="indefinite"
          values="0.2; 1; .2"
        />
        <animate
          attributeName="height"
          attributeType="XML"
          begin="0.15s"
          dur="1s"
          repeatCount="indefinite"
          values="10; 20; 10"
        />
        <animate
          attributeName="y"
          attributeType="XML"
          begin="0.15s"
          dur="1s"
          repeatCount="indefinite"
          values="10; 5; 10"
        />
      </rect>
      <rect height="10" opacity="0.2" width="4" x="16" y="10">
        <animate
          attributeName="opacity"
          attributeType="XML"
          begin="0.3s"
          dur="1s"
          repeatCount="indefinite"
          values="0.2; 1; .2"
        />
        <animate
          attributeName="height"
          attributeType="XML"
          begin="0.3s"
          dur="1s"
          repeatCount="indefinite"
          values="10; 20; 10"
        />
        <animate
          attributeName="y"
          attributeType="XML"
          begin="0.3s"
          dur="1s"
          repeatCount="indefinite"
          values="10; 5; 10"
        />
      </rect>
    </svg>
  </div>
</template>

<style scoped lang="scss">
@import '@/styles/core/colors';

svg path,
svg rect {
  fill: $primary-color;
}

.loader {
  display: grid;
  height: 100%;
  align-items: center;
  justify-content: center;
}
</style>
