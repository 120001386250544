<template>
  <div class="d-flex flex-column">
    <v-img
      :src="`/svg/authority-services/${authorityService}-logo.svg`"
      height="24"
      class="mb-3"
    />
    <div class="successful-verified-title">
      {{ i18n.t('titles.successfully_verified') }}
    </div>
    <v-card class="successful-verified-card" flat rounded="lg">
      <v-col class="d-flex flex-column pa-0 align-center">
        <v-icon color="success" size="44"> fas fa-circle-check</v-icon>
        <div class="mt-3 deep-caption text-center">
          <div class="text-hint-message">
            {{ i18n.t(`authorities.${authorityService}_can_sign`) }}
          </div>
        </div>
      </v-col>
    </v-card>
    <!-- ACTIONS -->
    <DeepButton
      :action="closeDialog"
      class="mt-1"
      variant="outlined"
      :text="i18n.t('buttons.close')"
    />
    <!-- /ACTIONS -->
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

defineProps({
  authorityService: {
    type: String,
    default: ''
  }
});

const i18n = useI18n();

const emit = defineEmits(['close-dialog']);

function closeDialog() {
  emit('close-dialog');
}
</script>

<style scoped lang="scss">
@import '@/styles/core/colors';

.successful-verified-card {
  background-color: $grey-lighten-4;
  padding: 28px !important;
  margin-bottom: 24px;
}

.text-hint-message {
  max-width: 200px !important;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.successful-verified-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: #000000;
  margin-bottom: 28px;
}
</style>
