export function addHrefSchemes(string: string): string {
  let result: string = string;
  const links: string = extractText(result, 'href="', '"');

  for (const link of links) {
    if (link[1].startsWith('mailto:')) {
      return result;
    } else {
      result = result.replaceAll(link[0], `href="${setHttp(`${link[1]}"`)}`);
    }
  }

  return result;
}

export function setHttp(link: string): string {
  let linkUrl: string = link;
  if (!linkUrl.startsWith('https://') && !linkUrl.startsWith('http://')) {
    linkUrl = 'https://' + linkUrl;
  }
  return linkUrl;
}

export function extractText(
  strToParse: string,
  strStart: string,
  strFinish: string
): string {
  return [...strToParse.matchAll(strStart + '(.*?)' + strFinish)];
}

export function formatPhoneNumber(phoneNumberString: string): string {
  if (phoneNumberString.substring(0, 3) === '+41') {
    const internationalCallSign: string = phoneNumberString.slice(0, 3);
    const operatorSign: string = phoneNumberString.slice(3, 5);
    const threeDigits: string = phoneNumberString.slice(5, 8);
    const twoDigits: string = phoneNumberString.slice(8, 10);
    const lastTwoDigits: string = phoneNumberString.slice(10, 12);
    return `${internationalCallSign} ${operatorSign} ${threeDigits} ${twoDigits} ${lastTwoDigits}`;
  }
  return phoneNumberString;
}

export function clamp(num: number, min: number, max: number) {
  return Math.min(Math.max(num, min), max);
}
