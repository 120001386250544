<template>
  <v-dialog
    v-model="showDialog"
    width="600"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card class="pa-6" rounded="lg">
      <!-- TITLE -->
      <v-card-title class="justify-center py-0">
        <h5 class="card-title">
          {{ i18n.t('titles.upload_document') }}
        </h5>
      </v-card-title>
      <!-- /TITLE -->
      <DeepSignAlert v-if="showAlert" error class="text-red-500">
        <div class="flex flex-row align-center">
          <VIcon :size="32" class="mr-4">
            fa-regular fa-circle-exclamation
          </VIcon>
          <span>{{ showAlertMessage }}</span>
        </div>
      </DeepSignAlert>

      <!-- USER HAS VALID ORGANIZATION -->
      <div v-if="filteredOrganizations.length > 0">
        <v-card-text class="text-center px-0 card-body">
          <div
            class="flex items-center justify-center mb-4 upload-zone"
            :class="{
              dragover: hover,
              'justify-center': uploadedFiles.length === 1,
              multiple: uploadedFiles.length > 1,
              disabled: uploadStarted && uploadedFiles.length > 1
            }"
            @click="onCardClick"
          >
            <div class="w-full overflow-y-auto h-[170px] max-h-[170px]">
              <input
                ref="fileInput"
                :accept="acceptedExtensions"
                multiple
                class="fileInput"
                type="file"
                :disabled="uploadStarted"
                data-test-id="file-input"
                @input="upload"
              />
              <div
                v-if="uploadedFiles.length === 0"
                class="flex flex-col items-center justify-center h-full"
              >
                <VIcon :size="32" class="mx-auto mb-3 text-primary/85">
                  fa-regular fa-file-plus
                </VIcon>
                <div class="mx-2">
                  <div class="deep-footnote deep-link">
                    {{ i18n.t('texts.drag_drop_or') }}
                    <a class="!text-primary">{{
                      i18n.t('texts.upload_file')
                    }}</a>
                  </div>
                  <div class="text-caption">
                    {{ i18n.t('texts.document_size_limit') }}
                  </div>
                </div>
              </div>

              <div v-else-if="uploadedFiles.length === 1" class="h-full">
                <div
                  class="flex flex-col items-center justify-center h-full deep-callout"
                >
                  <div
                    v-if="uploadedFiles[0].file"
                    class="text-wrap px-2"
                    style="max-width: 375px"
                    data-test-id="uploaded-file"
                  >
                    {{ uploadedFiles[0].file.name }}
                  </div>

                  <div class="d-flex flex-row justify-center hint-message">
                    <div v-if="uploadedFiles[0].file">
                      {{ humanFileSize(uploadedFiles[0].file.size, 0) }}
                    </div>

                    <v-divider class="mx-3 upload-divider" vertical />
                    <div
                      v-if="uploadedFiles[0]"
                      class="remove-button"
                      @click.stop="
                        uploadedFiles[0].file
                          ? removeFileAndReset(uploadedFiles[0])
                          : undefined
                      "
                    >
                      {{ i18n.t('buttons.remove') }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="h-[170px] max-h-[170px] overflow-y-auto">
                <UploadedFile
                  v-for="file in uploadedFiles"
                  :key="file.key"
                  class="w-full text-left pl-2"
                  :file="file"
                  @remove-uploaded-file="removeFile"
                />
              </div>
            </div>
          </div>
          <DeepSignAlert
            v-if="
              successfullyUploadedFiles.length >= 1 && uploadedFiles.length > 1
            "
            class="mb-1"
            success
          >
            {{
              isUploadingSeals
                ? i18n.t('aggregated.seals_uploaded', {
                    count: successfullyUploadedFiles.length,
                    total: uploadedFiles.length
                  })
                : i18n.t('aggregated.uploaded', {
                    count: successfullyUploadedFiles.length,
                    total: uploadedFiles.length
                  })
            }}
          </DeepSignAlert>

          <DeepSignAlert
            v-if="uploadedFiles.length > 1 && errorsCount.length > 0"
            warning
          >
            <div
              v-for="errorCount in errorsCount"
              :key="errorCount.apiError"
              class="text-left text-xs mb-1"
            >
              {{
                i18n.t(
                  `errors.aggregated.${errorCount.apiError}`,
                  errorCount.count,
                  {
                    count: errorCount.count,
                    total: uploadedFiles.length
                  }
                )
              }}
            </div>
          </DeepSignAlert>

          <a
            v-if="uploadedFiles.length === 0"
            v-show="filteredOrganizations.length > 0"
            class="no-decoration"
            :href="baseURL"
          >
            <div
              class="d-flex flex-row align-center justify-center choose-deepbox mb-6"
            >
              {{ i18n.t('texts.choose_from') }}
              <img
                alt="deepbox logo"
                class="deepbox-logo ml-1"
                height="16px"
                src="/svg/deepbox_logo_black.svg"
                width="66px"
              />
              <VIcon :size="10" class="mt-0.5 ml-0.5">
                fa-solid fa-chevron-right
              </VIcon>
            </div>
          </a>

          <div v-else>
            <v-autocomplete
              v-show="
                filteredOrganizationsWithoutHeaders.length > 1 && !uploadStarted
              "
              v-model="selectedOrganization"
              variant="outlined"
              class="mb-6"
              :hide-details="true"
              item-title="display_name"
              :items="filteredOrganizations"
              return-object
              :placeholder="i18n.t('texts.select_organization')"
              data-test-id="organization-select"
              @keydown.backspace="() => (selectedOrganization = undefined)"
              @update:model-value="onOrgSelect"
            >
              <template #selection="{ item }">
                <organization-item :item="item.raw" />
              </template>
              <template #item="{ item, props }">
                <v-list-item-subtitle
                  v-if="item.raw.type === 'subheader'"
                  class="m-3"
                >
                  {{ item.raw.title }}
                </v-list-item-subtitle>
                <v-list-item v-else v-bind="props" title="">
                  <organization-item
                    class="w-full flex"
                    :active="item.raw.group_id === selectedOrganization"
                    :item="item.raw"
                    data-test-id="organization-select-item"
                  />
                </v-list-item>
              </template>
            </v-autocomplete>
          </div>
        </v-card-text>

        <!-- Only shown when the seal feature is available and the use uploaded only 1 file -->
        <div v-if="canSeal && isSealsLoading" class="flex justify-center">
          <v-btn :loading="isSealsLoading"></v-btn>
        </div>
        <div
          v-if="
            !isSealsLoading &&
            !uploadStarted &&
            !addDocumentsFromFilesPending &&
            seals.length > 0
          "
          class="flex items-center justify-between"
        >
          <SealDocumentButton
            class="mb-3 col-span-1 flex justify-center items-center mb-3 my-auto w-[40%]"
            :disabled="createDisabled || isLoading"
            :loading="addDocumentsFromFilesPending"
            :organization-id="organizationId"
            :uploaded-files="uploadedFiles"
            :block="false"
            @close-dialog="closeDialogAfterSealDocuments"
            @click="isUploadingSeals = true"
          />
          <span
            class="text-gray-400 mb-3 px-2 flex items-center justify-center"
          >
            {{ i18n.t('actions.or') }}
          </span>
          <DeepButton
            :action="createDocuments"
            class="col-span-1 flex justify-center items-center mb-3 my-auto w-[40%]"
            :disabled="createDisabled || isLoading"
            :loading="addDocumentsFromFilesPending"
            :text="i18n.t('buttons.sign')"
            data-test-id="dialog-continue-button"
            :block="false"
          />
        </div>

        <DeepButton
          v-else-if="
            !isSealsLoading &&
            !uploadStarted &&
            !addDocumentsFromFilesPending &&
            seals.length === 0 &&
            uploadedFiles.length >= 1
          "
          :action="createDocuments"
          class="mb-3"
          :disabled="createDisabled || isLoading"
          :loading="addDocumentsFromFilesPending"
          :text="
            i18n.t(
              uploadedFiles.length <= 1 ? 'buttons.continue' : 'buttons.upload'
            )
          "
          data-test-id="dialog-continue-button"
        />
        <DeepButton
          v-if="
            uploadStarted &&
            successfullyUploadedFiles.length === 0 &&
            errorsCount.length > 1 &&
            !addDocumentsFromFilesPending
          "
          :action="clear"
          :text="i18n.t('buttons.clear')"
        />
        <DeepButton
          :action="closeDialog"
          :disabled="isLoading"
          :loading="addDocumentsFromFilesPending"
          class="mb-3"
          variant="outlined"
          :text="i18n.t(uploadStarted ? 'buttons.close' : 'buttons.cancel')"
          data-test-id="dialog-cancel-button"
        />
      </div>
      <!-- /USER HAS VALID ORGANIZATION -->

      <!-- NO VALID ORGANIZATION -->
      <div v-else>
        <v-card-text class="grid w-full text-center mb-4">
          {{ i18n.t('cards.no_valid_company') }}
          <br />
          <br />
          <span v-html="getTranslatedUpgradeAccount()"></span>

          <div v-show="organizations.length > 1" class="mt-6">
            <OrganizationSelector
              v-model="selectedOrganization"
              class="mt-2 px-4"
            />
          </div>
        </v-card-text>
        <v-card-actions class="px-6 pb-5">
          <div class="d-flex flex-column w-full">
            <DeepButton
              :disabled="!selectedOrganization && organizations.length > 1"
              :action="goToOnboarding"
              color="primary"
              variant="flat"
              :text="i18n.t('buttons.continue')"
            />
            <DeepButton
              :action="closeDialog"
              class="mt-3 bg-white"
              variant="outlined"
              :text="i18n.t('buttons.cancel')"
            />
          </div>
        </v-card-actions>
      </div>
      <!-- NO VALID ORGANIZATION -->
      <div v-show="hover" class="drop-overlay"></div>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { constants } from '@/constants/constants';
import { VerificationState } from '@/types/enums/VerificationState';
import { useDeepAdminStore } from '@/stores/deepadmin/deepadmin';
import { useDocumentStore } from '@/stores/document/document';
import { computed, onUnmounted, ref, watch, watchEffect } from 'vue';
import { useEmitter } from '@/composables/useEmitter';
import { useRoute, useRouter } from 'vue-router';
import { getAllFileEntries, humanFileSize } from '@/composables/useUtils';
import { useI18n } from 'vue-i18n';
import { UploadStatus } from '@/types/enums/UploadStatus';
import { v4 as uuid } from 'uuid';
import { useMessageStore } from '@/stores/message/message';
import type { Company, OrganizationHeader } from '@/types/deepadmin/Company';
import { getCompanySeals } from '@/api/deepsign/users-me-seal';
import { getCompanySettings } from '@/api/deepsign/company-settings';
import { useTerms } from '@/composables/useTerms';
import type { CompanySettings } from '@/types/deepsign/CompanySettings';

const emit = defineEmits(['done']);

interface FileType {
  key: string;
  file?: File;
  isTooLarge: boolean;
  apiError?: string;
  status: UploadStatus;
}

const deepAdminStore = useDeepAdminStore();
const documentStore = useDocumentStore();
const messageStore = useMessageStore();

const { getTranslatedUpgradeAccount } = useTerms();
const emitter = useEmitter();
const i18n = useI18n();

const showDialog = ref(false);
const acceptedExtensions = constants.SUPPORTED_DOCUMENT_TYPES.join(', ');
const hover = ref(false);
const uploadedFiles = ref<FileType[]>([]);
const selectedOrganization = ref<Company>();
const maxFileSizeMB = ref(40);
const uploadStarted = ref(false);
const addDocumentsFromFilesPending = ref(false);
const router = useRouter();
const route = useRoute();
const fileInput = ref();
const seals = ref([]);
const isSealsLoading = ref(false);
const isUploadingSeals = ref(false);
const companySettings = ref<CompanySettings>();

const baseURL = import.meta.env.VITE_DEEPBOX_FRONTEND_BASE_URL;

const showAlert = computed(() => {
  return uploadedFiles.value.some((file) => {
    return (
      file.isTooLarge ||
      file.status === UploadStatus.UNSUPPORTED ||
      (file.status === UploadStatus.ERROR && uploadedFiles.value.length === 1)
    );
  });
});

const showAlertMessage = computed(() => {
  if (uploadedFiles.value.length !== 1) {
    return i18n.t('texts.unsupported_file_format_or_too_large');
  } else if (uploadedFiles.value[0].isTooLarge) {
    return i18n.t('texts.file_too_large');
  } else if (uploadedFiles.value[0].status === UploadStatus.UNSUPPORTED) {
    return i18n.t('texts.unsupported_file_format');
  } else {
    return i18n.t(`errors.api.${uploadedFiles.value[0].apiError}`);
  }
});

const createDisabled = computed(() => {
  return (
    uploadedFiles.value.length === 0 ||
    organizationId.value === null ||
    showAlert.value
  );
});

const filteredOrganizations = computed<(Company | OrganizationHeader)[]>(() => {
  if (deepAdminStore.userInfo?.companies) {
    const orgs = deepAdminStore.userInfo?.companies.filter((organization) => {
      return (
        organization.verification_state !== VerificationState.NONE &&
        organization.structure !== 'personal'
      );
    });

    orgs.sort((a, b) => a.display_name.localeCompare(b.display_name));

    const personalSpace = deepAdminStore.userInfo?.companies.find(
      (organization) => {
        return (
          organization.verification_state !== VerificationState.NONE &&
          organization.structure === 'personal'
        );
      }
    );

    let availableOrgs: (Company | OrganizationHeader)[] = [];

    if (orgs.length > 0) {
      availableOrgs.push({
        title: String(i18n.t('labels.organizations')),
        type: 'subheader'
      });
      availableOrgs = [...availableOrgs, ...orgs];
    }

    if (personalSpace) {
      availableOrgs = [
        ...availableOrgs,
        {
          title: String(i18n.t('labels.personal')),
          type: 'subheader'
        },
        { ...personalSpace }
      ];
    }
    return availableOrgs;
  } else {
    return [];
  }
});

const filteredOrganizationsWithoutHeaders = computed(() => {
  return filteredOrganizations.value.filter((org) => !org.type);
});

const organizations = computed(() => {
  if (deepAdminStore.userInfo?.companies) {
    return deepAdminStore.userInfo?.companies.filter((organization) => {
      return organization.structure !== 'personal';
    });
  } else {
    return [];
  }
});

const defaultOrganization = computed(() => {
  const organizations = filteredOrganizationsWithoutHeaders.value as Company[];
  if (organizations.length > 1) {
    return selectedOrganization.value;
  } else if (organizations.length === 1) {
    return organizations[0];
  } else {
    return undefined;
  }
});

const canSeal = computed(() => {
  if (!defaultOrganization.value) return false;
  return defaultOrganization.value?.metadata.beta_features?.includes(
    'deepsign_seals'
  );
});

const organizationId = computed(() => {
  const organizations = filteredOrganizationsWithoutHeaders.value as Company[];
  if (organizations.length > 1) {
    return selectedOrganization.value?.group_id || null;
  } else if (organizations.length === 1) {
    return organizations[0]?.group_id;
  } else {
    return null;
  }
});

const successfullyUploadedFiles = computed(() => {
  return uploadedFiles.value.filter(
    (file) => file.status === UploadStatus.UPLOADED
  );
});

const errorsCount = computed(() => {
  const apiErrorCounts = uploadedFiles.value.reduce((map, { apiError }) => {
    if (apiError !== undefined) {
      map.set(apiError, (map.get(apiError) || 0) + 1);
    }
    return map;
  }, new Map());

  return Array.from(apiErrorCounts, ([apiError, count]) => ({
    apiError,
    count
  }));
});

const isLoading = computed(() => {
  return uploadedFiles.value.some(
    (file) => file.status === UploadStatus.PROGRESS
  );
});

emitter.$on('open-new-document-dialog', openDialog);
emitter.$on('close-new-document-dialog', closeDialog);
deepAdminStore.fetchUserInfo();

watchEffect(async () => {
  if (!selectedOrganization.value) seals.value = [];
  if (filteredOrganizationsWithoutHeaders.value.length === 1) {
    isSealsLoading.value = true;
    seals.value = [];
    try {
      const response = await getCompanySeals({
        filterCompanyId: filteredOrganizationsWithoutHeaders.value.group_id
      });
      seals.value = response.data;
    } catch (e) {
      seals.value = [];
      return e;
    } finally {
      isSealsLoading.value = false;
    }
  }
});

onUnmounted(() => {
  emitter.$off('open-new-document-dialog', openDialog);
});

function openDialog() {
  showDialog.value = true;
  uploadStarted.value = false;
  window.addEventListener('dragenter', dragenter);
  window.addEventListener('dragover', dragover);
  window.addEventListener('dragleave', dragleave);
  window.addEventListener('drop', drop);
}

function closeDialog() {
  window.removeEventListener('dragenter', dragenter);
  window.removeEventListener('dragover', dragover);
  window.removeEventListener('dragleave', dragleave);
  window.removeEventListener('drop', drop);
  if (fileInput.value) fileInput.value.value = null;
  uploadedFiles.value = [];
  selectedOrganization.value = undefined;
  addDocumentsFromFilesPending.value = false;
  showDialog.value = false;
}

async function closeDialogAfterSealDocuments(newUploadedFiles: FileType[]) {
  window.removeEventListener('dragenter', dragenter);
  window.removeEventListener('dragover', dragover);
  window.removeEventListener('dragleave', dragleave);
  window.removeEventListener('drop', drop);
  isUploadingSeals.value = false;
  if (fileInput.value) fileInput.value.value = null;
  showDialog.value = false;
  uploadedFiles.value = [];
  selectedOrganization.value = undefined;

  //If multiple files were uploaded
  if (newUploadedFiles.length > 1) {
    emit('done');
    router.push({
      query: {
        ...route.query,
        filterDocumentStatus: ['in-progress'],
        filterSignStatus: ['pending']
      }
    });

    messageStore.showMessage({
      text: i18n.t('snackbars.seals.documents_created', {
        number: newUploadedFiles.length
      }),
      color: 'success',
      icon: 'fa fa-circle-check'
    });
  }
}

function dragenter(event: DragEvent) {
  event.preventDefault();
  hover.value = true;
}

function dragleave(event: DragEvent) {
  event.preventDefault();
  hover.value = false;
}

function dragover(event: DragEvent) {
  event.preventDefault();
  hover.value = true;
}

function upload(event: Event) {
  const input = event.target as HTMLInputElement;
  const files = input?.files;
  // no selected file
  if (files && !files[0]) {
    return;
  }

  // selected files
  if (files) {
    uploadStarted.value = false;
    const filesArray: File[] = Array.from(files);
    filesArray?.forEach((file) => {
      let status = UploadStatus.READY;
      if (!constants.SUPPORTED_DOCUMENT_TYPES.includes(file.type)) {
        status = UploadStatus.UNSUPPORTED;
      }
      uploadedFiles.value.push({
        key: uuid(),
        file: file,
        isTooLarge: isTooLarge(file),
        status: status
      });
    });
  }

  fileInput.value.value = null;
}

async function drop(event: DragEvent) {
  event.preventDefault();
  hover.value = false;
  uploadStarted.value = false;
  const entries = await getAllFileEntries(event);
  entries.files.forEach((file) => {
    let status = UploadStatus.READY;
    if (
      file.file?.type &&
      !constants.SUPPORTED_DOCUMENT_TYPES.includes(file.file?.type)
    ) {
      status = UploadStatus.UNSUPPORTED;
    }
    uploadedFiles.value.push({
      key: uuid(),
      file: file.file ? file.file : undefined,
      isTooLarge: file.file ? isTooLarge(file.file) : true,
      status: status
    });
  });
}

function removeFile(file: FileType) {
  uploadedFiles.value = uploadedFiles.value.filter((uf) => uf.key !== file.key);
}

function removeFileAndReset(file: FileType) {
  removeFile(file);
  uploadStarted.value = false;
}

function clear() {
  uploadedFiles.value = [];
  uploadStarted.value = false;
}

async function createDocuments() {
  addDocumentsFromFilesPending.value = true;
  uploadStarted.value = true;
  const promises = [];
  for (const uploadedFile of uploadedFiles.value) {
    uploadedFile.status = UploadStatus.PROGRESS;
    if (uploadedFile.file && organizationId.value) {
      promises.push(
        documentStore
          .createDocumentFromFile(
            uploadedFile.file,
            organizationId.value,
            companySettings.value
          )
          .then(() => {
            uploadedFile.status = UploadStatus.UPLOADED;
            if (uploadedFiles.value.length === 1) {
              if (route.name !== 'document-validate-new') {
                router.push(
                  `document/${documentStore.document?.documentId}/create`
                );
              }
              closeDialog();
              documentStore.document = undefined;
            }
          })
          .catch((error) => {
            uploadedFile.status = UploadStatus.ERROR;
            addDocumentsFromFilesPending.value = false;
            if (
              constants.DEEPSIGN_API_HANDLED_ERRORS.includes(
                error.response.data.messageId
              )
            ) {
              uploadedFile.apiError = error.response.data.messageId;
            } else {
              console.error('Error creating document', error);
            }
          })
      );
    }
  }
  await Promise.all(promises);

  if (successfullyUploadedFiles.value.length >= 1) {
    emit('done');

    router.push({
      query: {
        ...route.query,
        filterDocumentStatus: ['draft'],
        filterSignStatus: undefined
      }
    });

    messageStore.showMessage({
      text: i18n.t('snackbars.documents_created', {
        number: successfullyUploadedFiles.value.length
      }),
      color: 'success',
      icon: 'fa fa-circle-check'
    });
  }

  if (uploadedFiles.value.some((file) => file.status === UploadStatus.ERROR)) {
    return;
  } else {
    closeDialog();
  }
}

function isTooLarge(file: File) {
  return Number((file.size / 1000000).toFixed(1)) > maxFileSizeMB.value;
}

function goToOnboarding() {
  let url;
  if (
    deepAdminStore.userInfo?.companies.length === 1 &&
    deepAdminStore.userInfo?.companies[0].structure === 'personal'
  ) {
    url = `${
      import.meta.env.VITE_ONBOARDING_FRONTEND_BASE_URL
    }account?service=deepsign`;
  } else {
    if (!selectedOrganization.value) {
      selectedOrganization.value = organizations.value[0];
    }
    if (selectedOrganization.value) {
      url = `${
        import.meta.env.VITE_ONBOARDING_FRONTEND_BASE_URL
      }account/verify/${selectedOrganization.value.group_id}`;
    }
  }

  window.open(url, '_self');
}

function onCardClick() {
  fileInput.value.click();
}

async function onOrgSelect(event: { group_id?: string }) {
  isSealsLoading.value = true;
  seals.value = [];
  companySettings.value = undefined; // initialize companySettings before fetching
  try {
    if (event.group_id) {
      const sealsResponse = await getCompanySeals({
        filterCompanyId: event.group_id
      });
      seals.value = sealsResponse.data;
    } else {
      seals.value = [];
    }
  } catch (e) {
    seals.value = [];
    return e;
  } finally {
    isSealsLoading.value = false;
  }
}

watch(
  () => defaultOrganization.value,
  async (newOrg) => {
    if (newOrg && newOrg.group_id) {
      const settingsResponse = await getCompanySettings(newOrg.group_id);
      companySettings.value = settingsResponse.data;
    }
  },
  {
    immediate: true,
    deep: true
  }
);
</script>

<style lang="scss" scoped>
@import '@/styles/core/colors';

.v-card-actions .v-btn ~ .v-btn:not(.v-btn-toggle .v-btn) {
  margin-inline-start: 0;
  background-color: rgb(245, 245, 245);
}

.dialog-container {
  padding: 60px 82px;
}

.card-title {
  word-break: break-word;
  font-weight: 600;
  font-size: 24px !important;
  text-align: center;
  margin: 0 0 30px;
  white-space: normal;
}

.instructions {
  margin-bottom: 90px;
  text-align: center;
}

.mobile-title {
  margin-left: 0;
  margin-right: 0;
}

.mobile-instructions {
  margin-bottom: 45px;
}

.create-document-dialog {
  overflow-x: hidden;
  border-radius: 12px;
}

.deep-card-title {
  padding: 21px 24px !important;
}

.upload-zone {
  @apply border-gray-400;
  height: 172px;
  border-radius: 8px;
  max-height: 172px;
  overflow-y: auto;
  overflow-x: hidden;
  &.multiple {
    height: auto;
  }

  &:hover {
    cursor: pointer;
  }

  &.disabled:hover {
    cursor: default;
  }
}

.file-plus-icon {
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 34px;
  color: $primary-color;
}

.deep-footnote {
  color: $black-text;
  margin-bottom: 8px;

  a {
    text-decoration: none;
  }
}

input.fileInput {
  display: none;
}

.dragover {
  background-color: rgba(93, 74, 198, 0.05);
}

.upload-divider {
  margin-top: 2px;
  min-height: 75%;
}

.hint-message {
  margin-top: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #8e8e93;
}

.deep-callout {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: $black-text;
}

.deepbox-logo {
  margin-top: 2px;
}

.choose-deepbox {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}

.remove-button {
  &:hover {
    cursor: pointer !important;
    color: $primary-color !important;
  }
}

.unsupported-file-warning {
  margin-top: 30px;
  border-radius: 10px !important;
  height: 52px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.drop-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
</style>
