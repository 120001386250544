import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { AxiosError } from 'axios';

export const useErrorStore = defineStore('error', () => {
  const isError = ref(false);
  const error = ref<AxiosError>();
  const backUrl = ref<string>();
  const errorType = ref<'document_not_found' | 'other'>();

  function create(errorPassed: AxiosError) {
    isError.value = true;
    error.value = errorPassed;
  }

  function clear() {
    isError.value = false;
    error.value = undefined;
  }

  return {
    // State
    isError,
    error,
    backUrl,
    errorType,

    // Actions
    create,
    clear
  };
});
