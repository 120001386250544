<template>
  <svg
    fill="none"
    height="24"
    viewBox="0 0 80 24"
    width="80"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_903_94626)">
      <path
        d="M10.2627 7.88571V0H13.2407V12.5571C13.2407 16.2429 10.518 18.9429 6.77422 18.9429C3.03047 18.9429 0.307739 16.2429 0.307739 12.5571C0.307739 8.82857 2.94538 6.12857 6.3488 6.12857C7.92288 6.12857 9.41187 6.77143 10.2627 7.88571ZM6.77422 9.04286C4.77472 9.04286 3.32827 10.5429 3.32827 12.5571C3.32827 14.5714 4.77472 16.0714 6.77422 16.0714C8.73119 16.0714 10.1776 14.5714 10.1776 12.5571C10.1776 10.5429 8.77373 9.04286 6.77422 9.04286Z"
        fill="#212529"
      />
      <path
        d="M21.4514 18.9428C17.7502 18.9428 15.1125 16.2857 15.1125 12.5143C15.1125 8.74284 17.8353 6.08569 21.579 6.08569C25.1952 6.08569 27.6626 8.57141 27.6626 11.9571C27.6626 12.4714 27.6201 12.9857 27.4925 13.5428H18.6861C18.3458 13.5428 18.1756 13.7143 18.1756 14.0143C18.1756 15.1714 19.6221 16.3285 21.579 16.3285C22.8553 16.3285 24.1741 15.9428 24.6846 14.8714H27.6201C26.6842 17.7428 24.3018 18.9428 21.4514 18.9428ZM18.0905 11.2714H24.3869C24.6421 11.2714 24.7697 11.1428 24.7697 10.8857C24.7697 9.77141 23.536 8.65712 21.6216 8.65712C19.7922 8.65712 18.4309 9.64284 18.0905 11.2714Z"
        fill="#212529"
      />
      <path
        d="M35.4905 18.9428C31.7893 18.9428 29.1516 16.2857 29.1516 12.5143C29.1516 8.74284 31.8743 6.08569 35.6181 6.08569C39.2342 6.08569 41.7017 8.57141 41.7017 11.9571C41.7017 12.4714 41.6591 12.9857 41.5315 13.5428H32.7252C32.3849 13.5428 32.2147 13.7143 32.2147 14.0143C32.2147 15.1714 33.6611 16.3285 35.6181 16.3285C36.8944 16.3285 38.2132 15.9428 38.7237 14.8714H41.6591C40.7658 17.7428 38.3408 18.9428 35.4905 18.9428ZM32.1721 11.2714H38.4684C38.7237 11.2714 38.8513 11.1428 38.8513 10.8857C38.8513 9.77141 37.6176 8.65712 35.7032 8.65712C33.8313 8.65712 32.4699 9.64284 32.1721 11.2714Z"
        fill="#212529"
      />
      <path
        d="M46.6793 17.2287V24.0001H43.7013V12.5572C43.7013 8.87152 46.5091 6.12866 50.1678 6.12866C53.8265 6.12866 56.6343 8.82866 56.6343 12.5572C56.6343 16.2429 53.9541 18.9858 50.5081 18.9858C49.0191 18.9429 47.4876 18.3001 46.6793 17.2287ZM50.2103 16.0715C52.1247 16.0715 53.6563 14.5715 53.6563 12.5572C53.6563 10.5858 52.1673 9.04295 50.2103 9.04295C48.2534 9.04295 46.7644 10.5429 46.7644 12.5572C46.7218 14.5287 48.2534 16.0715 50.2103 16.0715Z"
        fill="#212529"
      />
      <path
        d="M61.1863 0C62.0372 0 62.7179 0.685714 62.7179 1.5V1.58571C62.7179 2.44286 62.0372 3.08571 61.1863 3.08571C60.3355 3.08571 59.6548 2.4 59.6548 1.58571V1.5C59.6548 0.685714 60.3355 0 61.1863 0Z"
        fill="#FF7800"
      />
      <path
        d="M70.4181 18.6857H64.377C61.7819 18.6857 59.6548 16.5429 59.6548 13.9286V6.12857H62.7604V13.9286C62.7604 14.8286 63.4836 15.6 64.4196 15.6H70.4606C72.1623 15.6 73.7364 14.9143 74.8851 13.7143C76.0763 12.4714 76.6718 10.8429 76.6293 9.12857C76.5442 5.82857 73.6939 3.12857 70.2479 3.12857H65.7384V0H70.2479C75.3105 0 79.5648 4.02857 79.6924 9C79.7775 11.5714 78.8841 14.0143 77.0973 15.8571C75.3105 17.7 72.9706 18.6857 70.4181 18.6857Z"
        fill="url(#paint0_linear_903_94626)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_903_94626"
        gradientUnits="userSpaceOnUse"
        x1="59.6373"
        x2="79.6735"
        y1="9.34299"
        y2="9.34299"
      >
        <stop stop-color="#FF7800" />
        <stop offset="0.83" stop-color="#FF0066" />
      </linearGradient>
      <clipPath id="clip0_903_94626">
        <rect
          fill="white"
          height="24"
          transform="translate(0.307739)"
          width="79.3846"
        />
      </clipPath>
    </defs>
  </svg>
</template>
