import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type {
  Document,
  DocumentObserversRequest,
  DocumentRequest
} from '@/types/Document';
import AuthService from '@/services/auth-service';
import {
  destroyDocument,
  listDocuments,
  showDocument,
  updateDocument
} from '@/api/deepsign/document';
import type { Pagination } from '@/types/Pagination';
import type { AxiosRequestConfig } from 'axios';
import { storeFileDocument } from '@/api/deepsign/file';
import { storeDeepboxDocument } from '@/api/deepsign/deepbox';
import type { AddDocumentDeepbox } from '@/types/deepsign/AddDocumentDeepbox';
import { SendMail } from '@/types/enums/SendMail';
import { SignatureMode } from '@/types/enums/SignatureMode';
import { Jurisdiction } from '@/types/enums/Jurisdiction';
import type { SignOrderInfo } from '@/types/deepsign/SignOrderInfo';
import {
  destroyObserver,
  storeObserver,
  updateObserver
} from '@/api/deepsign/observer';
import {
  destroySignOrder,
  showSignOrder,
  updateSignOrder
} from '@/api/deepsign/sign-order';
import type { Signee } from '@/types/Signee';
import { uploadDocumentToBeSealed } from '@/api/deepsign/documents-file-seal';
import { cloneDeep, groupBy } from '@/composables/useUtils';
import type { CompanySettings } from '@/types/deepsign/CompanySettings';

export const useDocumentStore = defineStore('documents', () => {
  const documents = ref<Document[]>();
  const document = ref<Document>();
  const pagination = ref<Pagination>();
  const size = ref<number>();

  function getRequestBodyFromSignGroups(signGroups: Signee[][]) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const requestBody: any = [];
    if (!signGroups) {
      return requestBody;
    }
    if (signGroups.length === 1) {
      signGroups[0].forEach((signee) => {
        requestBody.push([signee.signeeId]);
      });
      return requestBody;
    } else {
      signGroups.forEach((group) => {
        const signeeIds: string[] = [];
        group.forEach((signee) => {
          signeeIds.push(signee.signeeId);
        });
        requestBody.push(signeeIds);
      });
      return requestBody;
    }
  }

  function getSignGroupsFromSignees(signeesList: Signee[]) {
    const signeeGroups: Signee[][] = [];
    let signees: Signee[] = cloneDeep(signeesList);
    let groupIndex = 0;
    signees = signees.sort((a, b) => {
      return a.signOrder - b.signOrder;
    });

    const groupedSignees = groupBy(signees, 'signOrder');
    for (const value of Object.values(groupedSignees)) {
      signeeGroups.push([]);
      value.forEach((signee) => {
        signeeGroups[groupIndex].push(signee);
      });
      groupIndex++;
    }
    return signeeGroups;
  }

  const placedSignatures = computed(() => {
    if (document.value?.signees) {
      return document.value?.signees.filter(
        (signee) => signee.autographPosition !== null
      );
    }
    return [];
  });

  const signGroups = computed(() => {
    if (document.value && document.value.signees) {
      return getSignGroupsFromSignees(document.value.signees);
    }
    return [];
  });

  async function fetchDocuments(config?: AxiosRequestConfig) {
    documents.value = await listDocuments(config);
  }

  async function fetchDocument(id: string, config?: AxiosRequestConfig) {
    document.value = await showDocument(id, config);
  }

  async function deleteDocument(id: string, config?: AxiosRequestConfig) {
    return await destroyDocument(id, config);
  }

  async function createDocumentFromFile(
    file: File,
    companyId: string,
    companySettings: CompanySettings
  ) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    const formData = new FormData();
    const data = JSON.stringify({
      companyId: companyId || null,
      scanPredefinedSignees: true,
      addInitiatorAsSignee: true,
      sendMail: SendMail.OTHERS,
      signatureMode:
        companySettings && companySettings.defaultSignatureMode
          ? companySettings.defaultSignatureMode
          : SignatureMode.TIMESTAMP,
      jurisdiction:
        companySettings && companySettings.defaultJurisdiction
          ? companySettings.defaultJurisdiction
          : Jurisdiction.ZERTES,
      authorityService: null
      // Left here just for testing cases
      // autographRequired: true,
      // visualSignatureRequirement: 'required'
    });
    formData.append('data', new Blob([data], { type: 'application/json' }));
    formData.append('file', file, file.name);

    const response = await storeFileDocument(formData, config);
    document.value = response.data;
  }

  async function createSealDocumentFromFile(file: File, companyId: string) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    const formData = new FormData();
    const data = JSON.stringify({
      companyId: companyId || null,
      scanPredefinedSignees: true,
      addInitiatorAsSignee: true,
      sendMail: SendMail.OTHERS,
      signatureMode: SignatureMode.TIMESTAMP,
      jurisdiction: Jurisdiction.ZERTES,
      authorityService: null
    });
    formData.append('data', new Blob([data], { type: 'application/json' }));
    formData.append('file', file, file.name);

    const response = await uploadDocumentToBeSealed(formData, config);
    document.value = response.data;
  }

  async function createDocumentFromDeepBox(data: AddDocumentDeepbox) {
    const response = await storeDeepboxDocument(data);
    document.value = response.data;
  }

  async function updateDocumentById(
    id: string,
    data: DocumentRequest,
    config?: AxiosRequestConfig
  ) {
    await updateDocument(id, data, config);
    if (document.value) {
      if (
        data.signeeAttachmentsAllowed === true ||
        data.signeeAttachmentsAllowed === false
      ) {
        document.value.signeeAttachmentsAllowed = data.signeeAttachmentsAllowed;
      }

      if (data.requiredAuthorityService !== undefined) {
        document.value.requiredAuthorityService = data.requiredAuthorityService;
      }
      if (data.jurisdiction) {
        document.value.jurisdiction = data.jurisdiction;
      }
      if (data.signatureMode) {
        document.value.signatureMode = data.signatureMode;
      }
    }
  }

  // Observers
  const hasEmptyObservers = computed(() => {
    return document.value?.observers.some((o) => o.email === null) || false;
  });

  async function addObserver(
    documentId: string,
    data: DocumentObserversRequest
  ) {
    const response = await storeObserver(documentId, data);
    if (document.value) {
      const observerIndex = document.value.observers.findIndex(
        (observer) => observer.observerId === response.data.observerId
      );
      if (observerIndex >= 0) {
        document.value.observers[observerIndex] = response.data;
      } else {
        document.value.observers.push(response.data);
      }
    }
  }

  async function updateDocumentObserver(
    documentId: string,
    observerId: string,
    data: DocumentObserversRequest
  ) {
    const response = await updateObserver(documentId, observerId, data);
    if (document.value) {
      const observerIndex = document.value.observers.findIndex(
        (observer) => observer.observerId === response.data.observerId
      );
      if (observerIndex >= 0) {
        document.value.observers[observerIndex] = response.data;
      }
      document.value.observers = [...document.value.observers];
    }
  }

  async function deleteObserver(documentId: string, observerId: string) {
    await destroyObserver(documentId, observerId);
    if (document.value) {
      document.value.observers = document.value?.observers.filter(
        (obs) => obs.observerId !== observerId
      );
    }
  }

  // Sign order
  const signOrderContext = ref<SignOrderInfo>();
  const getSignOrderContext = computed(() => signOrderContext.value);

  const hasSignOrder = computed(() => {
    if (document.value?.signeesOrdered) {
      return document.value?.signeesOrdered.length > 1;
    }
    return false;
  });

  const updatedSignOrderRequestBody = computed(() => {
    return getRequestBodyFromSignGroups(signGroups.value);
  });

  async function updateDocumentSignOrder(documentId: string, data: string[][]) {
    await updateSignOrder(documentId, data);
    updateDocumentSignOrderTemp(data);
    const newOrderContext = await showSignOrder(documentId);
    signOrderContext.value = newOrderContext.data.context;
  }

  function updateDocumentSignOrderTemp(data: string[][]) {
    if (document.value) {
      document.value.signees = (document.value.signees ?? []).map((signee) => {
        let order = null;
        data.forEach((peopleGroup: string[], index: number) => {
          if (peopleGroup.includes(signee.signeeId)) {
            order = index;
          }
        });
        return { ...signee, ...(order !== null && { signOrder: order }) };
      });

      document.value.signeesOrdered = signGroups.value;
    }
  }

  async function deleteDocumentSignOrder(documentId: string) {
    await destroySignOrder(documentId);
    if (document.value) {
      document.value.signees?.map((signee) => {
        const newSignee = { ...signee };
        newSignee.signOrder = 0;
        return newSignee;
      });
      if (document.value.signees) {
        const sortedSignees = sortByApprovers(document.value.signees);
        document.value.signees = sortedSignees;
        document.value.signeesOrdered = [sortedSignees];
        getSignGroupsFromSignees(sortedSignees);
      }
      const newOrderContext = await showSignOrder(documentId);
      signOrderContext.value = newOrderContext.data.context;
    }
  }

  function sortByApprovers(signees) {
    return signees.sort((a, b) =>
      a.signeeType < b.signeeType ? -1 : Number(a.signeeType > b.signeeType)
    );
  }

  // Signee
  const hasSignees = computed(() => {
    if (document.value?.signees) {
      return document.value?.signees.length > 0;
    }
    return false;
  });

  const initiatorSignee = computed(() => {
    return document.value?.signees?.find((s) =>
      compareString(s.email, AuthService.user?.email)
    );
  });

  function compareString(first: string, second: string, insensitive = true) {
    if (!first || !second) {
      return false;
    }
    return insensitive
      ? first.toLowerCase() === second.toLowerCase()
      : first === second;
  }

  const hasEmptySignees = computed(() => {
    return document.value?.signees?.some((s) => s.email === null) || false;
  });

  const hasOnlyEmptySignees = computed(() => {
    return (
      document.value?.signees?.filter((s) => s.email !== null).length === 0
    );
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function updateSigneeAutographPosition(data: any) {
    if (document.value && document.value.signees) {
      const index = document.value.signees?.findIndex(
        (signee) => signee.signeeId === data.signeeId
      );
      document.value.signees[index] = { ...data };

      // We need to do this so that components detect changes on the signees array
      document.value.signees = [...(document.value.signees ?? [])];
    }
  }

  function addSigneeToDocument(signee: Signee) {
    if (document.value) {
      document.value.signees?.push(signee);
    }
  }

  function addApproverToDocument(signee: Signee) {
    if (document.value) {
      document.value.signees?.unshift(signee);
    }
  }

  function removeSigneeFromDocument(signeeId: string) {
    if (document.value) {
      document.value.signees = document.value.signees?.filter(
        (signee) => signee.signeeId !== signeeId
      );
    }
  }

  function updateDocumentSignee(data: Signee) {
    if (document.value && document.value.signees) {
      const index = document.value.signees?.findIndex(
        (signee) => signee.signeeId === data.signeeId
      );
      document.value.signees[index].email = data.email;
      document.value.signees[index].autographPosition = data.autographPosition;
    }
  }

  return {
    documents,
    document,
    pagination,
    size,
    placedSignatures,
    signGroups,
    fetchDocuments,
    fetchDocument,
    deleteDocument,
    createDocumentFromFile,
    createSealDocumentFromFile,
    createDocumentFromDeepBox,
    updateDocumentById,
    // Observers
    hasEmptyObservers,
    addApproverToDocument,
    addObserver,
    updateDocumentObserver,
    deleteObserver,
    // Sign order
    signOrderContext,
    getSignOrderContext,
    getSignGroupsFromSignees,
    hasSignOrder,
    updatedSignOrderRequestBody,
    updateDocumentSignOrder,
    updateDocumentSignOrderTemp,
    deleteDocumentSignOrder,
    // Document signee
    hasSignees,
    initiatorSignee,
    hasEmptySignees,
    hasOnlyEmptySignees,
    updateSigneeAutographPosition,
    addSigneeToDocument,
    removeSigneeFromDocument,
    updateDocumentSignee
  };
});
